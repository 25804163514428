import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { useTable } from 'react-table';
import Link from '@material-ui/core/Link';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import useStyles from './style';

const AdverseActionNoticeReasons = ({ previousPage, pageTitle, resetReasons, aanReasonList }) => {
  const classes = useStyles();
  const handleSearchFormNav = () => {
    previousPage();
    pageTitle();
    resetReasons();
  };

  const columns = useMemo(() => [
    {
      Header: 'Reason',
      id: 'aanReasonCodes',
      accessor: row => row,
      Cell: e => (
        <span>
          <span
            className={classes.rowNumber}
          >
            {e.row.index + 1}
            {'.'}
          </span>
          {e.value.reasonCode}
        </span>
      ),
    },
    { Header: 'Explanation', id: 'explanation', accessor: 'explanation' },
  ], []);
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: aanReasonList,
    },
  );

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Button
          underline="always"
          onClick={handleSearchFormNav}
          className={classes.applicantSearchLink}
        >
          <ArrowBackIosIcon fontSize="inherit" />
          Applicant Search
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Top Reasons Applicant Was Funded</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">
          The below represents the top reasons an applicant was approved/denied per our
          model using the SHAP methodology to determine overall importance to final outcome.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="aan table" className={classes.table} {...getTableProps()}>
            <TableHead>
              { headerGroups.map(headerGroup => (
                <TableRow className={classes.headRow} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell align="left" {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            {rows.length !== 0 && (
              <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <TableRow data-testid="aan-reasons-table" className={classes.bodyRow} {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <TableCell align="left" className={classes.cellPadding} {...cell.getCellProps()}>
                          <Typography title={cell.value}>
                            {cell.render('Cell')}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Please see this
          {' '}
          <Link
            href="https://dataroom.ansarada.com/upstart/document/49294636"
            underline="always"
          >
            documentation
          </Link>
          {' '}
          on our AAN reasons for further detail.
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

AdverseActionNoticeReasons.propTypes = {
  previousPage: PropTypes.func.isRequired,
  pageTitle: PropTypes.func.isRequired,
  resetReasons: PropTypes.func.isRequired,
  aanReasonList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default AdverseActionNoticeReasons;
