import { format } from 'date-fns';
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';
import useStyle from './style';

const ReportDatesSelector = ({ defaultDates = '1Y', onDatesChange }) => {
  const styles = useStyle();
  const today = new Date();

  const [selectedDate, setSelectedDate] = useState(defaultDates);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showCustom, setShowCustom] = useState(false);

  const setDates = (filter, start = null, end = null) => {
    setShowCustom(false);
    setStartDate(start);
    setEndDate(end);
    onDatesChange(filter);
  };

  const formatDate = date => format(date, 'yyyy/MM/dd');

  const handleEndDateChange = date => {
    setEndDate(date);
    if (startDate && date) {
      const filter = `${formatDate(startDate)} to ${formatDate(date)}`;
      onDatesChange(filter);
    }
  };

  const handleStartDateChange = date => {
    setStartDate(date);
    if (endDate && date) {
      const filter = `${formatDate(date)} to ${formatDate(endDate)}`;
      onDatesChange(filter);
    }
  };

  const handleSelectedDateChange = (event, newDate) => {
    setSelectedDate(newDate);
  };

  return (
    <div>
      <ToggleButtonGroup
        value={selectedDate}
        exclusive
        onChange={handleSelectedDateChange}
        aria-label="text alignment"
        className={styles.buttonWrapper}
      >
        <ToggleButton
          data-testid="LTD"
          value="LTD"
          className={styles.button}
          onClick={() => setDates('')}
        >
          LTD
        </ToggleButton>
        <ToggleButton
          data-testid="YTD"
          value="YTD"
          className={styles.button}
          onClick={() => setDates(`after ${today.getFullYear()}/01/01`)}
        >
          YTD
        </ToggleButton>
        <ToggleButton
          data-testid="1Y"
          value="1Y"
          className={styles.button}
          onClick={() => setDates('12 Months')}
        >
          1Y
        </ToggleButton>
        <ToggleButton
          data-testid="6M"
          value="6M"
          className={styles.button}
          onClick={() => setDates('6 Months')}
        >
          6M
        </ToggleButton>
        <ToggleButton
          data-testid="1M"
          value="1M"
          className={styles.button}
          onClick={() => setDates('1 Months')}
        >
          1M
        </ToggleButton>
        <ToggleButton
          data-testid="custom"
          value="Custom"
          className={styles.button}
          onClick={() => setShowCustom(!showCustom)}
        >
          Custom
        </ToggleButton>
      </ToggleButtonGroup>
      <div data-testid="datePickers" className={styles.dateSlide} hidden={!showCustom}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={styles.datePicker}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="Start Date:"
            value={startDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              ...({ 'data-testid': 'startDate' }),
            }}
          />
          <KeyboardDatePicker
            className={styles.datePicker}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="End Date:"
            value={endDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              ...({ 'data-testid': 'endDate' }),
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

export default ReportDatesSelector;

ReportDatesSelector.propTypes = {
  defaultDates: PropTypes.string,
  onDatesChange: PropTypes.func.isRequired,
};

ReportDatesSelector.defaultProps = {
  defaultDates: '1Y',
};
