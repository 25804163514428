import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Avatar from '@mui/material/Avatar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useForecast } from '../../contexts/ForecastContext';
import Loader from '../Loader';

const useForecastNameStyles = makeStyles({
  editable: {
    borderRadius: 0,
    width: '150px',
    backgroundColor: '#00807B',
    color: 'white',
  },
  nonEditable: {
    borderRadius: 0,
    width: '150px',
    backgroundColor: '#E7E7E7',
    color: '#6E6E6E',
  },
});

const ForecastName = () => {
  const classes = useForecastNameStyles();
  const {
    name,
    setName,
    expanded,
    handleContinue,
    handlePanel,
    isLoading,
    isEditable,
    formType,
  } = useForecast();

  const pageTitle = () => {
    if (formType === 'view') {
      return 'View Forecast';
    } else if (formType === 'edit') {
      return 'Edit Forecast';
    } else if (isLoading) {
      return null;
    } else return 'Create Forecast';
  };

  const handleChange = e => {
    setName(e.target.value);
  };

  return (
    <>
      <h1>{pageTitle()}</h1>
      <Accordion
        expanded={expanded === 'all' || expanded === 'name-panel'}
        onChange={handlePanel('name-panel')}
        style={{ borderRadius: 4, backgroundColor: 'white' }}
        id="name-panel"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          style={{ fontWeight: 'bold' }}
        >
          <Avatar sx={{ bgcolor: '#00807B', width: 18, height: 18, fontSize: 10, marginRight: '5px', fontFamily: 'Montserrat' }}>
            1
          </Avatar>
          {' '}
          { isEditable ? 'Name your forecast' : 'Forecast Name' }
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <Grid container direction="column">
              <Loader data-testid="loader" size={40} />
            </Grid>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <TextField
                fullWidth
                label="Forecast Name"
                variant="outlined"
                value={name}
                style={{ borderRadius: 0, backgroundColor: 'white', width: '300px' }}
                onChange={handleChange}
                disabled={!isEditable}
                data-testid="name-input"
              />
              {(isEditable) && (
              <Button
                variant="contained"
                onClick={handleContinue}
                className={!name.trim() ? classes.nonEditable : classes.editable}
                disabled={!name.trim()}
                data-testid="name-continue"
              >
                Continue
              </Button>
              )}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ForecastName;
