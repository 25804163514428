import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useExternalInvestor } from 'contexts/ExternalInvestorContext';

const ExternalInvestorDropdown = () => {
  const {
    externalInvestors,
    currentExternalInvestor,
    setCurrentExternalInvestor,
  } = useExternalInvestor();
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExternalInvestorSelection = externalInvestor => {
    setCurrentExternalInvestor(externalInvestor);
    handleClose();
  };

  if (externalInvestors?.length > 0) {
    return (
      <React.Fragment>
        <Button
          aria-controls="external-investor-dropdown"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          size="large"
        >
          {currentExternalInvestor ? currentExternalInvestor.attributes.sfdcInvestorName : '-'}
        </Button>
        <Menu
          id="external-investor-dropdown"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div>
            {externalInvestors.map(externalInvestor => (
              <MenuItem
                key={externalInvestor.id}
                onClick={() => handleExternalInvestorSelection(externalInvestor)}
              >
                <Typography noWrap>
                  {externalInvestor.attributes.sfdcInvestorName}
                </Typography>
              </MenuItem>
            ))}
          </div>
        </Menu>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default ExternalInvestorDropdown;
