import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    background: theme.palette.neutral.background,
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mainContent: {
    padding: theme.spacing(4),
  },
  pageRoot: {
    margin: 0,
    padding: 12,
  },
}));

export default useStyles;
