import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import { Grid } from '@material-ui/core';
import useStyle from '../style';

const ApplicantSearchCard = ({ children }) => {
  const classes = useStyle();

  return (
    <Card>
      <CardContent>
        <Grid container item xs={12} xl={4} className={classes.container}>
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
};

ApplicantSearchCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ApplicantSearchCard.defaultProps = {
  children: [],
};

export default ApplicantSearchCard;
