import { makeStyles } from '@material-ui/core/styles';
import { sharedClasses } from 'theme/sharedStyles';

const styles = theme => (
  {
    linkLikeText: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      textDecoration: 'underline',
    },
    floatRight: {
      marginLeft: 'auto',
    },
  }
);

const useStyles = makeStyles(theme => ({
  ...sharedClasses(theme),
  ...styles(theme),
}));

export default useStyles;
