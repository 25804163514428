import React, { useMemo, useState } from 'react';
import ForecastsTable from 'components/ForecastsTable/ForecastsTable';
import { PropTypes } from 'prop-types';
import {
  duplicateForecastConfiguration,
  enableForecastConfigurationNightlyRuns,
  disableForecastConfigurationNightlyRuns, rerunForecastConfiguration,
} from 'api/forecastConfiguration';
import { useHistory } from 'react-router-dom';
import { useUser } from 'contexts/UserContext';
import Checkbox from '@material-ui/core/Checkbox';
import ConfirmModal from '../ConfirmModal';
import { createForecastConsensusHistory } from '../../api/forecastConsensusHistory';

const SubmittedForecasts = ({ submittedForecasts, consensus, isLoading }) => {
  const forecasts = submittedForecasts.map(({ attributes }) => attributes);
  const [isConsensusModalOpen, setIsConsensusModalOpen] = useState(false);
  const [isToggleModalOpen, setIsToggleModalOpen] = useState(false);
  const [forecastUUID, setForecastUUID] = useState('');
  const [toggleTitle, setToggleTitle] = useState('');
  const [toggleDescription, setToggleDescription] = useState('');
  const [nightlyStatus, setNightlyStatus] = useState('');
  const { isAdmin, isForecastAdmin } = useUser();
  const history = useHistory();

  const showToggleModal = row => {
    setNightlyStatus(row.nightlyRunsEnabled);
    if (row.nightlyRunsEnabled === 'false') {
      setToggleTitle('Enable Forecast for Nightly Runs');
      setToggleDescription('Too many nightly runs will crash our server. Are you sure you want to mark this forecast as a nightly run?');
    } else {
      setToggleTitle('Disable Forecast for Nightly Runs');
      setToggleDescription('Are you sure you want to remove this forecast from the nightly runs?');
    }
    setIsToggleModalOpen(true);
    setForecastUUID(row.uuid);
  };

  const columns = useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'forecastName',
      enableGlobalFilter: true,
    },
    {
      header: 'Owner',
      accessorKey: 'createdBy',
      enableGlobalFilter: true,
    },
    {
      header: 'Date Submitted',
      accessorKey: 'lastModified',
      enableGlobalFilter: true,
    },
    {
      header: 'Nightly Run',
      type: 'boolean',
      accessorKey: 'nightlyRunsEnabled',
      Cell: row => (
        <Checkbox
          sx={{ display: 'flex', gap: '2ch', alignItems: 'center' }}
          data-testid="nightly-checkbox"
          checked={row.row.original.nightlyRunsEnabled === 'true'}
          value={row.row.original.uuid}
          onClick={() => showToggleModal(row.row.original)}
        />
      ),
      enableGlobalFilter: true,
    },
  ]);

  const initialTableState = {
    showLoadingOverlay: false,
    sorting: [{ id: 'lastModified', desc: true }],
    showGlobalFilter: true,
    pagination: {
      pageSize: 25,
    },
    columnPinning: { right: ['mrt-row-actions'] },
  };

  const rowMenuActions = [
    'view',
    'duplicate',
  ];

  const rowMenuAdminActions = [
    ...rowMenuActions,
    're-run',
    'set as consensus assumption set',
  ];

  const handleDuplicate = async uuid => {
    try {
      const result = await duplicateForecastConfiguration(uuid);
      const { attributes } = result.data.data;
      history.push(`/forecast-configuration/${attributes.uuid}`);
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(`Error duplicating forecast configuration: ${err.message}`);
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const handleCloseConsensusModal = () => {
    setIsConsensusModalOpen(false);
    reloadPage();
  };

  const showConsensusModal = uuid => {
    setIsConsensusModalOpen(true);
    setForecastUUID(uuid);
  };

  const handleConfirmConsensusModal = async () => {
    try {
      await createForecastConsensusHistory(forecastUUID);
      history.push(`/forecast-configurations`);
    } catch (err) {
      alert(`Error setting forecast configuration as consensus: ${err.message}`);
    }
    handleCloseConsensusModal();
  };

  const handleCloseToggleModal = () => {
    setIsToggleModalOpen(false);
  };

  const handleConfirmToggleModal = async () => {
    const target = submittedForecasts.find(forecast => forecast.attributes.uuid === forecastUUID);

    // Enable if false, disable if true
    if (nightlyStatus === 'false') {
      try {
        await enableForecastConfigurationNightlyRuns(forecastUUID);
        target.attributes.nightlyRunsEnabled = 'true'; // Update UI
      } catch (err) {
        alert(`Error enabling forecast configuration for Nightly Runs: ${err.message}`);
      }
    } else {
      try {
        await disableForecastConfigurationNightlyRuns(forecastUUID);
        target.attributes.nightlyRunsEnabled = 'false';
      } catch (err) {
        alert(`Error disabling forecast configuration for Nightly Runs: ${err.message}`);
      }
    }
    handleCloseToggleModal();
  };

  const myRowMenuActions = useMemo(() => {
    if (isAdmin || isForecastAdmin) {
      return rowMenuAdminActions;
    }
    return rowMenuActions;
  }, [isForecastAdmin, isAdmin]);

  function handleRerun(uuid) {
    rerunForecastConfiguration(uuid)
    // eslint-disable-next-line no-alert
      .then(() => alert(`Forecast marked for rerun: ${uuid}`))
    // eslint-disable-next-line no-alert
      .catch(e => alert(`Error rerunning forecast: ${e.message}`));
  }

  const performAction = (row, action) => {
    if (action === 'view') {
      history.push(`/forecast-configuration/${row.original.uuid}`);
    } else if (action === 'duplicate') {
      handleDuplicate(row.original.uuid);
    } else if (action === 'set as consensus assumption set') {
      showConsensusModal(row.original.uuid);
    } else if (action === 're-run') {
      handleRerun(row.original.uuid);
    }
  };

  return (
    <div>
      <h1>Submitted Forecasts</h1>
      <ForecastsTable
        columns={columns}
        forecasts={forecasts}
        consensus={consensus || 'No consensus set'}
        initialTableState={initialTableState}
        isLoading={isLoading}
        rowMenuActions={myRowMenuActions}
        performAction={performAction}
      />
      <ConfirmModal
        title="Set as Consensus Assumption Set?"
        description="Are you sure you want to set this forecast as consensus?"
        isOpen={isConsensusModalOpen}
        onExit={handleCloseConsensusModal}
        onConfirm={handleConfirmConsensusModal}
      />
      <ConfirmModal
        title={toggleTitle}
        description={toggleDescription}
        isOpen={isToggleModalOpen}
        onExit={handleCloseToggleModal}
        onConfirm={handleConfirmToggleModal}
        agree="Yes, I'm sure"
        disagree="Cancel"
      />
    </div>
  );
};

SubmittedForecasts.propTypes = {
  submittedForecasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        uuid: PropTypes.string,
        forecastName: PropTypes.string,
        createdBy: PropTypes.string,
        lastModified: PropTypes.string,
        nightlyRunsEnabled: PropTypes.string,
      }),
    }),
  ).isRequired,
  consensus: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

SubmittedForecasts.defaultProps = {
  consensus: '',
};

export default SubmittedForecasts;
