import { useMemo } from 'react';
import { useAsync } from 'react-async';
import { getFeatureFlags } from 'api/featureFlag';

const FeatureFlags = () => {
  const { data, isFulfilled } = useAsync(getFeatureFlags, { });
  const flags = useMemo(() => (
    isFulfilled ? data.data.data : {}
  ), [isFulfilled]);

  return flags;
};

export default FeatureFlags;
