import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    '& .MuiFormLabel-root.Mui-error, & .MuiFormHelperText-root.Mui-error': {
      color: '#B00020',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBlockColor: '#B00020',
    },
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '24px',
    color: '#222929',
    marginTop: '15px',
  },
  container: {
    margin: '72px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '20px',
  },
  searchSection: {
    marginBottom: theme.spacing(2),
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    tableLayout: 'fixed',
  },
  headRow: {
    borderRadius: '2px',
    backgroundColor: theme.palette.primary.main,
    height: '70px',
    '& th': {
      fontWeight: 600,
      color: '#FFFFFF !important',
    },
  },
  bodyRow: {
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    '& td p': {
      ...theme.typography.secondary,
      verticalAlign: 'middle',
    },
    '& .application-id-link-pos': {
      verticalAlign: 'middle',
    },
  },
  errorIconColor: {
    color: '#B00020',
  },
  pagination: {
    padding: theme.spacing(1),
  },
  loanStatusButton: {
    color: theme.palette.highlight.main,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      textDecoration: 'underline',
    },
  },
  loanStatus: {
    textTransform: 'capitalize',
  },
}));

export default useStyles;
