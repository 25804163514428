import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import BankLogo from 'components/BankLogo';
import { useUser } from 'contexts/UserContext';
import { useView } from 'contexts/ViewContext';
import UPCLogo from 'assets/upc-logo.svg';
import CircleArrow from 'assets/circle-arrow.svg';
import UpwardsGraphArrow from 'assets/upwards-graph-arrow.svg';
import { useAuth } from 'contexts/AuthContext';
import useStyles from './style';

const SideNav = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { authState } = useAuth();
  const { isAdmin, hasPiiAccess, forecastPrivileges, hasPortfolioAnalyticsAccess } = useUser();
  const selected = current => current === pathname;
  const { productView, investorView, forecastView, portfolioAnalyticsView } = useView();

  const menuItems = useMemo(() => [
    {
      path: '/',
      icon: <DashboardOutlinedIcon />,
      name: 'Dashboard',
      visible: true,
    },
    {
      path: '/reports',
      icon: <SubjectOutlinedIcon />,
      name: 'Reports',
      visible: true,
    },
    {
      path: '/metrics',
      icon: <img src={UpwardsGraphArrow} alt="Metrics" />,
      name: 'Metrics',
      visible: true,
    },
    {
      path: '/configuration',
      icon: <SettingsOutlinedIcon />,
      name: 'Configuration',
      visible: true,
    },
    {
      path: '/applicant-search',
      icon: <SearchOutlinedIcon />,
      name: 'Applicant Search',
      visible: hasPiiAccess,
    },
    {
      path: '/admin',
      icon: <PlaylistAddOutlinedIcon />,
      name: 'Admin',
      visible: isAdmin,
    },
  ], [isAdmin, hasPiiAccess]);

  const menuItemsInvestor = useMemo(() => [
    {
      path: '/',
      icon: <DashboardOutlinedIcon />,
      name: 'Dashboard',
      visible: true,
    },
    {
      path: '/reports',
      icon: <SubjectOutlinedIcon />,
      name: 'Reports',
      visible: true,
    },
    {
      path: '/metrics',
      icon: <img src={UpwardsGraphArrow} alt="Metrics" />,
      name: 'Metrics',
      visible: true,
    },
    {
      path: '/admin',
      icon: <PlaylistAddOutlinedIcon />,
      name: 'Admin',
      visible: isAdmin,
    },
  ], [isAdmin, hasPiiAccess]);

  const menuItemsForecast = useMemo(() => [
    {
      path: '/forecast-configurations',
      icon: <PlaylistAddOutlinedIcon />,
      name: 'Configurations',
      visible: isAdmin || forecastPrivileges,
    },
  ], [forecastPrivileges, isAdmin]);

  const menuPortfolioAnalytics = useMemo(() => [
    {
      path: '/',
      icon: <DashboardOutlinedIcon />,
      name: 'Dashboard',
      visible: true,
    },
    {
      path: '/credit-performance',
      icon: <img src={UpwardsGraphArrow} alt="Credit Performance" />,
      name: 'Credit Performance',
      visible: true,
    },
    {
      path: '/saved-queries',
      icon: <img src={CircleArrow} alt="Saved Queries" />,
      name: 'Saved Queries',
      visible: true,
    },
  ], [hasPortfolioAnalyticsAccess]);

  const items = useMemo(() => {
    if (productView) {
      return menuItems;
    } else if (investorView) {
      return menuItemsInvestor;
    } else if (forecastView) {
      return menuItemsForecast;
    } else if (portfolioAnalyticsView) {
      return menuPortfolioAnalytics;
    } else return [];
  }, [productView, investorView, forecastView, portfolioAnalyticsView]);

  return (
    <Drawer
      variant="permanent"
      classes={{
        root: classes.drawerRoot,
        paper: classes.drawerPaper,
      }}
      style={{ paddingTop: portfolioAnalyticsView ? '80px' : '0' }}
      open
      elevation={0}
    >
      <BankLogo />
      <div className={classes.sideNavContainer}>
        <List className={classes.listMenu}>
          {items.map(({ name, path, icon, visible }) => {
            if (visible) {
              return (
                <ListItem
                  key={name}
                  button
                  className={clsx(classes.listItem, selected(path) && classes.selected)}
                  component={Link}
                  to={path}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {authState?.isAuthenticated ? icon : null}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={authState?.isAuthenticated ? name : '—'}
                  />
                </ListItem>
              );
            }
            return null;
          })}
        </List>
        {!portfolioAnalyticsView && (
        <div className={classes.upstartLogoContainer}>
          <img src={UPCLogo} className={classes.upstartLogoImage} alt="Upstart Logo" />
        </div>
        )}
      </div>
    </Drawer>
  );
};

export default SideNav;
