import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { getReportList } from 'api/report';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Loader from 'components/Loader';
import SquareIconButton from 'components/SquareIconButton';
import { useProduct } from 'contexts/ProductContext';
import CircleChevron from 'icons/CircleChevron/CircleChevron';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import sharedStyles from 'theme/sharedStyles';
import theme from 'theme';

const ReportsSideList = props => {
  const classes = sharedStyles();
  const { currentProductKey } = useProduct();
  const { category, onSelectionChange } = props;
  const [explorable, setExplorable] = useState([]);
  const [staticReports, setStaticReports] = useState([]);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const { neutral } = theme.palette;
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setLoading(true);
    getReportList({ productKey: currentProductKey, category }).then(result => {
      setExplorable(result.data.data.filter(report => report.attributes.type === 'explorable'));
      setStaticReports(result.data.data.filter(report => report.attributes.type === 'static'));
      setLoading(false);
    });
  }, [currentProductKey, category]);

  const onClick = report => {
    setSelected(report);
    onSelectionChange(report);
    setCollapsed(true);
  };

  const collapsedClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Grid item className={collapsed ? classes.sideBarCollapsed : classes.sideBar}>
      <Card className={classes.reportListWrapper}>
        <SquareIconButton
          className={collapsed ? `${classes.sideListCollapseButton} collapsed` : classes.sideListCollapseButton}
          data-testid="collapse-button"
          icon={collapsed ? CircleChevron(neutral.text, 'left') : CircleChevron(neutral.text, 'right')}
          onClick={() => collapsedClick()}
        />
        {loading && <Loader data-testid="loader" /> }
        {!loading && !collapsed && ((explorable.length > 0 || staticReports.length > 0) ?
          (
            <CardContent data-testid="card-content">
              <Box className={classes.reportList}>
                {explorable.length > 0 && (
                  <>
                    <div className={classes.reportListHeader}>EXPLORABLE</div>
                    <hr className={classes.horizontalRule} />
                    {explorable.map(report => (
                      <Button
                        className={
                          `${classes.reportListTitle} ${report === selected ? 'selected' : ''}`
                        }
                        key={`${report.attributes.type}-${report.attributes.name}`}
                        onClick={() => onClick(report)}
                      >
                        {report.attributes.name}
                      </Button>
                    ))}
                    <br />
                    <br />
                  </>
                )}
              </Box>
              <Box className={classes.reportList}>
                {staticReports.length > 0 && (
                  <>
                    <div className={classes.reportListHeader}>STATIC</div>
                    <hr className={classes.horizontalRule} />
                    {staticReports.map(report => (
                      <Button
                        className={
                          `${classes.reportListTitle} ${report === selected ? 'selected' : ''}`
                        }
                        key={`${report.attributes.type}-${report.attributes.slug}`}
                        onClick={() => onClick(report)}
                      >
                        {report.attributes.name}
                      </Button>
                    ))}
                  </>
                )}
              </Box>
            </CardContent>
          ) : (
            <CardContent className={classes.headerText} data-testid="card-content">
              <Typography>No reports available</Typography>
            </CardContent>
          ))}
      </Card>
    </Grid>
  );
};

export default ReportsSideList;

ReportsSideList.propTypes = {
  category: PropTypes.string.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
};
