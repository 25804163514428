import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: '600',
    lineHeight: '20px',
  },
  body: {
    fontWeight: 'bold',
    lineHeight: '32px',
    color: 'black',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '15px',
  },
  acknowledgeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '5px',
  },
}));

export default useStyles;
