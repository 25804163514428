import axios from 'axios';
import humps from 'humps';
import Qs from 'qs';

export default axios.create({
  baseURL: process.env.REACT_APP_BANK_PARTNER_CONSOLE_API_BASE_URL,
  headers: {
    withCredentials: true,
  },
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data),
  ],
  transformRequest: [
    data => (data instanceof FormData ? data : humps.decamelizeKeys(data)),
    ...axios.defaults.transformRequest,
  ],
  paramsSerializer: params => Qs.stringify(humps.decamelizeKeys(params)),
});

export const streamingClient = axios.create({
  baseURL: process.env.REACT_APP_BANK_PARTNER_CONSOLE_API_BASE_URL,
  headers: {
    withCredentials: true,
  },
});
