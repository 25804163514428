import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: '600',
    lineHeight: '32px',
  },
  body: {
    fontWeight: '500',
    lineHeight: '32px',
    color: '#FFFFFF',
  },
}));

export default useStyles;
