import { makeStyles } from '@material-ui/core/styles';
import { sharedClasses } from 'theme/sharedStyles';

const styles = () => (
  {
    container: {
      alignItems: 'end',
    },
    textField: {
      width: '100%',
    },
  }
);

const useStyles = makeStyles(theme => ({
  ...sharedClasses(theme),
  ...styles(theme),
}));

export default useStyles;
