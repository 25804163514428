import { Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Loader from 'components/Loader';
import { useProduct } from 'contexts/ProductContext';
import { useExternalInvestor } from 'contexts/ExternalInvestorContext';
import React, { useMemo, useEffect, useState } from 'react';
import getEmbedUrl from 'api/embed';
import { randomString } from 'utils/toolsService';
import { useView } from 'contexts/ViewContext';
import useStyles from './style';

const AnalyticsExplore = () => {
  const classes = useStyles();
  const { productView, investorView } = useView();
  const { currentProduct } = useProduct();
  const { currentProductKey } = useProduct();
  const { currentInvestorName } = useExternalInvestor();
  const servicesOwnLoans = useMemo(
    () => currentProduct?.attributes?.servicesOwnLoans,
    [currentProduct],
  );
  const [url, setUrl] = useState('');
  const [exploreType, setExploreType] = servicesOwnLoans ? useState('applications') : useState('loans');
  const [embedId] = useState(randomString());
  const embedDomain = `?embed_domain=${window.location.origin}`;
  const reportPath = 'embed/explore/lending-partnership-reporting/';

  useEffect(() => {
    if (productView && currentProductKey) {
      getEmbedUrl({
        productKey: currentProductKey,
        embedPath: `${reportPath}${exploreType}${embedDomain}`,
        embedDomain,
        investorName: null,
      }).then(result => setUrl(result.data.url));
    } else if (investorView && currentInvestorName) {
      getEmbedUrl({
        productKey: null,
        embedPath: `${reportPath}${exploreType}${embedDomain}`,
        embedDomain,
        investorName: currentInvestorName,
      }).then(result => setUrl(result.data.url));
    }
  }, [productView, currentProductKey, investorView, currentInvestorName,
    reportPath, exploreType, embedDomain]);

  return (
    <>
      {url.length === 0 ? (<Loader data-testid="loader" />) : (
        <Grid container item xs={12} spacing={2}>
          {!servicesOwnLoans && (
            <Grid item xs={12}>
              <Select
                onChange={e => setExploreType(e.target.value)}
                value={exploreType}
                label="Explore Type"
              >
                <MenuItem value="applications">Applications Data</MenuItem>
                <MenuItem value="loans">Loans Data</MenuItem>
                <MenuItem value="repayments">Repayments</MenuItem>
              </Select>
            </Grid>
          )}
          <Grid item xs={12}>
            <div className={classes.iframeExploreWrapper}>
              <iframe
                id={embedId}
                className={[classes.iframe, classes.iframeHeight].join(' ')}
                title="Look"
                src={url}
                width="100%"
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AnalyticsExplore;
