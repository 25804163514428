import { parseISO, format, startOfMonth, differenceInMonths } from 'date-fns';

export const MONTHS_IN_YEAR = 12;

function* dateGenerator(startingDate, numOfMonths) {
  const currDate = startOfMonth(new Date(startingDate));
  for (let i = numOfMonths; i >= 0; i -= 1) {
    yield format(currDate, 'yyyy-MM-dd');
    currDate.setMonth(currDate.getMonth() + 1);
  }
}

export const getMonthlySeriesDefault = (startingDate, seriesLength, defaultValues) => {
  const getDates = dateGenerator(startingDate, seriesLength);

  return Array.from({ length: seriesLength }, () => {
    const date = getDates.next().value;
    return {
      id: date,
      type: 'monthly_default',
      attributes: {
        date,
        ...defaultValues,
      },
    };
  });
};

export const populateMonthlySeries = (seriesDefault, series) => {
  const populated = seriesDefault.map(x => {
    const realData = series.find(y => {
      const defaultDate = new Date(y.attributes.date);
      const dataDate = new Date(x.attributes.date);

      return defaultDate.getUTCMonth() === dataDate.getUTCMonth() &&
        defaultDate.getUTCFullYear() === dataDate.getUTCFullYear();
    });
    return realData || x;
  });

  return populated;
};

export const selectAttribute = attributeName => ({ attributes }) => attributes[attributeName];

export const selectMonthlyDateLabel =
  (lastUpdatedDate, showAsterisk = true) => ({ attributes: { date } }) => {
    const label = format(parseISO(date), 'MMMM yyyy');
    const isDataIncomplete = differenceInMonths(new Date(date), lastUpdatedDate) >= 0;
    return isDataIncomplete && showAsterisk ? `${label}*` : label;
  };
