import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import Accordion from '@material-ui/core/Accordion';
import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Select,
} from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import { ExpandMore } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { Input, InputLabel } from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getProductTypes } from 'api/productTypes';
import { getOwners } from 'api/owner';
import Grid from '@material-ui/core/Grid';
import Papa from 'papaparse';
import Alert from '@material-ui/lab/Alert';
import { useForecast } from '../../contexts/ForecastContext';
import FileDropzone from '../FileDropzone';
import Loader from '../Loader';

const linkButtonStyle = {
  background: 'none',
  color: 'blue',
  border: 'none',
  padding: 0,
  font: 'inherit',
  textDecoration: 'underline',
  cursor: 'pointer',
};

const ForecastFilters = () => {
  const {
    expanded,
    handleContinue,
    handleSaveAndExit,
    handlePanel,
    selectedProductTypes,
    setSelectedProductTypes,
    selectedOwners,
    setSelectedOwners,
    asOfDate,
    setAsOfDate,
    setLoanIds,
    loanIdCount,
    setError,
    isLoading,
    isEditable,
    state,
    name,
  } = useForecast();

  const loanIdsCsvData =
    state?.loanPool?.attributes?.loanIds?.map(loanId => ({ LoanID: loanId })) ??
    [];

  const [productTypes, setProductTypes] = useState(null);
  const [owners, setOwners] = useState(null);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const validFileTypes = '.csv';

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
  };

  const loadFilterData = () => {
    getProductTypes()
      .then(response => {
        setProductTypes(response.data.data);
      })
      .catch(() => {
        // Handle error
      });
    getOwners()
      .then(response => {
        setOwners(response.data.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    loadFilterData();
  }, []);

  const handleChangeProductTypes = e => {
    setSelectedProductTypes(e.target.value);
  };

  const handleChangeOwners = e => {
    setSelectedOwners(e.target.value);
  };

  const handleChangeAsOfDate = date => {
    if (!date) {
      setAsOfDate(null);
    } else {
      setAsOfDate(date.toLocaleDateString());
    }
  };

  const validateHeader = file => {
    setIsCsvLoading(true);
    const acceptedHeader = 'loan_id';
    const uploadedHeaders = file.meta.fields;
    if (uploadedHeaders.length > 1) {
      setError('CSV can only contain Header: loan_id');
      return false;
    } else if (
      uploadedHeaders.length === 1 &&
      uploadedHeaders[0] !== acceptedHeader
    ) {
      setError('CSV must contain Header: loan_id');
      return false;
    } else if (uploadedHeaders.length === 0) {
      setError('CSV has no data');
      return false;
    }

    return true;
  };

  const processLoanIdCsv = file => {
    const reader = new FileReader();
    reader.onload = event => {
      const text = event.target.result;
      Papa.parse(text, {
        complete: results => {
          if (validateHeader(results)) {
            const loanIds = results.data.map(row => row.loan_id);
            setLoanIds(loanIds);
            setSelectedProductTypes(null);
            setSelectedOwners(null);
            setIsCsvLoading(false);
          } else {
            setIsCsvLoading(false);
          }
        },
        error: error => {
          setError(error.message);
          setIsCsvLoading(false);
        },
        worker: true,
        header: true,
        dynamicTyping: false,
        skipEmptyLines: true,
      });
    };
    reader.onerror = error => {
      setError(error);
      setIsCsvLoading(false);
    };
    reader.readAsText(file);
  };

  const handleFileUpload = acceptedFiles => {
    setIsCsvLoading(true);
    acceptedFiles.forEach(file => {
      processLoanIdCsv(file);
    });
  };

  const handleRejectUpload = rejectedFiles => {
    setIsCsvLoading(true);
    rejectedFiles.forEach(file => {
      setError(
        `File Upload ${file.file.name} Rejected: ${file.errors[0].message}`,
      );
    });
    setIsCsvLoading(false);
  };

  const handleRemoveLoans = () => {
    setLoanIds([]);
  };

  const renderSelectedProductTypes = selected => selected
    .map(
      value => productTypes.find(type => type.attributes.id === value).attributes
        .name,
    )
    .join(', ');

  const renderSelectedOwners = selected => selected
    .map(
      value => owners.find(type => type.attributes.externalInvestorId === value)
        .attributes.sfdcName,
    )
    .join(', ');

  return (
    <>
      <Accordion
        expanded={expanded === 'all' || expanded === 'filter-panel'}
        onChange={handlePanel('filter-panel')}
        style={{ borderRadius: 4, backgroundColor: 'white' }}
        id="filter-panel"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          style={{ fontWeight: 'bold' }}
        >
          <Avatar
            sx={{
              bgcolor: '#00807B',
              width: 18,
              height: 18,
              fontSize: 10,
              marginRight: '5px',
              fontFamily: 'Montserrat',
            }}
          >
            2
          </Avatar>
          {' '}
          {isEditable ? 'Build Loan Pool' : 'Loan Pool'}
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <Grid container direction="column">
              <Loader data-testid="loader" size={40} />
            </Grid>
          ) : (
            <>
              <Grid container direction="column">
                <h2>Select filters</h2>
                {productTypes && (
                <FormControl>
                  <InputLabel id="product-type-selector-label">
                    Product(s)
                  </InputLabel>
                  <Select
                    labelId="product-type-selector-label"
                    id="product-type-selector"
                    multiple
                    label="Product(s)"
                    value={selectedProductTypes}
                    onChange={handleChangeProductTypes}
                    input={<Input />}
                    renderValue={renderSelectedProductTypes}
                    MenuProps={MenuProps}
                    style={{ maxWidth: '400px' }}
                    disabled={(!isEditable || (loanIdCount > 0))}
                  >
                    {productTypes.map(data => (
                      <MenuItem
                        key={data.attributes.name}
                        value={data.attributes.id}
                      >
                        <Checkbox
                          checked={selectedProductTypes.indexOf(data.attributes.id) > -1}
                        />
                        <ListItemText primary={data.attributes.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
                {owners && (
                <FormControl>
                  <InputLabel id="owner-selector-label">Owner(s)</InputLabel>
                  <Select
                    labelId="owner-selector-label"
                    id="owner-selector"
                    multiple
                    label="Owner(s)"
                    value={selectedOwners}
                    onChange={handleChangeOwners}
                    input={<Input />}
                    renderValue={renderSelectedOwners}
                    MenuProps={MenuProps}
                    style={{ maxWidth: '400px' }}
                    disabled={!isEditable || (loanIdCount > 0)}
                  >
                    {owners.map(data => (
                      <MenuItem
                        key={data.attributes.sfdcName}
                        value={data.attributes.externalInvestorId}
                      >
                        <Checkbox
                          checked={selectedOwners.indexOf(
                            data.attributes.externalInvestorId,
                          ) > -1}
                        />
                        <ListItemText primary={data.attributes.sfdcName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="as-of-date-picker"
                    label="As of date"
                    value={asOfDate}
                    onChange={handleChangeAsOfDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{ maxWidth: '400px' }}
                    disabled={!isEditable}
                  />
                </MuiPickersUtilsProvider>
                <Grid hidden={!isEditable}>
                  <br />
                  <Button
                    variant="contained"
                    onClick={handleSaveAndExit}
                    style={{
                      borderRadius: 0,
                      background: '#F3F3F3',
                      color: '#00807B',
                      width: '150px',
                      marginRight: '10px',
                    }}
                  >
                    Save and exit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleContinue}
                    style={{
                      borderRadius: 0,
                      background: '#00807B',
                      color: 'white',
                      width: '150px',
                    }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={1} style={{ display: 'inline-block' }}>
                <div
                  style={{
                    width: '1px',
                    backgroundColor: '#F3F3F3',
                    height: '100%',
                  }}
                />
              </Grid>
              <Grid container direction="column">
                <h2>Upload loan IDs</h2>
                {isCsvLoading ? (
                  <Loader data-testid="loader" size={40} />
                ) : (
                  <>
                    <FileDropzone
                      onDrop={handleFileUpload}
                      multiple={false}
                      description="Click to upload"
                      accept={validFileTypes}
                      onDropRejected={handleRejectUpload}
                    />
                    {!isCsvLoading && loanIdCount > 0 && (
                    <Alert severity="success">
                      {loanIdCount}
                      {' '}
                      Loan IDs uploaded -
                      {' '}
                      <CSVLink
                        data={loanIdsCsvData}
                        filename={`loan_ids_${name}_${parseInt(Date.now(), 10)}.csv`}
                        className="btn btn-primary"
                        target_="_blank"
                      >
                        Download CSV
                      </CSVLink>
                      {' '}
                      /
                      {' '}
                      <button style={linkButtonStyle} onClick={handleRemoveLoans} type={'button'}>
                        Remove
                      </button>
                    </Alert>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ForecastFilters;
