import React, { useMemo } from 'react';
import { useProduct } from 'contexts/ProductContext';
import investorsLogo from 'assets/investors.png';
import forecastingLogo from 'assets/forecasting.png';
import { useView } from 'contexts/ViewContext';
import useStyle from './style';
import { useAuth } from '../../contexts/AuthContext';

const BankLogo = () => {
  const classes = useStyle();
  const { authState } = useAuth();
  const { currentProduct } = useProduct();
  const { productView, investorView, forecastView } = useView();

  const issuingBankName = useMemo(
    () => currentProduct?.attributes?.issuingBankName,
    [currentProduct?.attributes?.issuingBankName],
  );
  const issuingBankSlug = useMemo(
    () => currentProduct?.attributes?.issuingBankSlug,
    [currentProduct?.attributes?.issuingBankSlug],
  );
  const issuingBankLogo = useMemo(
    () => currentProduct?.attributes?.bankLogo,
    [issuingBankSlug],
  );

  if (productView) {
    return (
      <div className={classes.toolbarLogo}>
        {issuingBankSlug && issuingBankLogo && authState.isAuthenticated && (
          <img className={classes.image} src={issuingBankLogo} alt={`${issuingBankName} Logo`} />
        )}
      </div>
    );
  } else if (investorView) {
    return (
      <div className={classes.toolbarLogo}>
        <img className={classes.image} src={investorsLogo} alt={`Investors Logo`} />
      </div>
    );
  } else if (forecastView) {
    return (
      <div className={classes.toolbarLogo}>
        <img className={classes.image} src={forecastingLogo} alt={`Forecasting Logo`} />
      </div>
    );
  } else {
    return null;
  }
};

export default BankLogo;
