/* eslint-disable react/no-this-in-sfc */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useAsync } from 'react-async';
import { add, startOfMonth, differenceInMonths, format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'components/Loader';
import { useProduct } from 'contexts/ProductContext';
import { getMonthlyPortfolioLoanMetrics } from 'api/performanceMetric';
import abbreviateNumber from 'utils/abbreviateNumber';
import theme from 'theme';
import {
  getMonthlySeriesDefault,
  populateMonthlySeries,
  selectMonthlyDateLabel,
  MONTHS_IN_YEAR, selectAttribute,
} from 'utils/monthlySeries';
import useLegendStyles from 'theme/legendStyle';
import Typography from '@material-ui/core/Typography';
import useStyle from './style';

const { highlight } = theme.palette;
const { tooltip, legend, chart } = theme.chartStyle;

const YEAR_OPTIONS = [1, 3, 5];
const MAX_MONTHS = Math.max(...YEAR_OPTIONS) * MONTHS_IN_YEAR;
const EWAALR = 'weightedAverageAnnualizedLossRate';

const MonthlyWeightedAverageLossesAndNetReturns = () => {
  const classes = useStyle();
  const legendClasses = useLegendStyles({ color: highlight.main });

  const [year] = useState(1);
  const [series, setSeries] = useState([]);
  const { currentProductKey } = useProduct();

  // default series to length of MAX_MONTHS with 0.00% weighted average apr
  const metricBirthday = new Date(2022, 1, 1);
  const yearStart = startOfMonth(add(Date.now(), { months: -MAX_MONTHS }));
  const startingDate = (metricBirthday > yearStart ? metricBirthday : yearStart);
  const seriesDefault = useMemo(
    () => getMonthlySeriesDefault(
      startingDate,
      differenceInMonths(Date.now(), startingDate),
      { [EWAALR]: 0 },
    ),
    [MAX_MONTHS],
  );

  // populate series from api
  const { data, isLoading, isFulfilled } = useAsync(getMonthlyPortfolioLoanMetrics, {
    limit: MAX_MONTHS,
    productKey: currentProductKey,
    watch: currentProductKey,
  });

  const monthlyData = data?.data?.data || [];
  useEffect(() => {
    setSeries(
      populateMonthlySeries(seriesDefault, monthlyData),
    );
  }, [isFulfilled]);

  // create highchart data/labels for series[YEAR_OPTIONS[0]]... series[YEAR_OPTIONS[n]]
  const ewaalrData = useMemo(() => series.map(selectAttribute(EWAALR) ?? 0), [series]);
  const monthlyLabels = useMemo(
    () => series.map(selectMonthlyDateLabel(new Date(), false)),
    [series],
  );

  const getSeriesForYear = useCallback(
    (totalSeries, yr) => totalSeries.slice(-MONTHS_IN_YEAR * yr),
    [],
  );

  const highchartSeries = [
    {
      data: getSeriesForYear(ewaalrData, year),
      name: 'Expected weighted average annualized loss rates',
      pointPlacement: 'on',
      lineWidth: 1,
      lineColor: highlight.main,
      style: { marginRight: 24 },
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, Highcharts.color(highlight.main).setOpacity(0.4).get('rgba')],
          [1, Highcharts.color(highlight.main).setOpacity(0.0).get('rgba')],
        ],
      },
      marker: {
        enabled: false,
        fillColor: highlight.main,
        lineColor: highlight.main,
      },
    },
  ];

  const options = {
    series: highchartSeries,
    legend: {
      ...legend,
      className: legendClasses.chartLegend,
      itemStyle: {
        ...legend.itemStyle,
        color: highlight.main,
      },
    },
    offset: -15,
    title: {
      text: '',
    },
    plotOptions: {
      area: {
        dataLabels: {
          enabled: true,
          format: '{y:.2f}%',
          color: highlight.main,
          padding: 15,
          alignment: 'right',
          style: {
            fontWeight: 'normal',
          },
        },
      },
    },
    chart: {
      style: {
        ...chart.style,
      },
      marginRight: 72,
      type: 'area',
    },
    xAxis: [{
      offset: 3,
      tickAmount: 12,
      categories: getSeriesForYear(monthlyLabels, year),
      crosshair: true,
      labels: {
        step: Math.floor(year * 2 - 1),
        y: 32,
        style: { fontSize: 14 },
        styledMode: true,
        format: '{value}',
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          const label = this.axis.defaultLabelFormatter.call(this);
          return [label.split(/\s/)].map(x => `${x[0].slice(0, 3)} ${x[1]}`)[0].replace(/\s/, '<br>');
        },
      },
    }],
    yAxis: {
      offset: 2,
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        formatter() {
          const label = this.axis.defaultLabelFormatter.call(this);
          return `${abbreviateNumber(label)}.0%`;
        },
        style: {
          color: '#757575',
          fontSize: 12,
          backgroundColor: 'white',
        },
        step: 1,
      },
      title: {
        text: '',
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      ...tooltip,
      headerFormat: '',
      pointFormatter() {
        return `
          <strong>${getSeriesForYear(monthlyLabels, year)[this.x].replace('<br/>', ' ')}</strong><br>
          <p>${this.y} Expected Weighted Average Annualized Loss Rate</p><br />
        `;
      },
    },
  };

  const Chart = () => (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <div className={classes.root}>
        <Typography component="p" className={classes.text} style={{ margin: '-1em 1em 1em auto', fontFamily: 'Open Sans' }}>
          {`*Data is available no earlier than ${format(metricBirthday, 'M/d/yyyy')}`}
        </Typography>
      </div>
    </>
  );

  return (
    <>
      {
        isLoading ?
          <Loader data-testid="loader" /> :
          <Chart />
      }
    </>
  );
};

export default MonthlyWeightedAverageLossesAndNetReturns;
