import { getExternalInvestors } from 'api/externalInvestor';
import { useUser } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-async';
import { useCookies } from 'react-cookie';
import { useInstitution } from 'contexts/InstitutionContext';

export const ExternalInvestorContext = createContext();

export const useExternalInvestor = () => ({ ...useContext(ExternalInvestorContext) });

export const ExternalInvestorContextProvider = ({ children }) => {
  const { user } = useUser();
  const [externalInvestors, setExternalInvestors] = useState([]);
  const [currentExternalInvestor, setCurrentExternalInvestor] = useState(null);
  const currentInvestorName = useMemo(() => {
    if (currentExternalInvestor) {
      return currentExternalInvestor.attributes.sfdcInvestorName;
    }
    return null;
  }, [currentExternalInvestor]);
  const currentNameCookieName = 'reporting-console-current-externalinvestor-key';
  const [cookies, setCookie] = useCookies([currentNameCookieName]);
  const {
    run,
    data,
    isFulfilled,
    isLoading: externalInvestorLoading,
    counter: externalInvestorCallCounter,
  } = useAsync({ deferFn: getExternalInvestors, defer: true });
  const {
    currentInstitution,
  } = useInstitution();

  useEffect(() => {
    if (user && currentInstitution) {
      run(
        user.external_investors?.map(group => group.replace('upstart_investor_', '')).join(','),
        currentInstitution.id,
      );
    } else {
      setCurrentExternalInvestor(null);
    }
  }, [user, currentInstitution]);

  useEffect(() => {
    if (isFulfilled) {
      const listExternalInvestors = data.data.data;
      setExternalInvestors(listExternalInvestors);

      // Once we support institutions in looker, if there multiple external investors,
      // it can default to blank for all investors for the institution.
      if (cookies[currentNameCookieName]) {
        const first = listExternalInvestors.length > 0 ? listExternalInvestors[0] : null;
        setCurrentExternalInvestor(
          listExternalInvestors.find(
            pp => pp.attributes.sfdcInvestorName === cookies[currentNameCookieName],
          ) || first,
        );
      } else if (listExternalInvestors.length > 0) {
        setCurrentExternalInvestor(listExternalInvestors[0]);
        setCookie(currentNameCookieName,
          listExternalInvestors[0].attributes.sfdcInvestorName);
      } else {
        setCurrentExternalInvestor(null);
        setCookie(currentNameCookieName, null);
      }
    }
  }, [isFulfilled, data]);

  useEffect(() => {
    if (currentExternalInvestor) {
      setCookie(currentNameCookieName,
        currentExternalInvestor.attributes.sfdcInvestorName);
    }
  }, [currentExternalInvestor]);

  return (
    <ExternalInvestorContext.Provider
      value={{
        externalInvestors,
        currentInvestorName,
        currentExternalInvestor,
        setCurrentExternalInvestor,
        externalInvestorLoading,
        externalInvestorCallCounter,
      }}
    >
      {children}
    </ExternalInvestorContext.Provider>
  );
};

ExternalInvestorContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ExternalInvestorContextProvider.defaultProps = {
  children: [],
};
