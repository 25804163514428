import apiClient from 'api/apiClient';

// eslint-disable-next-line import/prefer-default-export

export const getForecastConfigurations = () => apiClient.get('/v1/forecast_configurations');

export const getForecastConfiguration = uuid => apiClient.get(`/v1/forecast_configurations/${uuid}`);

export const createForecastConfiguration = data => apiClient.post('/v1/forecast_configurations', data);

export const updateForecastConfiguration = (forecastUuid, data) => apiClient.patch(`/v1/forecast_configurations/${forecastUuid}`, data);

export const duplicateForecastConfiguration = (forecastUuid, data) => apiClient.get(`/v1/forecast_configurations/duplicate/${forecastUuid}`, data);

export const deleteForecastConfiguration = forecastUuid => apiClient.delete(`/v1/forecast_configurations/${forecastUuid}`);

export const enableForecastConfigurationNightlyRuns = uuid => apiClient.get(`/v1/forecast_configurations/enable_nightly_runs/${uuid}`);

export const disableForecastConfigurationNightlyRuns = uuid => apiClient.get(`/v1/forecast_configurations/disable_nightly_runs/${uuid}`);

export const rerunForecastConfiguration = uuid => apiClient.get(`/v1/forecast_configurations/re_run/${uuid}`);

export const submitForecastConfiguration = uuid => apiClient.put(`/v1/forecast_configurations/submit/${uuid}`);

export default getForecastConfigurations;
