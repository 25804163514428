import { Box, Tab } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import AnalyticsExplore from 'components/AnalyticsExplore';
import ReportsSaved from 'components/ReportsSaved';
import ReportsSplit from 'components/ReportsSplit';
import { useAnalytics } from 'contexts/AnalyticsContext';
import React from 'react';
import useStyles from './style';

const ReportsTabbed = () => {
  const classes = useStyles();
  const { analyticsPremiumEnabled } = useAnalytics();
  const [selectedTab, setSelectedTab] = React.useState('applications');

  const onSelectedTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList variant="scrollable" scrollButtons={'auto'} onChange={onSelectedTabChange}>
            <Tab label="Applications" value="applications" />
            <Tab label="Loans" value="loans" />
            <Tab label="Program" value="program" />
            <Tab label="Finance" value="finance" />
            <Tab label="My Reports" value="my-reports" data-testid="tab-my-reports" />
            <Tab
              style={{ visibility: analyticsPremiumEnabled ? 'visible' : 'hidden' }}
              className={[classes.linkLikeText, classes.floatRight, classes.hideMobile].join(' ')}
              label="+ Create Report"
              value="create-report"
            />
          </TabList>
        </Box>
      </Grid>
      <Grid item container xs={12}>
        <TabPanel className={classes.tabPanel} value="applications">
          <ReportsSplit category="Applications" />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="loans">
          <ReportsSplit category="Loans" />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="program">
          <ReportsSplit category="Program" />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="finance">
          <ReportsSplit category="Finance" />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="my-reports">
          <ReportsSaved />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="create-report">
          <AnalyticsExplore />
        </TabPanel>
      </Grid>
    </TabContext>
  );
};

export default ReportsTabbed;
