/* eslint-disable */
import MuiTooltip from '@material-ui/core/Tooltip';
import { Typography, Link, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyle from './style';
import { getUmi } from '../../api/upstartMacro';

const UMIDisplay = () => {
  const classes = useStyle();
  const [umiData, setUmiData] = useState(null);
  const [errors, setErrors] = useState({ umi: false });

  useEffect(() => {
    getUmi()
      .then(response => {
        setUmiData(response.data);
      })
      .catch(() => {
        setErrors({ umi: true });
      });
  }, []);

  const umiIncreased = umiData ? umiData.umiIncreased : true;
  const umiMonth = umiData ? umiData.month.split(' ')[0] : '';
  const umiPercentage = umiData ? umiData.percentageChanged : '';
  const umiNum = umiData ? umiData.currentUmi : '';
  const percentTooltip = `UMI has ${umiIncreased ? 'increased' : 'decreased'} m/m`;
  const percentTipElement = <Typography style={{ fontSize: '13px' }}>{percentTooltip}</Typography>;
  const learnMoreLink = 'https://www.upstart.com/umi/';

  return (
    <Box mx={3}>
      { (errors.umi || umiData == "") && (
        <Typography className={classes.errors} data-testid='error-no-data'>Data not available</Typography>
      ) }
      { umiData != "" && (
        <div className={classes.outermostContainer}>
          <Typography component="p" className={classes.title}>
            {umiMonth}
            &nbsp;UMI
          </Typography>
          <div className={classes.umiDiv}>
            <Typography className={classes.umiNum}  data-testid='umi-value'>{umiNum}</Typography>
            <div className={classes.percentContainer}>
              <MuiTooltip arrow placement="top" title={percentTipElement}>
                <div className={umiIncreased ? classes.percentBoxUp: classes.percentBoxDown} data-testid='umi-increased'>
                  <div className={classes.percentBoxContent} data-testid='umi-change-value'>
                    <Typography>
                      { umiPercentage }
                      %
                    </Typography>
                    <div className={classes.arrowContainer}>
                      <span
                        className={umiIncreased ? classes.triangleUp : classes.triangleDown}
                      />
                    </div>
                  </div>
                </div>
              </MuiTooltip>
            </div>
          </div>
          <Link
            underline="always"
            href={learnMoreLink}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>
        </div>
      ) }
    </Box>
  );
};

export default UMIDisplay;
