import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  button: {
    margin: '8px 8px 8px 0px',
    background: 'white',
    padding: 9,
    borderRadius: '25px !important',
    borderTopRightRadius: 25,
    border: 'solid',
    borderWidth: 1.5,
    borderColor: theme.palette.neutral.borderLight,
    minWidth: 80,
    height: 40,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      alignSelf: 'center',
    },
    textAlign: 'center',
    '&.MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
      borderLeft: '1px solid',
      borderColor: theme.palette.neutral.borderLight,
    },
    '&$selected': {
      borderColor: '#1952A7 !important',
    },
  },
  selected: {
    borderColor: '#1952A7 !important',
  },
  datePicker: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
  },
}));

export default useStyles;
