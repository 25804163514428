import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: theme.typography.secondary.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.055em',
    textDecoration: 'none',
  },
  link: {
    '&:hover': {
      color: theme.palette.highlight.main,
      textDecoration: 'underline',
    },
  },
  reportTitle: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
    textTransform: 'inherit',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
}));

export default useStyles;
