import React, { useEffect, useRef } from 'react';
import { useProduct } from 'contexts/ProductContext';
import { useReport } from 'contexts/ReportContext';
import { useAuth } from '../../contexts/AuthContext';

const DownloadFileForm = () => {
  const { currentProductKey } = useProduct();
  const { currentReportDownloadState } = useReport();
  const { authState } = useAuth();
  const formRef = useRef();

  const actionEndpoint = () => {
    if (currentReportDownloadState.actionEndpoint !== '') {
      return `${process.env.REACT_APP_BANK_PARTNER_CONSOLE_API_BASE_URL}/v1/products/${currentProductKey}/reports/${currentReportDownloadState.actionEndpoint}`;
    }
    return '#';
  };

  useEffect(() => {
    if (currentReportDownloadState.reportId) {
      formRef.current.submit();
    }
  }, [currentReportDownloadState]);

  return (
    <form
      ref={formRef}
      method={'POST'}
      action={actionEndpoint()}
      data-testid={'download-file-form'}
    >
      <input data-testid={'report-id-input'} type={'hidden'} name={currentReportDownloadState.paramName} value={currentReportDownloadState.reportId} />
      <input data-testid={'cxf2j3'} type={'hidden'} name={'px7ij'} value={authState?.accessToken} />
      <input type={'submit'} style={{ display: 'none' }} />
    </form>
  );
};

export default DownloadFileForm;
