const abbreviateNumber = (number, decimalPlaces = 2) => {
  const decPlaces = 10 ** decimalPlaces;
  const suffixes = ['K', 'M', 'B', 'T'];
  let result = number;

  for (let i = suffixes.length - 1; i >= 0; i -= 1) {
    const size = 10 ** ((i + 1) * 3);
    if (size <= result) {
      result = Math.round(result * (decPlaces / size)) / decPlaces;
      if ((result === 1000) && (i < suffixes.length - 1)) {
        result = 1;
        i += 1;
      }
      result += suffixes[i];
      break;
    }
  }

  return result;
};

export default abbreviateNumber;
