import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  input: {
    background: '#ffffff',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  inputOpen: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  inputContainer: {
    transition: 'all 0.6s ease',
  },
  inputContainerOpen: {
    width: '100%',
  },
  inputContainerClosed: {
    width: '0px',
    padding: '0px',
  },
}));

export default useStyles;
