import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import { sharedClasses } from 'theme/sharedStyles';

const styles = theme => (
  {
    horizontalRuleMargins: {
      marginTop: '40px',
      marginBottom: '40px',
    },
    headerLink: {
      color: theme.palette.primary.main,
      marginLeft: 20,
      alignSelf: 'center',
      textAlign: 'left',
      fontSize: '16px',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  }
);

const useStyles = makeStyles(theme => createStyles({
  ...sharedClasses(theme),
  ...styles(theme),
}));

export default useStyles;
