import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getFolders } from 'api/analyticsApi';
import PortfolioAnalyticsDashboard from 'components/PortfolioAnalyticsDashboard';
import PortfolioAnalyticsReport from 'components/PortfolioAnalyticsReport';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Loader from 'components/Loader';
import SquareIconButton from 'components/SquareIconButton';
import { useAnalytics } from 'contexts/AnalyticsContext';
import CircleChevron from 'icons/CircleChevron/CircleChevron';
import React, { useState } from 'react';
import { useAsync } from 'react-async';
import theme from 'theme';
import sharedStyles from 'theme/sharedStyles';

const PortfolioReportsSaved = () => {
  const classes = sharedStyles();
  const [selected, setSelected] = React.useState();
  const [selectedType, setSelectedType] = React.useState('');
  const { analyticsPremiumEnabled } = useAnalytics();
  const { neutral } = theme.palette;
  const [collapsed, setCollapsed] = useState(false);

  const { data, isLoading } = useAsync(getFolders);

  const folders = data?.data;

  const onClick = (item, type) => {
    setSelected(item);
    setSelectedType(type);
    setCollapsed(true);
  };

  const collapsedClick = () => {
    setCollapsed(!collapsed);
  };

  const someReports = () => (
    folders?.some(folder => (folder.looks?.length + folder.dashboards?.length) > 0)
  );

  const selectedView = () => {
    if (selectedType === 'look') {
      return (
        <Grid container>
          <Grid item className={classes.header}>
            <div className={classes.headerText}>{selected.title}</div>
          </Grid>
          <PortfolioAnalyticsReport reportPath={`/embed${selected.url}`} showDateSelector={false} />
        </Grid>
      );
    } else {
      return (
        <PortfolioAnalyticsDashboard
          headerText={selected.title}
          reportPath={`/embed${selected.url}`}
          showDateSelector={false}
          subHeader={false}
        />
      );
    }
  };

  const noneSelectedView = () => {
    if (someReports()) {
      return (
        <Card className={classes.reportListWrapper}>
          <CardContent className={classes.headerText}>
            <Typography>Please select a report</Typography>
          </CardContent>
        </Card>
      );
    } else {
      return (
        analyticsPremiumEnabled ?
          (
            <Card className={classes.reportListWrapper}>
              <CardContent className={classes.headerText} data-testid="main-card-content">
                <Typography>
                  {'Don\'t have any custom reports yet? Click "Create Report" on the top right!'}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <Card className={classes.reportListWrapper}>
              <CardContent className={classes.headerText} data-testid="main-card-content">
                <span>
                  <Typography>
                    {'Your organization doesn\'t have custom reports yet.'}
                  </Typography>
                  <Typography>
                    {'Reach out to your admin if you have questions on how to start creating custom reports.'}
                  </Typography>
                </span>
              </CardContent>
            </Card>
          )
      );
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item className={collapsed ? classes.sideBarCollapsed : classes.sideBar}>
        <Card className={classes.reportListWrapper}>
          <SquareIconButton
            className={collapsed ? `${classes.sideListCollapseButton} collapsed` : classes.sideListCollapseButton}
            data-testid="collapse-button"
            icon={collapsed ? CircleChevron(neutral.text, 'left') : CircleChevron(neutral.text, 'right')}
            onClick={() => collapsedClick()}
          />
          {isLoading && <Loader data-testid="loader" />}
          {!isLoading && !collapsed && (
            <CardContent data-testid="side-card-content">
              {!someReports() && (<Typography>No reports available</Typography>)}
              {someReports() && folders
                .filter(folder => folder.looks?.length > 0 || folder.dashboards?.length > 0)
                .map(folder => (
                  <Box className={classes.reportList}>
                    <div className={classes.reportListHeader}>{folder.name}</div>
                    <hr className={classes.horizontalRule} />
                    {folder.looks.map(item => (
                      <Button
                        className={
                          `${classes.reportListTitle} ${item === selected ? 'selected' : ''}`
                        }
                        key={item.url}
                        onClick={() => onClick(item, 'look')}
                      >
                        {item.title}
                      </Button>
                    ))}
                    {folder.dashboards.map(item => (
                      <Button
                        className={
                          `${classes.reportListTitle} ${item === selected ? 'selected' : ''}`
                        }
                        key={item.url}
                        onClick={() => onClick(item, 'dashboard')}
                      >
                        {item.title}
                      </Button>
                    ))}
                    <br />
                    <br />
                  </Box>
                ))}
            </CardContent>
          )}
        </Card>
      </Grid>
      <Grid item className={classes.mainContent}>
        {isLoading && (
          <Card className={classes.reportListWrapper}>
            <CardContent>
              <Loader data-testid="loader" />
            </CardContent>
          </Card>
        )}
        {!isLoading && (selected ? selectedView() : noneSelectedView())}
      </Grid>
    </Grid>
  );
};

export default PortfolioReportsSaved;
