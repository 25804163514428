/* eslint-disable react/no-this-in-sfc */
import * as React from 'react';
import { useAsync } from 'react-async';
import { parseISO, add, startOfMonth } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'components/Loader';
import { useProduct } from 'contexts/ProductContext';
import abbreviateNumber from 'utils/abbreviateNumber';
import { getDailyOriginationLoanMetricByMonth } from 'api/performanceMetric';
import theme from 'theme';
import LastUpdatedNotifier from 'components/LastUpdatedNotifier';
import RangeButton from 'components/RangeButton';
import {
  getMonthlySeriesDefault,
  populateMonthlySeries,
  selectAttribute,
  selectMonthlyDateLabel,
  MONTHS_IN_YEAR,
} from 'utils/monthlySeries';

const { sky, teal, primary, turquoise } = theme.palette;
const { tooltip, legend, chart } = theme.chartStyle;

const YEAR_OPTIONS = [1, 3, 5];
const MAX_MONTHS = Math.max(...YEAR_OPTIONS) * MONTHS_IN_YEAR;
const [NUMBER_OF_LOANS, LOANS_VALUE, AVG_LOAN_AMT] = ['numberOfLoans', 'loansValue', 'avgLoanAmt'];

const OriginationsByMonth = () => {
  const [year, setYear] = React.useState(1);
  const [series, setSeries] = React.useState([]);
  const { currentProductKey } = useProduct();
  const startingDate = startOfMonth(add(Date.now(), { months: -MAX_MONTHS + 1 }));

  const seriesDefault = React.useMemo(
    () => getMonthlySeriesDefault(startingDate, MAX_MONTHS, {
      [NUMBER_OF_LOANS]: 0,
      [LOANS_VALUE]: 0,
      [AVG_LOAN_AMT]: 0,
    }), [MAX_MONTHS],
  );

  const { data, isLoading, isFulfilled } = useAsync(getDailyOriginationLoanMetricByMonth, {
    from: startingDate,
    productKey: currentProductKey,
    watch: currentProductKey,
  });

  const lastUpdatedDate = parseISO(data?.data?.meta?.lastUpdatedDate);
  const monthlyLoanMetrics = data?.data?.data || [];

  React.useEffect(() => {
    setSeries(
      populateMonthlySeries(seriesDefault, monthlyLoanMetrics),
    );
  }, [isFulfilled]);

  const numberOfLoans = React.useMemo(() => series.map(selectAttribute(NUMBER_OF_LOANS)),
    [series]);

  const loansValue = React.useMemo(() => series.map(selectAttribute(LOANS_VALUE)),
    [series]);

  const avgLoanAmount = React.useMemo(
    () => series.map(selectAttribute(AVG_LOAN_AMT)), [series],
  );

  const monthlyLabels = React.useMemo(
    () => series.map(selectMonthlyDateLabel(lastUpdatedDate)),
    [series],
  );

  const getSeriesForYear = React.useCallback(
    (totalSeries, yr) => totalSeries.slice(-MONTHS_IN_YEAR * yr),
    [],
  );

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  const highchartSeries = [
    {
      name: 'Originations',
      type: 'column',
      color: teal.main,
      marker: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: '$',
        pointFormat: '<p>{point.y} Originations</p><br>',
        pointFormatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `<p>$${abbreviateNumber(this.y)} Originations</p><br />`;
        },
      },
      lineWidth: 1,
      lineColor: teal.main,
      data: getSeriesForYear(loansValue, year),
      zoneAxis: 'x',
      zones: [{
        value: getSeriesForYear(numberOfLoans, year)
          .length - (getSeriesForYear(monthlyLabels, year).filter(x => x.includes('*')).length),
      }, {
        color: sky.main,
      }],
    },
    {
      name: 'MTD',
      type: 'areaspline',
      color: sky.main,
      marker: {
        enabled: false,
      },
      lineWidth: 0,
    },
    {
      name: 'Avg Loan Amt',
      color: turquoise.main,
      showInLegend: false,
      marker: {
        enabled: false,
        fillColor: 'transparent',
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      lineWidth: 0,
      lineColor: 'transparent',
      yAxis: 1,
      data: getSeriesForYear(avgLoanAmount, year),
      tooltip: {
        valuePrefix: '$',
        pointFormat: '<p>{point.y} Avg Loan Amt</p><br>',
        pointFormatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `<p>$${abbreviateNumber(this.y)} Avg Loan Amt</p><br />`;
        },
      },
    },
    {
      name: 'Loan units per mo.',
      type: 'line',
      color: primary.main,
      marker: {
        enabled: false,
        symbol: 'circle',
        lineColor: null,
        lineWidth: 0,
      },
      lineWidth: 2,
      lineColor: primary.main,
      yAxis: 1,
      data: getSeriesForYear(numberOfLoans, year),
      tooltip: {
        pointFormat: '<p>{point.y} Loan Units</p>',
        pointFormatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `<p>${abbreviateNumber(this.y)} Loan Units</p><br />`;
        },
      },
    },
  ];

  const options = {
    title: {
      text: '',
    },
    chart: {
      ...chart,
    },
    tooltip,
    plotOptions: {
      column: {
        groupPadding: 0.25,
        borderWidth: 0,
        pointPadding: 0,
        pointWidth: Math.ceil(24 / year),
      },
      area: {
        marker: {
          enabled: false,
        },
      },
    },
    legend,
    xAxis: [{
      showLastLabel: true,
      endOnTick: true,
      offset: 3,
      tickAmount: 12,
      labels: {
        step: Math.floor(year * 2 - 1),
        y: 32,
        style: { fontSize: 14 },
        styledMode: true,
        format: '{value}',
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          const label = this.axis.defaultLabelFormatter.call(this);
          return [label.split(/\s/)].map(x => `${x[0].slice(0, 3)} ${x[1]}`)[0].replace(/\s/, '<br>');
        },
      },
      categories: getSeriesForYear(monthlyLabels, year),
      crosshair: true,
    }],
    yAxis: [{
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          const label = this.axis.defaultLabelFormatter.call(this);
          return `$${abbreviateNumber(label)}`;
        },
        style: {
          color: '#757575',
          fontSize: 12,
        },
      },
      max: Math.max(...getSeriesForYear(loansValue, year)),
      title: {
        text: 'Loan Originations',
        offset: 64,
        style: {
          color: '#757575',
          fontSize: 14,
        },
      },
    }, {
      max: Math.max(...getSeriesForYear(numberOfLoans, year)),
      gridLineWidth: 0,
      title: {
        text: 'Loan Units',
        rotation: '-90',
        offset: 64,
        style: {
          color: '#6E6E6E',
          fontSize: 14,
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: '#6E6E6E',
          fontSize: 12,
        },
      },
      opposite: true,
    }],
    credits: {
      enabled: false,
    },
    series: highchartSeries,
  };

  const Chart = () => (
    <>
      <nav style={{ display: 'flex', margin: '-9px 0 36px 0' }}>
        { YEAR_OPTIONS.map(num => (<RangeButton {...{ year, setYear, value: num, key: num }} />)) }
      </nav>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <LastUpdatedNotifier date={lastUpdatedDate} />
    </>
  );

  return (
    <>
      {
        isLoading ?
          <Loader data-testid="loader" /> :
          <Chart />
      }
    </>
  );
};

export default OriginationsByMonth;
