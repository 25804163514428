import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    border: '2px dashed #C2C2C2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '162px',
    color: '#C2C2C2',
  },
  dragActiveRoot: {
    background: '#F3F3F3',
  },
  icon: {
    fontSize: '3em',
  },
  message: {
    fontFamily: theme.typography.secondary.fontFamily,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
  },
}));

export default useStyles;
