import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F3F3F3',
    borderRadius: '4px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#222929',
    fontFamily: theme.typography.secondary.fontFamily,
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

export default useStyles;
