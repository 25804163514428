import { Grid } from '@material-ui/core';
import getAppConfig from 'api/appConfig';
import { useEffect, useState } from 'react';
import getPortfolioAnalyticsEmbedUrl from 'api/portfolioAnalyticsEmbed';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import { randomString } from 'utils/toolsService';
import useStyles from './style';
import ReportDatesSelector from '../ReportDatesSelector';

const PortfolioAnalyticsDashboard = (
  {
    reportPath,
    headerText,
    linkText,
    linkUrl,
    showDivider,
    showDateSelector,
    dateFilterField,
    subBoard,
    subHeader,
    subHeaderText,
  },
) => {
  const classes = useStyles();
  const { data: appConfigData } = useAsync(getAppConfig);
  const [url, setUrl] = useState('');
  const [contentHeight, setContentHeight] = useState('600px');
  const [embedId] = useState(randomString());
  const [isExploring, setIsExploring] = useState(false);
  const embedDomain = `?embed_domain=${window.location.origin}`;

  useEffect(() => {
    const handleMessage = event => {
      if (event.source === document.getElementById(embedId)?.contentWindow) {
        if (event.origin === `https://${appConfigData?.data.analyticsRootUrl}`) {
          const data = JSON.parse(event.data);
          // eslint-disable-next-line default-case
          switch (data.type) {
            case 'page:properties:changed':
              setContentHeight(`${data.height}px`);
              break;
            case 'env:client:dialog':
              if (data.dialogType === 'drilling') {
                setIsExploring(data.open);
              }
              break;
            case 'page:changed':
              setIsExploring(!data.page.url.startsWith(`/${reportPath}`));
              break;
          }
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [appConfigData]);

  useEffect(() => {
    getPortfolioAnalyticsEmbedUrl({
      embedPath: `${reportPath}${embedDomain}`,
      embedDomain,
    }).then(result => setUrl(result.data.url));
  }, []);

  const headerClass = () => {
    if (subHeader) {
      return classes.subHeaderText;
    }
    return classes.headerText;
  };

  const shiftUp = () => {
    if (subHeader || subHeaderText !== '') {
      return classes.shiftUp;
    }
    return '';
  };

  const determineType = () => {
    if (reportPath.includes('dashboard')) {
      return 'dashboard';
    }
    if (reportPath.includes('look')) {
      return 'look';
    }
    return 'explore';
  };

  const runRequest = {
    type: `${determineType()}:run`,
  };

  // eslint-disable-next-line no-unused-vars
  const onDatesChange = filter => {
    // Build the request
    const type = determineType();
    const request = `{
      "type": "${type}:filters:update",
      "filters": {
        "${dateFilterField}": "${filter}"
      }
    }`;
    const embed = document.getElementById(embedId);
    embed?.contentWindow?.postMessage(request, `https://${appConfigData?.data.analyticsRootUrl}`);
    embed?.contentWindow?.postMessage(
      JSON.stringify(runRequest), `https://${appConfigData?.data.analyticsRootUrl}`,
    );
  };

  // return the iframe component with the embed url as the src
  return (

    <Grid item xs={12}>
      <div style={{ marginBottom: '80px' }} />
      <Grid container>
        <Grid item xs={12} md={8} className={headerClass()}>
          {headerText}
          <a className={classes.headerLink} href={linkUrl}>
            {linkText}
          </a>
        </Grid>
      </Grid>
      {subBoard && (
      <Grid item xs={12}>
        {subBoard}
      </Grid>
      )}
      {subHeaderText !== '' && (
      <div>
        <Grid item xs={12} md={8} className={classes.subHeaderText2}>
          {subHeaderText}
        </Grid>
      </div>
      )}
      {showDateSelector && (
      <Grid item xs={12} style={{ visibility: isExploring ? 'hidden' : 'visible' }}>
        <ReportDatesSelector defaultDates="1Y" onDatesChange={onDatesChange} />
      </Grid>
      )}
      <Grid item xs={12}>
        <div className={shiftUp()}>
          <div className={classes.iframeDashboardWrapper}>
            <iframe
              id={embedId}
              className={classes.iframe}
              title="Look"
              src={url}
              height={contentHeight}
              width="100%"
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        {showDivider && (<hr className={[classes.horizontalRule, classes.horizontalRuleMargins].join(' ')} />)}
      </Grid>
    </Grid>
  );
};

export default PortfolioAnalyticsDashboard;

PortfolioAnalyticsDashboard.propTypes = {
  dateFilterField: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  reportPath: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
  showDateSelector: PropTypes.bool,
  subBoard: PropTypes.element,
  subHeader: PropTypes.bool,
  subHeaderText: PropTypes.string,
};

PortfolioAnalyticsDashboard.defaultProps = {
  dateFilterField: 'Origination Date',
  linkText: '',
  linkUrl: '',
  showDivider: true,
  showDateSelector: true,
  subBoard: null,
  subHeader: true,
  subHeaderText: '',
};
