import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  text: {
    color: '#757575',
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Montserrat',
    lineHeight: '20px',
  },
}));

export default useStyles;
