import React, { useState, useMemo, useEffect } from 'react';
import { useAsync } from 'react-async';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReportTypeSearch from 'components/ReportTypeSearch';
import FileDropzone from 'components/FileDropzone';
import FileDisplay from 'components/FileDisplay';
import Loader from 'components/Loader';
import { createReport, getValidContentTypes } from 'api/report';
import { getIssuingBanks, parseIssuingBanks } from 'api/issuingBank';
import useStyle from './style';

const ReportForm = () => {
  const classes = useStyle();
  const { goBack } = useHistory();
  const fileLimit = 1;
  const [reportType, setReportType] = useState(null);
  const [file, setFile] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [banks, setBanks] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validContentTypes, setValidContentTypes] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const { data: issuingBanks, isLoading, isFulfilled } = useAsync(getIssuingBanks);
  const allBanksChecked = banks.length > 0 && banks.every(bank => bank.checked);
  const anyBanksChecked = banks.length > 0 && banks.some(bank => bank.checked);
  const selectedBanks = banks.filter(bank => bank.checked)
    .map(bank => `${bank.attributes.name} (${bank.attributes.slug})`).join(', ');
  const formComplete = useMemo(
    () => !!file && !!reportType && anyBanksChecked, [file, reportType, anyBanksChecked],
  );

  const onReportTypeChange = v => {
    setReportType(v);
  };

  const onDrop = files => {
    setFile(files[0]);
  };

  const resetForm = () => {
    setBanks(parseIssuingBanks(issuingBanks).map(bank => ({ ...bank, checked: false })));
    setFile(null);
    setShowDialog(false);
    setIsDisabled(false);
  };

  const submit = async () => {
    if (isDisabled) return;
    setErrorMessage(null);
    const issuingBankSlugs = banks.filter(bank => bank.checked).map(bank => bank.attributes.slug);
    const data = new FormData();
    data.append('report[file]', file);
    data.append('report_type_slug', reportType.value);
    data.append('issuing_bank_slugs', issuingBankSlugs.join(','));
    setSubmitLoading(true);
    setIsDisabled(true);
    try {
      await createReport(data);
      setSubmitLoading(false);
      setSuccessMessage(
        `File ${file.name} was uploaded to ${reportType.display} for ${issuingBankSlugs.join(',')}.`,
      );
      resetForm();
    } catch (e) {
      setErrorMessage(e.response.data?.message ?? e.message);
      setShowDialog(false);
    }
  };

  const allBanksChange = e => {
    const { checked } = e.target;
    setBanks(prevState => prevState.map(bank => ({ ...bank, checked })));
  };

  const individualBankChange = e => {
    const slug = e.target.value;
    const { checked } = e.target;
    setBanks(prevState => prevState.map(bank => (
      bank.attributes.slug === slug ? { ...bank, checked } : bank
    )));
  };

  useEffect(() => {
    if (isFulfilled) {
      setBanks(
        parseIssuingBanks(issuingBanks).map(bank => ({ ...bank, checked: false })),
      );
    }
  }, [issuingBanks, isFulfilled]);

  useEffect(() => {
    getValidContentTypes().then(response => setValidContentTypes(response.data.join(',')));
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              <Grid item xs={12} elevation={0}>
                <ReportTypeSearch onChange={onReportTypeChange} />
              </Grid>
            </Grid>
            <Grid item xs={12} elevation={0}>
              {file ? (
                <FileDisplay file={file} onClear={() => setFile(null)} />
              ) : (
                <FileDropzone
                  limit={fileLimit}
                  onDrop={onDrop}
                  multiple={false}
                  accept={validContentTypes}
                  description={'Limit one file per report.'}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose Bank</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={allBanksChecked} onChange={allBanksChange} name="all-banks" value="all-banks" />}
                label="All banks"
              />
              <Divider />
              {isLoading && (
                <Box mt={2}>
                  <Loader data-testid="loader" />
                </Box>
              )}
              {banks.map(bank => (
                <FormControlLabel
                  key={bank.id}
                  control={(
                    <Checkbox
                      checked={bank.checked}
                      onChange={individualBankChange}
                      name={bank.attributes.slug}
                      value={bank.attributes.slug}
                    />
                  )}
                  label={`${bank.attributes.name} (${bank.attributes.slug})`}
                  data-testid="bank-checkbox"
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} elevation={0}>
          <Button
            className={classes.addReportButton}
            variant="contained"
            color="primary"
            onClick={() => setShowDialog(true)}
            disableElevation
            size="large"
            disabled={!formComplete || submitLoading}
            data-testid="add-report-button"
          >
            {submitLoading ? 'Adding Report...' : 'Add Report'}
          </Button>
          <Button variant="contained" disableElevation size="large" onClick={goBack}>
            Cancel
          </Button>
        </Grid>
        {errorMessage && (
          <Alert severity="error">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success">
            {successMessage}
          </Alert>
        )}
      </Grid>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="warning-dialog"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContent id="alert-dialog-description">
            <Grid container spacing={2} className={classes.dialogContentGrid}>
              <Grid item xs={12}>
                <Typography>For Lender(s):</Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Typography>
                  {allBanksChecked ? 'All Banks' : selectedBanks}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>You are uploading the following file:</Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <Typography>Report Type:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{reportType?.display}</Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <Typography>File Name:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{file?.name}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} variant="contained" size="large" data-testid="dialog-cancel-button">
            Cancel
          </Button>
          <Button disabled={isDisabled} onClick={submit} variant="contained" color="primary" size="large" autoFocus data-testid="create-report-button">
            Create Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportForm;
