import React, { useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import { useProduct } from 'contexts/ProductContext';

const TrustPilot = () => {
  const { currentProductKey, currentProduct } = useProduct();
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [currentProductKey]);

  return (
    <Box mx={3}>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="539ad0ffdec7e10e686debd7"
        data-businessunit-id="5509ab1f0000ff00057e23d4"
        data-style-height="340px"
        data-style-width="100%"
        data-theme="light"
        data-tags={currentProduct?.attributes?.issuingBankSlug}
        data-stars="5"
        data-review-languages="en"
      />
    </Box>
  );
};

export default TrustPilot;
