import React, { useEffect, useState } from 'react';
import { Typography, Checkbox, TextField, Button } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useStyle from './style';
import { getUmf, updateUmfValue } from '../../api/upstartMacro';

/* This convert date method prevents javascript from converting UTC dates to be one day off
 * There is a "Unnecessary escape character: \/ no-useless-escape" so eslint needs to be disabled
 */
// eslint-disable-next-line
export const convertDate = (date) => new Date(date.replace(/-/g, '\/').replace(/T.+/, '')).toLocaleDateString();
const UMFEditor = () => {
  const classes = useStyle();
  const [selectedDate, setSelectedDate] = useState(null);
  const [input, setInput] = useState('');
  const [umfData, setUmfData] = useState(null);
  const [check, setCheck] = useState(false);
  const [error, setError] = useState(false);
  const umfNum = umfData ? umfData.value : '';
  const umfDate = umfData ? convertDate(umfData.asOfDate) : '';

  const saveHelper = isChecked => {
    if (input === '' || selectedDate === null) {
      alert('Please input UMF and date before proceeding!');
      return;
    }
    if (isChecked) {
      if (window.confirm(`Update UMF to be ${input}`)) {
        updateUmfValue(input, new Date(selectedDate).toDateString())
          .then(() => {
            alert(`New UMF has been updated to ${input}!`);
            setInput('');
            setSelectedDate(null);
            setCheck(false);
          });
      }
    } else {
      alert('Please check the change acknowledgement before proceeding!');
    }
  };

  useEffect(() => {
    getUmf()
      .then(response => {
        setUmfData(response.data);
        setError(false);
      })
      .catch(() => {
        setError(true);
      });
  }, [check]);

  const handleSelectedDateChange = (event, newDate) => {
    setSelectedDate(newDate);
  };

  return (
    <div className={classes.outerContainer}>
      { !error && (
        <div>
          <Typography className={classes.body}>
            Current UMF:&nbsp;
            { umfNum }
          </Typography>
          <Typography>
            As of date&nbsp;
            { umfDate }
          </Typography>
        </div>
      )}
      <div className={classes.inputContainer}>
        <TextField
          value={input}
          label={'Input new UMF'}
          onChange={e => {
            setInput(e.target.value);
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.datePicker}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="As of:"
            value={selectedDate}
            onChange={handleSelectedDateChange}
          />
        </MuiPickersUtilsProvider>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              saveHelper(check);
            }}
          >
            Save
          </Button>
        </div>
      </div>
      <div className={classes.checkContainer}>
        <Checkbox
          checked={check}
          onChange={() => {
            setCheck(!check);
          }}
          name="third-party-sign-off"
        />
        <div className={classes.acknowledgeContainer}>
          <Typography>
            I acknowledge that a third party has reviewed and signed off on this change.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default UMFEditor;
