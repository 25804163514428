import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import useStyle from './style';

const Insights = () => {
  const classes = useStyle();

  return (
    <Box mx={2}>
      <Typography component="p" className={classes.title}>Take the Upstart Program Certification</Typography>
      <Typography component="p" className={classes.body}>
        Our course is designed to help you understand every step of your program,
        helping you be regulatory-ready.
      </Typography>
      <br />
      <Typography component="p">
        Reach out to&nbsp;
        <Link
          underline="always"
          href="mailto: lendersupport@upstart.com"
          className={classes.body}
          target="_blank"
          rel="noopener noreferrer"
        >
          lendersupport@upstart.com
        </Link>
        &nbsp; for your code.
      </Typography>
    </Box>
  );
};

export default Insights;
