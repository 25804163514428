import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { CSVLink } from 'react-csv';
import {
  Tabs,
  Tab,
  FormControl,
  Input,
  Grid,
  Select,
  MenuItem,
  ListItemText,
  Button,
  Avatar,
} from '@material-ui/core';
import FileDropzone from 'components/FileDropzone';
import Papa from 'papaparse';
import { useForecast } from 'contexts/ForecastContext';
import { Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PushPinIcon from '@mui/icons-material/PushPin';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ForecastInputModifierErrorPanel from 'components/ForecastInputModiferErrorPanel/ForecastInputModifierErrorPanel';
import Loader from '../Loader';
import Spreadsheet from '../Spreadsheet';
import { getCurrentForecastConsensus } from '../../api/forecastConsensusHistory';
import { getForecastConfigurations } from '../../api/forecastConfiguration';
import { searchManagementAssumptionAdjustment } from '../../api/managementAssumptionAdjustment';

const ForecastInputModifierTab = ({
  currentTab,
  setCurrentTab,
  allTabs,
  currentAdjustment,
  setCurrentAdjustment,
  searchForecastMaas,
  currentAdjustmentId,
  csvData,
  setCsvData,
  handleAddToAdjustments,
  duplicateAdjustment,
}) => {
  const {
    createAdjustment,
    updateAdjustment,
    isEditable,
    setError,
    isValidated,
    setIsValidated,
    handleValidateAdjustments,
    handleSubmit,
    isValidating,
  } = useForecast();
  const [adjustmentChanged, setAdjustmentChanged] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [currentHeaders, setCurrentHeaders] = useState([]);
  const history = useHistory();
  const validFileTypes = '.csv';
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
  };

  // eslint-disable-next-line max-len
  const readyForSubmit = !!(!!isValidated && Object.values(isValidated).every(valid => valid === true) && !adjustmentChanged);
  const buttonText = readyForSubmit ? 'Submit' : 'Save and Validate';

  const getCurrentHeaders = tab => {
    if (!tab || !currentAdjustment) return [];

    const headers = Object.keys(
      currentAdjustment.attributes?.adjustments[0] || [],
    );
    if (!headers || headers.length === 0) return [];

    const gridHeaders = headers.map(h => ({
      field: h,
      editable: isEditable,
      flex: 1,
    }));
    gridHeaders.unshift({ field: 'id', editable: false, flex: 1 });
    return gridHeaders;
  };

  const handleSaveAdjustment = (forceUpdate = false) => {
    if (!adjustmentChanged && !forceUpdate) {
      return;
    }
    if (!currentAdjustmentId) {
      const dataPayload = {
        adjustmentType: currentTab,
        adjustments: currentAdjustment?.attributes?.adjustments || [],
      };
      // eslint-disable-next-line consistent-return
      return createAdjustment(dataPayload);
    } else {
      const dataPayload = {
        adjustmentType: currentTab,
        adjustments: currentAdjustment?.attributes?.adjustments || [],
      };
      // eslint-disable-next-line consistent-return
      return updateAdjustment(currentAdjustmentId, dataPayload);
    }
  };

  const processRowUpdate = async updatedRow => {
    setCurrentAdjustment(prevAdj => {
      const newAdjustments = [...prevAdj.attributes.adjustments];
      const newAdjustment = { ...updatedRow };
      delete newAdjustment.id;
      newAdjustments[updatedRow.id - 1] = newAdjustment;
      return {
        ...prevAdj,
        attributes: {
          ...prevAdj.attributes,
          adjustments: newAdjustments,
        },
      };
    });
    setAdjustmentChanged(true);
    return updatedRow;
  };

  useEffect(() => {
    setCsvData(currentAdjustment?.attributes?.adjustments);
  }, [currentAdjustment]);

  const validateCsvUpload = file => {
    setIsCsvLoading(true);
    const missingHeaders = [];
    const extraHeaders = [];
    const uploadedKeys = Object.keys(file.data[0]);
    const acceptedHeaders = allTabs.find(
      tab => tab.value === currentTab,
    ).permittedHeaders;
    acceptedHeaders.forEach(head => {
      if (!uploadedKeys.includes(head)) {
        missingHeaders.push(head);
      }
    });
    uploadedKeys.forEach(head => {
      if (!acceptedHeaders.includes(head)) {
        extraHeaders.push(head);
      }
    });
    if (missingHeaders.length > 0 && extraHeaders.length === 0) {
      setError(`Missing Header(s): ${missingHeaders}`);
      return false;
    } else if (extraHeaders.length > 0 && missingHeaders.length === 0) {
      setError(`Incorrect Header(s): ${extraHeaders}`);
      return false;
    } else if (missingHeaders.length > 0 && extraHeaders.length > 0) {
      setError(
        `Missing Header(s): ${missingHeaders}; Incorrect Header(s): ${extraHeaders}`,
      );
      return false;
    }

    return true;
  };

  useEffect(() => {
    const headers = getCurrentHeaders(currentTab);
    setCurrentHeaders(headers);
  }, [currentAdjustment]);

  const handleSaveAndExit = async () => {
    setError('');
    try {
      await handleSaveAdjustment();
      history.push('/');
    } catch (err) {
      setError(err.message);
    }
  };

  const processCsv = file => {
    const reader = new FileReader();
    reader.onload = event => {
      const text = event.target.result;
      Papa.parse(text, {
        complete: results => {
          if (validateCsvUpload(results)) {
            setCurrentAdjustment({
              attributes: {
                adjustments: results.data,
              },
            });
            setCsvData(results.data);
            setIsCsvLoading(false);
            setIsValidated(false);
          } else {
            setAdjustmentChanged(false);
            setIsCsvLoading(false);
          }
        },
        error: e => {
          setError(e.message);
          setIsCsvLoading(false);
        },
        header: true,
        dynamicTyping: false,
        skipEmptyLines: true,
      });
    };
    reader.readAsText(file);
  };

  const handleFileUpload = acceptedFiles => {
    setIsCsvLoading(true);
    setAdjustmentChanged(true);
    acceptedFiles.forEach(file => {
      processCsv(file);
    });
  };

  const handleDuplicate = async str => {
    const maaToDuplicate = JSON.parse(str);
    let adjustment;

    if (maaToDuplicate.id === 'consensus') {
      const currentConsensus = await getCurrentForecastConsensus();
      const currentConsensusForecastId =
        currentConsensus.data.data?.attributes?.forecastConfigurationId;

      if (!currentConsensusForecastId) {
        setError('There is no consensus set.');
        return;
      }
      const forecasts = await getForecastConfigurations();

      const consensusForecast = forecasts.data.data.submittedForecasts.find(
        f => f.id === currentConsensusForecastId.toString(),
      );

      if (!consensusForecast) {
        setError(`Consensus forecast not found.`);
      } else {
        const foundMaa = await searchManagementAssumptionAdjustment(
          consensusForecast.attributes.uuid,
          currentTab,
        );

        if (!foundMaa?.data?.data[0]?.id) {
          setError('Consensus Forecast does not have this input modifier set.');
          return;
        }
        adjustment = await duplicateAdjustment(foundMaa?.data?.data[0]?.id, {
          source_configuration_uuid: consensusForecast.attributes.uuid,
        });
      }
    } else {
      adjustment = await duplicateAdjustment(maaToDuplicate.id, {
        source_configuration_uuid: maaToDuplicate.uuid,
      });
    }

    if (!adjustment) {
      setError('Failed to duplicate adjustment');
      return;
    }
    handleAddToAdjustments(adjustment.data.data);
    setCurrentAdjustment(adjustment.data.data);
    setAdjustmentChanged(false);
  };

  const handleRejectUpload = rejectedFiles => {
    setIsCsvLoading(true);
    rejectedFiles.forEach(file => {
      setError(
        `File Upload ${file.file.name} Rejected: ${file.errors[0].message}`,
      );
    });
    setIsCsvLoading(false);
  };

  const handleTabChange = async (event, newCurrentTab) => {
    if (adjustmentChanged) {
      try {
        const newAdjustment = await handleSaveAdjustment();
        handleAddToAdjustments(newAdjustment.data.data);
        setAdjustmentChanged(false);
        setError(null);
        setCurrentTab(newCurrentTab);
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError(null);
      setCurrentTab(newCurrentTab);
    }
  };

  const formatTab = category => {
    if (!!isValidated && isValidated[category.value] !== true &&
      isValidated[category.value]?.errors?.adjustments.length > 0
    ) {
      return (
        <div style={{ display: 'flex', gap: '4px' }}>
          {category.label}
          <Avatar
            variant="rounded"
            style={{
              backgroundColor: '#FF6767',
              width: 40,
              height: 20,
              fontSize: 16,
              marginRight: '5px',
              fontFamily: 'Montserrat',
            }}
          >
            {
              isValidated[category.value].errors.adjustments.length
            }
          </Avatar>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', gap: '4px' }}>{category.label}</div>
      );
    }
  };

  const triggerSaveAndValidate = async () => {
    await handleSaveAdjustment(true);
    setAdjustmentChanged(false);
    handleValidateAdjustments();
  };

  const StyledTab = withStyles(theme => ({
    root: {
      fontSize: theme.typography.pxToRem(12),
    },
  }))(props => <Tab disableRipple {...props} />);

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="wrapped scrollable auto label tabs"
      >
        {allTabs.map(category => (
          <StyledTab
            label={formatTab(category)}
            value={category.value}
            key={category.value}
          />
        ))}
      </Tabs>
      <Grid container justifyContent="space-around" direction="row">
        {searchForecastMaas && currentHeaders && (
        <Grid item xs={5}>
          <FormControl fullWidth={'true'}>
            <h4>Auto-fill values from a previous forecast</h4>
            <Select
              labelId="forecast-selector-label"
              id="forecast-selector"
              label="Forecast Adjustment"
              onChange={event => handleDuplicate(event.target.value)}
              value=""
              input={<Input />}
              MenuProps={MenuProps}
              style={{ width: 'auto' }}
              disabled={!isEditable}
            >
              <MenuItem
                key="consensus"
                value={JSON.stringify({
                  id: 'consensus',
                  uuid: 'consensus',
                })}
              >
                <ListItemIcon>
                  <PushPinIcon />
                </ListItemIcon>
                <ListItemText primary="Consensus Assumption Set" />
              </MenuItem>
              {searchForecastMaas.map(forecast => (
                <MenuItem
                  key={forecast.id}
                  value={JSON.stringify({
                    id: forecast.id,
                    uuid: forecast.attributes.forecastConfigurationUuid,
                  })}
                >
                  <ListItemText primary={forecast.attributes.forecastName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        )}
        <Grid item xs={5}>
          <h4>
            {isEditable ? 'Upload ' : 'Download '}
            sheet with values inputted (CSV)
          </h4>
          {isCsvLoading ? (
            <Loader data-testid="loader" size={40} />
          ) : (
            <>
              {isEditable ? (
                <FileDropzone
                  onDrop={handleFileUpload}
                  multiple={false}
                  description="Click to upload"
                  accept={validFileTypes}
                  onDropRejected={handleRejectUpload}
                />
              ) : null}
              {!isCsvLoading && currentAdjustment && (
              <Alert severity="success">
                File Upload Successful -
                {' '}
                <CSVLink
                  data={csvData}
                  filename={`${currentTab}_${parseInt(Date.now(), 10)}.csv`}
                  className="btn btn-primary"
                  target="_blank"
                >
                  Download CSV
                </CSVLink>
              </Alert>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {''}
      <br />
      {!!isValidated && isValidated[currentTab] != null &&
        isValidated[currentTab] !== true && (
        <ForecastInputModifierErrorPanel
          adjustmentErrors={isValidated[currentTab]}
        />
      )}
      {currentAdjustment && (
      <Grid container direction="column">
        <Spreadsheet
          columns={currentHeaders || []}
          data={
            currentAdjustment.attributes?.adjustments?.map((adjustment, index) => ({
              ...adjustment,
              id: index + 1,
            })) || []
          }
          tableHeader={isEditable ? 'Review & edit values' : 'View values'}
          processRowUpdate={processRowUpdate}
        />
      </Grid>
      )}
      <Grid container direction="row" spacing={2} style={{ marginTop: 16 }}>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleSaveAndExit}
            style={{
              display: isEditable ? 'block' : 'none',
              borderRadius: 0,
              background: 'white',
              color: '#00807B',
              width: '150px',
            }}
          >
            Save and exit
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={readyForSubmit ? handleSubmit : triggerSaveAndValidate}
            disabled={!!isValidating}
            style={{
              borderRadius: 0,
              background: '#00807B',
              color: 'white',
              width: '180px',
            }}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ForecastInputModifierTab;

ForecastInputModifierTab.defaultProps = {
  currentAdjustmentId: null,
  csvData: '',
  currentAdjustment: null,
};

ForecastInputModifierTab.propTypes = {
  currentTab: PropTypes.string.isRequired,
  allTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      permittedHeaders: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  searchForecastMaas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        id: PropTypes.number,
        forecastName: PropTypes.string,
        uuid: PropTypes.string,
      }),
    }),
  ).isRequired,
  duplicateAdjustment: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  currentAdjustment: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    attributes: PropTypes.shape({
      adjustmentType: PropTypes.string,
      adjustments: PropTypes.arrayOf(PropTypes.shape()),
      forecastConfigurationId: PropTypes.number,
      productTypeName: PropTypes.string,
    }),
  }),
  setCurrentAdjustment: PropTypes.func.isRequired,
  currentAdjustmentId: PropTypes.number,
  csvData: PropTypes.string,
  setCsvData: PropTypes.func.isRequired,
  handleAddToAdjustments: PropTypes.func.isRequired,
};
