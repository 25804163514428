import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ForecastsTable from 'components/ForecastsTable/ForecastsTable';
import { PropTypes } from 'prop-types';
import { duplicateForecastConfiguration, deleteForecastConfiguration } from 'api/forecastConfiguration';
import ConfirmModal from '../ConfirmModal';
import ForecastRenameModal from '../ForecastRenameModal';

const DraftForecasts = ({ draftForecasts, isLoading }) => {
  const forecasts = draftForecasts.map(({ attributes }) => attributes);
  const history = useHistory();
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [forecastName, setForecastName] = useState('');
  const [forecastUUID, setForecastUUID] = useState('');

  const columns = useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'forecastName',
      enableGlobalFilter: true,
    },
    {
      header: 'Owner',
      accessorKey: 'createdBy',
      enableGlobalFilter: true,
    },
    {
      header: 'Date Modified',
      accessorKey: 'lastModified',
      enableGlobalFilter: true,
    },
  ]);

  const showRenameModalForForecastConfiguration = row => {
    setIsRenameModalOpen(true);
    setForecastUUID(row.original.uuid);
    setForecastName(row.original.forecastName);
  };

  const showDeleteModal = uuid => {
    setIsDeleteModalOpen(true);
    setForecastUUID(uuid);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const handleCloseRenameModal = () => {
    setIsRenameModalOpen(false);
    reloadPage();
  };

  const handleDuplicate = async uuid => {
    try {
      const result = await duplicateForecastConfiguration(uuid);
      const { attributes } = result.data.data;
      history.push(`/forecast-configuration/${attributes.uuid}`);
    } catch (err) {
      alert(`Error duplicating forecast configuration: ${err.message}`);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    reloadPage();
  };

  const handleConfirmDeleteModal = async () => {
    try {
      await deleteForecastConfiguration(forecastUUID);
      history.push(`/forecast-configurations`);
    } catch (err) {
      alert(`Error deleting forecast configuration: ${err.message}`);
    }
    handleCloseDeleteModal();
  };

  const performAction = (row, action) => {
    if (action === 'edit') {
      history.push(`/forecast-configuration/${row.original.uuid}`);
    } else if (action === 'rename') {
      showRenameModalForForecastConfiguration(row);
    } else if (action === 'duplicate') {
      handleDuplicate(row.original.uuid);
    } else if (action === 'delete') {
      showDeleteModal(row.original.uuid);
    }
  };

  const initialTableState = {
    showLoadingOverlay: false,
    sorting: [{ id: 'lastModified', desc: true }],
    isRenameModalOpen: false,
    showGlobalFilter: true,
    pagination: { pageSize: 5 },
    columnPinning: { right: ['mrt-row-actions'] },
  };

  const rowMenuActions = [
    'edit', 'duplicate', 'rename', 'delete',
  ];

  return (
    <div>
      <h1>Draft Forecasts</h1>
      <ForecastsTable
        columns={columns}
        forecasts={forecasts}
        initialTableState={initialTableState}
        isLoading={isLoading}
        rowMenuActions={rowMenuActions}
        performAction={performAction}
      />
      <ForecastRenameModal
        isOpen={isRenameModalOpen}
        onClose={handleCloseRenameModal}
        forecastName={forecastName}
        forecastUuid={forecastUUID}
      />
      <ConfirmModal
        title="Delete Forecast"
        description="Are you sure you want to delete this forecast?"
        isOpen={isDeleteModalOpen}
        onExit={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
      />
    </div>
  );
};

DraftForecasts.propTypes = {
  draftForecasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      attributes: PropTypes.shape({
        uuid: PropTypes.string,
        forecastName: PropTypes.string,
        createdBy: PropTypes.string,
        lastModified: PropTypes.string,
      }),
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DraftForecasts;
