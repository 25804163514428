import React from 'react';
import { Grid } from '@material-ui/core';
import PortfolioAnalyticsDashboard from '../../components/PortfolioAnalyticsDashboard';

const CreditPerformance = () => (
  <Grid container>
    <Grid container justifyContent="space-between" alignItems="center">
      <PortfolioAnalyticsDashboard
        headerText={''}
        reportPath={'embed/dashboards/upstart_portfolio_analytics::upa_credit_performance'}
        showDivider={false}
        showDateSelector={false}
      />
    </Grid>
  </Grid>
);

export default CreditPerformance;
