import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  addReportButton: {
    marginRight: theme.spacing(2),
  },
  dialogContentGrid: {
    lineBreak: 'auto',
    wordBreak: 'break-word',
  },
  errorMessage: {
    color: '#FF0000',
    fontWeight: 'bold',
  },
}));

export default useStyles;
