import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listIconContainer: {
    background: `${theme.palette.highlight.main}20`,
    color: theme.palette.highlight.main,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
  },
  link: {
    fontFamily: theme.typography.secondary.fontFamily,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.055em',
    textDecorationLine: 'underline',
    color: theme.palette.highlight.main,
  },
  timeStamp: {
    fontFamily: theme.typography.secondary.fontFamily,
    fontSize: '16px',
    letterSpacing: '0.055em',
    color: '#222929',
  },
}));

export default useStyles;
