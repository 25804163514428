import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import React, { createContext, useContext, useMemo } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import AuthContextProvider from 'contexts/AuthContext';
import { ViewContextProvider } from 'contexts/ViewContext';
import { ProductContextProvider } from 'contexts/ProductContext';
import { InstitutionContextProvider } from 'contexts/InstitutionContext';
import { ExternalInvestorContextProvider } from 'contexts/ExternalInvestorContext';
import { UserContextProvider } from 'contexts/UserContext';
import { TimePeriodContextProvider } from 'contexts/TimePeriodContext';
import theme from 'theme';
import Layout from 'components/Layout';
import { useAsync } from 'react-async';
import getAppConfig from 'api/appConfig';
import Loader from 'components/Loader';

export const AppConfigContext = createContext({});
export const useAppConfig = () => ({ ...useContext(AppConfigContext) });

const App = () => {
  const { data: appConfigData } = useAsync(getAppConfig);
  const appConfig = appConfigData?.data;
  const value = useMemo(() => ({ appConfig }), [appConfig]);
  if (!appConfigData?.data) return <Loader />;

  return (
    <AppConfigContext.Provider value={value}>
      <AuthContextProvider>
        <UserContextProvider>
          <InstitutionContextProvider>
            <ExternalInvestorContextProvider>
              <ProductContextProvider>
                <ViewContextProvider>
                  <TimePeriodContextProvider>
                    <AnalyticsContextProvider>
                      <ThemeProvider theme={theme}>
                        <Layout />
                      </ThemeProvider>
                    </AnalyticsContextProvider>
                  </TimePeriodContextProvider>
                </ViewContextProvider>
              </ProductContextProvider>
            </ExternalInvestorContextProvider>
          </InstitutionContextProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </AppConfigContext.Provider>
  );
};

export default App;
