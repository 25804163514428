import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import computer from '../../assets/computer.svg';
import { useUser } from '../../contexts/UserContext';
import useStyles from './style';
import PortfolioAnalyticsDashboard from '../../components/PortfolioAnalyticsDashboard';

const PortfolioAnalytics = () => {
  const { user } = useUser();
  const [showBanner, setShowBanner] = useState(false);
  const [cookies, setCookie] = useCookies(['showBanner']);
  const emptyName = '';
  const welcomeMessageSuffix = user?.name ? `, ${user.name.split(' ')[0]}` : emptyName;

  useEffect(() => {
    if (cookies.showBanner === undefined) {
      setShowBanner(true);
      setCookie('showBanner', true);
    } else if (cookies.showBanner === 'true') {
      setShowBanner(true);
    }
  }, [cookies]);

  const classes = useStyles();

  return (
    <Grid container>
      <Grid container justifyContent="space-between" alignItems="center">

        {showBanner && (
          <div className={classes.welcomeBanner}>
            <div className={classes.welcomeBannerContent}>
              <img src={computer} alt="" className={classes.welcomeBannerIcon} />
              <div>
                <h1 className={classes.welcomeHeader}>{`Welcome${welcomeMessageSuffix}`}</h1>
                <p className={classes.welcomeText}>
                  This is your new home for portfolio analytics.
                  {/* Dive in now or read our */}
                  {/* {' '} */}
                  {/* <a href={'https://upstart.com'} style={{ color: '#006D69' }}>Getting Started Guide</a> */}
                  {/* {' '} */}
                  {/* to get the */}
                  {/* most of your data. */}
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                setCookie('showBanner', false);
                setShowBanner(false);
              }}
              className={classes.welcomeButton}
              type={'button'}
            >
              &#x2715;
            </button>
          </div>
        )}
        <PortfolioAnalyticsDashboard
          headerText={''}
          reportPath={'embed/dashboards/upstart_portfolio_analytics::upstart_portfolio_analytics_dashboard'}
          showDivider={false}
          showDateSelector={false}
        />
      </Grid>
    </Grid>
  );
};

export default PortfolioAnalytics;
