import Grid from '@material-ui/core/Grid';
import AnalyticsDashboard from 'components/AnalyticsDashboard';
import RequireExternalInvestor from 'components/RequireExternalInvestor';
import React from 'react';

// THis has to require investor
const InvestorMetrics = () => (
  <RequireExternalInvestor>
    <Grid item xs={12}>
      <AnalyticsDashboard
        reportPath={'embed/dashboards/lending-partnership-reporting::allocations_detail'}
        headerText="Summary"
        showDivider={false}
        subHeader={false}
      />
    </Grid>
  </RequireExternalInvestor>
);

export default InvestorMetrics;
