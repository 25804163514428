import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import useStyle from './style';

const SquareIconButton = ({ icon, ...rest }) => {
  const classes = useStyle();

  return (
    <IconButton className={classes.root} {...rest}>
      {icon}
    </IconButton>
  );
};

SquareIconButton.propTypes = {
  icon: PropTypes.node.isRequired,
};

export default SquareIconButton;
