import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import LandingPage from './LandingPage';
import SearchForm from './SearchForm';
import AdverseActionNoticeReasons from './AdverseActionNoticeReasonsPage';

import useStyle from './style';

const ApplicantSearch = () => {
  const LANDING_PAGE = 'landingPage';
  const SEARCH_FORM = 'searchForm';
  const AAN_REASON_PAGE = 'aanReasonPage';
  const classes = useStyle();
  const [search, setSearch] = useState({ upstartId: '', currentPage: LANDING_PAGE });
  const [pageTitle, setPageTitle] = useState('Applicant Search');
  const [aanReasons, setAanReasons] = useState([]);

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.sectionTitle}>{pageTitle}</Typography>
      </Grid>

      <Grid item xs={12}>
        { search.currentPage === LANDING_PAGE && (
          <LandingPage
            setUpstartId={e => setSearch({ ...search, upstartId: e.target.value })}
            nextPage={() => setSearch({ ...search, currentPage: SEARCH_FORM })}
          />
        )}
        { search.currentPage === SEARCH_FORM && (
          <SearchForm
            initialUpstartId={search.upstartId}
            nextPage={() => setSearch({ ...search, currentPage: AAN_REASON_PAGE })}
            pageTitle={title => setPageTitle(title)}
            aanReasons={reasons => setAanReasons(reasons)}
          />
        )}
        { search.currentPage === AAN_REASON_PAGE && (
          <AdverseActionNoticeReasons
            previousPage={() => setSearch({ upstartId: '', currentPage: LANDING_PAGE })}
            pageTitle={() => setPageTitle('Applicant Search')}
            resetReasons={() => setAanReasons([])}
            aanReasonList={aanReasons}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ApplicantSearch;
