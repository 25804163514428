import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: 'white',
    marginRight: theme.spacing(1),
    '& .MuiAutocomplete-inputRoot': {
      padding: '5px',
    },
  },
  paper: {
    borderRadius: '0px',
  },
  outlinedInput: {
    border: '1px solid #1F83DB',
  },
}));

export default useStyles;
