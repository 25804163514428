import getAppConfig from 'api/appConfig';
import { useProduct } from 'contexts/ProductContext';
import { useExternalInvestor } from 'contexts/ExternalInvestorContext';
import { useEffect, useState } from 'react';
import getEmbedUrl from 'api/embed';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import sharedStyles from 'theme/sharedStyles';
import { randomString } from 'utils/toolsService';
import { useView } from 'contexts/ViewContext';
import ReportDatesSelector from '../ReportDatesSelector';

const AnalyticsReport = ({ reportPath, showDateSelector, dateFilterField }) => {
  const classes = sharedStyles();
  const { productView, investorView } = useView();
  const { currentProductKey } = useProduct();
  const { currentInvestorName } = useExternalInvestor();
  const { data: appConfigData } = useAsync(getAppConfig);
  const [url, setUrl] = useState('');
  const [contentHeight, setContentHeight] = useState('600px');
  const [embedId] = useState(randomString());
  const embedDomain = `?embed_domain=${window.location.origin}`;

  useEffect(() => {
    const handleMessage = event => {
      if (event.source === document.getElementById(embedId)?.contentWindow) {
        if (event.origin === `https://${appConfigData?.data.analyticsRootUrl}`) {
          const data = JSON.parse(event.data);
          // eslint-disable-next-line default-case
          switch (data.type) {
            case 'page:properties:changed':
              setContentHeight(`${data.height}px`);
              break;
          }
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [appConfigData]);

  useEffect(() => {
    if (productView && currentProductKey) {
      getEmbedUrl({
        productKey: currentProductKey,
        embedPath: `${reportPath}${embedDomain}`,
        embedDomain,
        investorName: null,
      }).then(result => setUrl(result.data.url));
    } else if (investorView && currentInvestorName) {
      getEmbedUrl({
        productKey: null,
        embedPath: `${reportPath}${embedDomain}`,
        embedDomain,
        investorName: currentInvestorName,
      }).then(result => setUrl(result.data.url));
    }
  }, [productView, currentProductKey, investorView, currentInvestorName, reportPath]);

  const determineType = () => {
    if (reportPath.includes('dashboard')) {
      return 'dashboard';
    }
    if (reportPath.includes('look')) {
      return 'look';
    }
    return 'explore';
  };

  const runRequest = {
    type: `${determineType()}:run`,
  };

  const onDatesChange = filter => {
    // Build the request
    const type = determineType();
    const request = `{ type: '${type}:filters:update', filters: { ${dateFilterField}: '${filter}' } }`;
    const embed = document.getElementById(embedId);
    embed?.contentWindow?.postMessage(request, `https://${appConfigData?.data.analyticsRootUrl}`);
    embed?.contentWindow?.postMessage(
      JSON.stringify(runRequest), `https://${appConfigData?.data.analyticsRootUrl}`,
    );
  };

  // return the iframe component with the embed url as the src
  return (
    <>
      {showDateSelector && (<ReportDatesSelector defaultDates="1M" onDatesChange={onDatesChange} />)}
      <div className={classes.iframeReportWrapper}>
        <iframe
          id={embedId}
          className={classes.iframe}
          title="Look"
          src={url}
          height={contentHeight}
          width="100%"
        />
      </div>
    </>
  );
};

export default AnalyticsReport;

AnalyticsReport.propTypes = {
  reportPath: PropTypes.string.isRequired,
  showDateSelector: PropTypes.bool,
  dateFilterField: PropTypes.string,
};

AnalyticsReport.defaultProps = {
  showDateSelector: false,
  dateFilterField: 'Origination Date',
};
