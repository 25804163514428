import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import ForecastForm from '../../components/ForecastForm';
import { useForecast } from '../../contexts/ForecastContext';

const ForecastConfigurationsForm = () => {
  const { uuid } = useParams();
  const { loadForecast } = useForecast();

  useEffect(() => {
    if (uuid) {
      loadForecast(uuid);
    }
  }, [uuid]);

  return (
    <ForecastForm />
  );
};

export default ForecastConfigurationsForm;
