import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useProduct } from 'contexts/ProductContext';
import { getReportTypes, parseReportTypes } from 'api/report';
import useStyle from './style';

const ReportTypeSearch = ({ additionalOptions = [], onChange, version }) => {
  const classes = useStyle();
  const { currentProductKey } = useProduct();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const memoizedAdditionalOptions = useMemo(() => additionalOptions, []);
  const params = useMemo(() => ({
    productKey: currentProductKey,
    query: inputValue,
    watch: currentProductKey,
    version,
  }), [inputValue, currentProductKey]);
  const { data, isLoading, isFulfilled } = useAsync(getReportTypes, { ...params, watch: params });

  useEffect(() => {
    if (isFulfilled) {
      setOptions([
        ...memoizedAdditionalOptions,
        ...parseReportTypes(data).map(reportType => ({
          display: reportType.attributes.name,
          value: reportType.attributes.slug,
          query: { reportTypeSlug: reportType.attributes.slug },
        })),
      ]);
    }
  }, [data, isFulfilled, memoizedAdditionalOptions]);

  return (
    <Autocomplete
      id="report-type-search"
      className={classes.root}
      classes={{
        paper: classes.paper,
      }}
      options={options}
      getOptionLabel={option => option?.display || ''}
      getOptionSelected={(option, value) => option?.value === value?.value}
      onInputChange={(_, v, r) => {
        if (r === 'input') { setInputValue(v); }
      }}
      onChange={(event, value) => {
        event.persist();
        onChange(value);
      }}
      loading={isLoading}
      renderInput={p => (
        <TextField
          {...p}
          variant="outlined"
          placeholder="Select Report Type"
          InputProps={{
            ...p.InputProps,
            classes: {
              focused: classes.outlinedInput,
            },
            'data-testid': 'report-type-input',
          }}
        />
      )}
    />
  );
};

// Query params is an object of Date objects
ReportTypeSearch.propTypes = {
  additionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      value: PropTypes.string,
      query: PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.instanceOf(Date),
          PropTypes.number,
        ]),
      ),
    }),
  ),
  onChange: PropTypes.func.isRequired,
  version: PropTypes.string,
};

ReportTypeSearch.defaultProps = {
  additionalOptions: [],
  version: null,
};

export default ReportTypeSearch;
