import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { listReportTypes } from 'api/reportTypes';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Loader from 'components/Loader';
import ReportTypesCreate from 'components/ReportTypeCreate';
import React, { useEffect } from 'react';
import sharedStyles from 'theme/sharedStyles';

const ReportTypesEditor = () => {
  const classes = sharedStyles();
  const [showCreate, setShowCreate] = React.useState(false);
  const [editing, setEditing] = React.useState(null);
  const [data, setData] = React.useState(null);

  const getReportTypes = () => {
    listReportTypes().then(response => {
      setData(response?.data?.data);
    });
  };

  const afterReportSave = () => {
    setEditing(null);
    getReportTypes();
    setShowCreate(false);
  };

  useEffect(() => {
    getReportTypes();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} elevation={0}>
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <Button onClick={() => setShowCreate(!showCreate)} variant="contained">
                Add New
              </Button>
            </Grid>
            {showCreate && (
              <Grid item xs={12}>
                <ReportTypesCreate afterSave={() => afterReportSave()} />
              </Grid>
            )}
            <hr className={classes.horizontalRule} />
            {data ? (
              <TableContainer>
                <Table aria-label="report_types table" className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.headRow}>
                      <TableCell>Version</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Slug</TableCell>
                      <TableCell>Visibility</TableCell>
                      <TableCell>Deprecated</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.length > 0 && data.map(reportType => (
                      <>
                        <TableRow>
                          <TableCell>{`v${reportType.attributes.version}`}</TableCell>
                          <TableCell>{reportType.attributes.category}</TableCell>
                          <TableCell>{reportType.attributes.name}</TableCell>
                          <TableCell>{reportType.attributes.slug}</TableCell>
                          <TableCell>{reportType.attributes.visibility}</TableCell>
                          <TableCell>{reportType.attributes.deprecated.toString()}</TableCell>
                          <TableCell>
                            <Button variant="contained" onClick={() => setEditing(editing ? null : reportType)}>
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                        {editing && editing === reportType && (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <ReportTypesCreate
                                afterSave={() => afterReportSave()}
                                reportType={reportType}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : <Loader data-testid="loader" />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ReportTypesEditor;
