import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useInstitution } from 'contexts/InstitutionContext';

const InstitutionDropdown = () => {
  const {
    institutions,
    currentInstitution,
    setCurrentInstitution,
  } = useInstitution();
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInstitutionSelection = institution => {
    setCurrentInstitution(institution);
    handleClose();
  };

  if (institutions) {
    return (
      <React.Fragment>
        <Button
          aria-controls="institution-dropdown"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          size="large"
          disabled={!currentInstitution}
        >
          {currentInstitution ? currentInstitution.attributes.name : '—'}
        </Button>
        <Menu
          id="institution-dropdown"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div>
            {institutions.map(institution => (
              <MenuItem
                key={institution.id}
                onClick={() => handleInstitutionSelection(institution)}
              >
                <Typography noWrap>
                  {institution.attributes.name}
                </Typography>
              </MenuItem>
            ))}
          </div>
        </Menu>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default InstitutionDropdown;
