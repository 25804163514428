import { Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useAnalytics } from 'contexts/AnalyticsContext';
import React, { useEffect, useState } from 'react';
import { getReportingApiStatusColor, getUpstartStatusColor } from '../../api/uptimeStatus';
import useStyle from './style';

const UptimeStatus = () => {
  const classes = useStyle();
  const uptimeStatusLink = 'https://status.upstart.com/uptime/pngjlvydj2fb?page=1';
  const supportEmail = 'mailto:LenderSupport@upstart.com';
  const { analyticsEnabled } = useAnalytics();
  const [reportingApiColor, setReportingApiColor] = useState({ color: 'default' });
  const [upstartColor, setUpstartColor] = useState({ color: 'default' });
  const [error, setError] = useState('');

  /*
   * Method is needed as original instructions for project is snot encompassing of the sliding scale
   * of colors that Uptime Status page uses
   */
  /* eslint-disable no-bitwise */
  const hexToRGB = hexValue => {
    const numericValue = parseInt(hexValue.replace(/^#/, ''), 16);
    const red = (numericValue >> 16) & 0xFF;
    const green = (numericValue >> 8) & 0xFF;
    const blue = numericValue & 0xFF;
    return { r: red, g: green, b: blue };
  };
  /* eslint-disable no-bitwise */

  useEffect(() => {
    getReportingApiStatusColor()
      .then(response => {
        if (response.data !== null) {
          setReportingApiColor(response.data);
        }
      })
      .catch(response => setError(JSON.stringify(response.message)));
    getUpstartStatusColor()
      .then(response => {
        if (response.data !== null) {
          setUpstartColor(response.data);
        }
      })
      .catch(response => setError(JSON.stringify(response.message)));
  }, []);

  const renderStatus = (color1, color2, errorMessage) => {
    const rgb1 = hexToRGB(color1);
    const rgb2 = hexToRGB(color2);
    switch (true) {
      case (errorMessage !== ''):
        return <div className={classes.circleDefault} />;
      case (rgb1.r - rgb1.g > rgb2.r - rgb2.g):
        return <div className={classes.circle} style={{ backgroundColor: color1 }} />;
      case (rgb2.r - rgb2.g > rgb1.r - rgb1.g):
        return <div className={classes.circle} style={{ backgroundColor: color2 }} />;
      case (rgb1.r - rgb1.g === rgb2.r - rgb2.g):
        return <div className={classes.circle} style={{ backgroundColor: color1 }} />;
      default:
        return <div className={classes.circleDefault} />;
    }
  };

  return (
    <Grid item xs={6} className={classes.wrapper}>
      { analyticsEnabled && (
        <div className={classes.verticalLineWrapper}>
          <div className={classes.verticalLine} />
        </div>
      )}
      <div className={classes.circleWrapper}>
        {upstartColor && reportingApiColor &&
          renderStatus(upstartColor.color, reportingApiColor.color, error)}
      </div>
      <div className={classes.statusWrapper}>
        <Link
          underline="always"
          href={uptimeStatusLink}
          className={classes.statuslink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Upstart Uptime Status
        </Link>
      </div>
      <div className={classes.verticalLineWrapper}>
        <div className={classes.verticalLine} />
      </div>
      <div className={classes.emailWrapper}>
        <Link
          underline="always"
          href={supportEmail}
          className={classes.emailLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          LenderSupport@upstart.com
        </Link>
      </div>
    </Grid>
  );
};

export default UptimeStatus;
