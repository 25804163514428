/* eslint-disable react/no-this-in-sfc */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useAsync } from 'react-async';
import { parseISO, add, startOfMonth } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'components/Loader';
import { useProduct } from 'contexts/ProductContext';
import { getMonthlyWeightedAverageApr } from 'api/performanceMetric';
import abbreviateNumber from 'utils/abbreviateNumber';
import LastUpdatedNotifier from 'components/LastUpdatedNotifier';
import RangeButton from 'components/RangeButton';
import theme from 'theme';
import {
  getMonthlySeriesDefault,
  populateMonthlySeries,
  selectAttribute,
  selectMonthlyDateLabel,
  MONTHS_IN_YEAR,
} from 'utils/monthlySeries';
import useLegendStyles from '../../theme/legendStyle';

const { tangerine } = theme.palette;
const { tooltip, legend, chart } = theme.chartStyle;

const YEAR_OPTIONS = [1, 3, 5];
const MAX_MONTHS = Math.max(...YEAR_OPTIONS) * MONTHS_IN_YEAR;
const WAVG_APR = 'wavgApr';

const MonthlyWeightedAverageApr = () => {
  const classes = useLegendStyles({ color: tangerine.main });

  const [year, setYear] = useState(1);
  const [series, setSeries] = useState([]);
  const { currentProductKey } = useProduct();

  // default series to length of MAX_MONTHS with 0.00% weighted average apr
  const startingDate = startOfMonth(add(Date.now(), { months: -MAX_MONTHS + 1 }));
  const seriesDefault = useMemo(
    () => getMonthlySeriesDefault(startingDate, MAX_MONTHS, {
      [WAVG_APR]: 0,
    }), [MAX_MONTHS],
  );

  // populate series from api
  const { data, isLoading, isFulfilled } = useAsync(getMonthlyWeightedAverageApr, {
    from: startingDate,
    productKey: currentProductKey,
    watch: currentProductKey,
  });

  const lastUpdatedDate = parseISO(data?.data?.meta?.lastUpdatedDate);
  const monthlyAverages = data?.data?.data || [];
  useEffect(() => {
    setSeries(
      populateMonthlySeries(seriesDefault, monthlyAverages),
    );
  }, [isFulfilled]);

  // create highchart data/labels for series[YEAR_OPTIONS[0]]... series[YEAR_OPTIONS[n]]
  const wavgApr = useMemo(() => series.map(selectAttribute(WAVG_APR)), [series]);
  const monthlyLabels = useMemo(
    () => series.map(selectMonthlyDateLabel(lastUpdatedDate)),
    [series],
  );

  const getSeriesForYear = useCallback(
    (totalSeries, yr) => totalSeries.slice(-MONTHS_IN_YEAR * yr),
    [],
  );

  const highchartSeries = [
    {
      data: getSeriesForYear(wavgApr, year),
      name: 'Weighted average APR',
      pointPlacement: 'on',
      lineWidth: 1,
      lineColor: tangerine.main,
      style: { marginRight: 24 },
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, Highcharts.color(tangerine.main).setOpacity(0.4).get('rgba')],
          [1, Highcharts.color(tangerine.main).setOpacity(0.0).get('rgba')],
        ],
      },
      marker: {
        enabled: false,
        fillColor: tangerine.main,
        lineColor: tangerine.main,
      },
    },
  ];

  const options = {
    series: highchartSeries,
    legend: {
      ...legend,
      className: classes.chartLegend,
      itemStyle: {
        ...legend.itemStyle,
        color: tangerine.main,
      },
    },
    offset: -15,
    title: {
      text: '',
    },
    chart: {
      style: {
        ...chart.style,
      },
      marginRight: 72,
      type: 'area',
    },
    xAxis: [{
      offset: 3,
      tickAmount: 12,
      categories: getSeriesForYear(monthlyLabels, year),
      crosshair: true,
      labels: {
        step: Math.floor(year * 2 - 1),
        y: 32,
        style: { fontSize: 14 },
        styledMode: true,
        format: '{value}',
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          const label = this.axis.defaultLabelFormatter.call(this);
          return [label.split(/\s/)].map(x => `${x[0].slice(0, 3)} ${x[1]}`)[0].replace(/\s/, '<br>');
        },
      },
    }],
    yAxis: {
      offset: 2,
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        formatter() {
          const label = this.axis.defaultLabelFormatter.call(this);
          return `${abbreviateNumber(label)}.00%`;
        },
        style: {
          color: '#757575',
          fontSize: 12,
          backgroundColor: 'white',
        },
        step: 2,
      },
      title: {
        text: 'APR',
        style: {
          color: '#757575',
          fontSize: 14,
        },
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      ...tooltip,
      headerFormat: '',
      pointFormatter() {
        return `
          <strong>${getSeriesForYear(monthlyLabels, year)[this.x].replace('<br/>', ' ')}</strong><br>
          <p>${this.y}% WAVG APR</p><br />
        `;
      },
    },
  };

  const Chart = () => (
    <>
      <nav style={{ display: 'flex', margin: '-9px 0 36px 0' }}>
        { YEAR_OPTIONS.map(num => (<RangeButton {...{ year, setYear, value: num, key: num }} />)) }
      </nav>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <LastUpdatedNotifier date={lastUpdatedDate} />
    </>
  );

  return (
    <>
      {
        isLoading ?
          <Loader data-testid="loader" /> :
          <Chart />
      }
    </>
  );
};

export default MonthlyWeightedAverageApr;
