import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  icon: {
    display: 'block',
    marginLeft: 'auto',
  },
}));

export default useStyles;
