import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AuthProvider as OidcAuthProvider } from 'react-oidc-context';
import { useHistory, useLocation } from 'react-router';
import { useAppConfig } from 'components/App';
import storageFlag from 'utils/storageFlag';
import AuthContextAdapter, { AuthContext } from './adapter';

export const useAuth = () => ({ ...useContext(AuthContext) });

const isKcEnabled = (location, appConfig) => {
  const kcEnabled = storageFlag.upsertFromSearch(location, 'kcEnabled');
  if (kcEnabled === undefined) return appConfig.keycloakEnabled;

  return kcEnabled;
};

const locationWithoutAuth = location => {
  const parsed = new URLSearchParams(location.search);
  parsed.delete('code');
  parsed.delete('error');
  parsed.delete('state');
  parsed.delete('session_state');
  parsed.delete('iss');
  return { ...location, search: parsed.toString() };
};

export const AuthContextProvider = ({ children }) => {
  const { appConfig } = useAppConfig();
  const history = useHistory();
  const location = useLocation();

  const oidcConfig = useMemo(() => {
    if (!appConfig || !location) {
      return undefined;
    }

    const baseConfig = {
      redirect_uri: window.location.origin,
      onSigninCallback: () => {
        history.replace(locationWithoutAuth(location));
      },
    };
    if (isKcEnabled(location, appConfig)) {
      return {
        ...baseConfig,
        authority: appConfig.keycloakAuthority,
        client_id: appConfig.keycloakClientId || 'upc-ui',
        extraQueryParams: {
          kc_idp_hint: appConfig.keycloak_idp_hint || 'okta-saml-lp',
        },
      };
    }

    return {
      ...baseConfig,
      authority: appConfig.oktaAuthority,
      client_id: appConfig.oktaClientId,
    };
  }, [appConfig, location]);

  return (
    <>
      {oidcConfig && (
      <OidcAuthProvider {...oidcConfig}>
        <AuthContextAdapter>
          {children}
        </AuthContextAdapter>
      </OidcAuthProvider>
      )}
    </>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AuthContextProvider.defaultProps = {
  children: [],
};

export default AuthContextProvider;
