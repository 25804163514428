import React, { useState } from 'react';
import { useAsync } from 'react-async';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FundUsage from 'components/FundUsage';
import TimePeriodSelector, { selectorOptions } from 'components/TimePeriodSelector';
import Loader from 'components/Loader';
import { useProduct } from 'contexts/ProductContext';
import { getFundUsages } from 'api/performanceMetric';
import useStyle from './style';

const UseOfFunds = () => {
  const classes = useStyle();
  const { currentProductKey } = useProduct();
  const [showAll, setShowAll] = useState(false);
  const [timePeriod, setTimePeriod] = useState(selectorOptions.YESTERDAY.value());
  const { data, isLoading, isFulfilled } = useAsync(getFundUsages, {
    productKey: currentProductKey,
    from: timePeriod.fromDate,
    to: timePeriod.toDate,
    watchFn: (params, prevParams) => (
      params.from !== prevParams.from ||
      params.to !== prevParams.to ||
      params.productKey !== prevParams.productKey
    ),
  });
  const fundUsages = (isFulfilled && data.data.data) || [];
  const total = (isFulfilled && data.data.meta.total) || 0;
  const renderShowLimit = 4;

  const handleTimePeriodChange = ({ value }) => {
    setTimePeriod(value);
    setShowAll(false);
  };

  return (
    <div>
      {isLoading ? <Loader data-testid="loader" /> : (
        <>
          {fundUsages.length === 0 && (
            <Typography align="center" component="p">No Data Available</Typography>
          )}
          {fundUsages.slice(0, showAll ? undefined : renderShowLimit).map(fundUsage => (
            <FundUsage
              key={fundUsage.id}
              name={fundUsage.attributes.reason}
              percentage={Math.round((fundUsage.attributes.numberOfLoans / total) * 100)}
            />
          ))}
          {!showAll && fundUsages.length > renderShowLimit && (
            <Button color="primary" onClick={() => setShowAll(true)}>
              {`+${fundUsages.length - renderShowLimit} more`}
            </Button>
          )}
        </>
      )}
      <TimePeriodSelector
        className={classes.timePeriodSelector}
        initialTimePeriod={selectorOptions.YESTERDAY}
        onChange={handleTimePeriodChange}
      />
    </div>
  );
};

export default UseOfFunds;
