import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { updateForecastConfiguration } from 'api/forecastConfiguration';
import Typography from '@material-ui/core/Typography';

const ForecastRenameModal = ({ isOpen, onClose, forecastName, forecastUuid }) => {
  const [newForecastName, setNewForecastName] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleInputChange = event => {
    setNewForecastName(event.target.value);
  };

  const handleRename = async () => {
    try {
      await updateForecastConfiguration(forecastUuid, { forecastName: newForecastName });
      setErrorMessage(null);
      onClose();
    } catch (err) {
      setErrorMessage('Name already taken. Please give it a different name.');
    }
  };

  useEffect(() => {
    setNewForecastName(forecastName);
  }, [forecastName]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="forecast-rename-modal"
    >
      <div style={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', boxShadow: 24, p: 4, backgroundColor: '#FFFFFF', padding: '10px',
      }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <h1 id="forecast-rename-modal">Rename forecast</h1>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <TextField
          label="Forecast name"
          variant="outlined"
          style={{ outlineColor: '#00807B' }}
          fullWidth
          defaultValue={forecastName}
          onChange={handleInputChange}
          autoFocus
        />
        {errorMessage && (
          <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
            {errorMessage}
          </Typography>
        )}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <hr />
          <br />
          <Button variant="contained" color="primary" style={{ width: '100%', backgroundColor: '#00807B' }} onClick={handleRename}>Continue</Button>
        </div>
      </div>
    </Modal>
  );
};

ForecastRenameModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  forecastName: PropTypes.string.isRequired,
  forecastUuid: PropTypes.string.isRequired,
};

export default ForecastRenameModal;
