import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatISO, startOfYesterday, endOfYesterday, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTimePeriodContext } from 'contexts/TimePeriodContext';
import ButtonDropDown from 'components/ButtonDropDown';

const getDate = date => formatISO(date, { representation: 'date' });

export const getFromToDates = (month, year) => {
  const isYearly = month === 'ALL';
  const date = isYearly ? new Date(`Jan 1 ${year}`) : new Date(`${month} 1 ${year}`);
  const fromDate = isYearly ? startOfYear(date) : startOfMonth(date);
  const toDate = isYearly ? endOfYear(date) : endOfMonth(date);
  return {
    fromDate: getDate(fromDate),
    toDate: getDate(toDate),
  };
};

export const selectorOptions = {
  YESTERDAY: {
    name: 'YESTERDAY',
    value: () => ({ fromDate: getDate(startOfYesterday()), toDate: getDate(endOfYesterday()) }),
  },
  MONTH_YEAR_SELECTION: {
    name: 'MONTH_YEAR_SELECTION',
    value: (year, month) => getFromToDates(month, year),
  },
};

const TimePeriodSelector = ({ initialTimePeriod, onChange, vertical, horizontal, ...rest }) => {
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(initialTimePeriod.name);
  useEffect(() => {
    onChange({ value: selectorOptions[selectedTimePeriod].value(selectedYear, selectedMonth) });
  }, [selectedTimePeriod, selectedYear, selectedMonth]);

  const { yearMonthMap, isLoading } = useTimePeriodContext();

  useEffect(() => setYears(Object.keys(yearMonthMap).reverse()), [yearMonthMap]);
  useEffect(() => setMonths(yearMonthMap[selectedYear] || []), [yearMonthMap, selectedYear]);
  useEffect(() => setSelectedYear(years[0]), [years.length]);
  useEffect(() => setSelectedMonth(months[0]), [selectedYear, months.length]);

  return (
    <Grid container spacing={1} {...rest}>
      <Grid
        item
        xs={horizontal ? 4 : 12}
        lg={horizontal ? 4 : 12}
        xl={vertical ? 12 : 4}
      >
        <Button
          size="small"
          variant={selectedTimePeriod === selectorOptions.YESTERDAY.name ? 'contained' : 'text'}
          color={selectedTimePeriod === selectorOptions.YESTERDAY.name ? 'primary' : 'default'}
          onClick={() => setSelectedTimePeriod(selectorOptions.YESTERDAY.name)}
          fullWidth
        >
          {selectorOptions.YESTERDAY.name}
        </Button>
      </Grid>
      <ButtonDropDown
        options={months}
        selectedOption={selectedMonth}
        onChange={setSelectedMonth}
        onMenuOpen={() => setSelectedTimePeriod(selectorOptions.MONTH_YEAR_SELECTION.name)}
        isMenuOpen={selectedTimePeriod === selectorOptions.MONTH_YEAR_SELECTION.name}
        {...{ vertical, horizontal, isLoading }}
      />
      <ButtonDropDown
        options={years}
        selectedOption={selectedYear}
        onChange={setSelectedYear}
        onMenuOpen={() => setSelectedTimePeriod(selectorOptions.MONTH_YEAR_SELECTION.name)}
        isMenuOpen={selectedTimePeriod === selectorOptions.MONTH_YEAR_SELECTION.name}
        {...{ vertical, horizontal, isLoading }}
      />
    </Grid>
  );
};

TimePeriodSelector.propTypes = {
  initialTimePeriod: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.func,
  }),
  vertical: PropTypes.bool,
  horizontal: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

TimePeriodSelector.defaultProps = {
  initialTimePeriod: selectorOptions.YESTERDAY,
  vertical: false,
  horizontal: false,
};

export default TimePeriodSelector;
