import apiClient from 'api/apiClient';

export const getExplorableReports = () => (
  apiClient.get(`/v1/explorable_report`)
);

export const createExplorableReport = data => (
  apiClient.post('/v1/explorable_report/create', { explorable_report: data })
);

export const editExplorableReport = data => (
  apiClient.put(`/v1/explorable_report/edit`, { explorable_report: data })
);
