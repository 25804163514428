import React from 'react';
import { Link } from 'react-router-dom';
import { useAsync } from 'react-async';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import CardContent from 'components/CardContent';
import Loader from 'components/Loader';
import ReportSnapshot from 'components/ReportSnapshot';
import { ReportContextProvider } from 'contexts/ReportContext';
import { useProduct } from 'contexts/ProductContext';
import { getReports, parseReports } from 'api/report';
import DownloadFileForm from '../DownloadFileForm';

const RecentReports = () => {
  const { currentProductKey } = useProduct();
  const { data, isPending, isFulfilled } = useAsync(getReports, {
    productKey: currentProductKey,
    limit: 4,
    watch: currentProductKey,
  });
  const reports = isFulfilled ? parseReports(data) : [];

  return (
    <ReportContextProvider>
      <Card>
        <CardHeader title="Reports" subheader={<Link to="/reports">See All</Link>} />
        <DownloadFileForm />
        <CardContent>
          {isPending && <Loader data-testid="loader" />}
          {!isPending && reports.length === 0 && <Typography component="p" align="center">No Reports</Typography>}
          {!isPending && reports.length > 0 && (
            <Box px={3} py={2}>
              <Grid container spacing={3}>
                {reports.map(report => (
                  <ReportSnapshot
                    key={report.id}
                    id={report.id}
                    name={report.attributes.displayName}
                    uploadDate={report.attributes.reportDate}
                  />
                ))}
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </ReportContextProvider>
  );
};

export default RecentReports;
