import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { useExternalInvestor } from 'contexts/ExternalInvestorContext';
import Loader from 'components/Loader';

const RequireExternalInvestor = ({ children }) => {
  const {
    externalInvestorLoading,
    currentExternalInvestor,
    externalInvestorCallCounter,
  } = useExternalInvestor();
  return (
    <React.Fragment>
      {externalInvestorLoading && (
        <Grid item xs={12}>
          <Loader />
        </Grid>
      )}
      {!externalInvestorLoading && !currentExternalInvestor && externalInvestorCallCounter > 0 && (
        <Grid item xs={12}>
          <Alert severity="warning">{`You don't have access to any investor data or need to select an investor.`}</Alert>
        </Grid>
      )}
      {!externalInvestorLoading && currentExternalInvestor && children}
    </React.Fragment>
  );
};

RequireExternalInvestor.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RequireExternalInvestor;
