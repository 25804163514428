import React, { useMemo } from 'react';
import { format, formatISO, startOfYesterday, startOfMonth } from 'date-fns';
import { useReport } from 'contexts/ReportContext';
import ReportTypeSearch from 'components/ReportTypeSearch';

const ReportTableTypeSearch = () => {
  const { updateReportParams } = useReport();
  const defaultQuery = {
    createdAfter: null,
    reportTypeSlug: null,
  };
  const additionalOptions = useMemo(() => ([
    { display: 'All Reports', value: 'all_reports', query: {} },
    {
      display: "Yesterday's Reports",
      value: 'yesterday_reports',
      query: { createdAfter: formatISO(startOfYesterday()) },
    },
    {
      display: format(new Date(), "MMMM 'Reports'"),
      value: 'current_month_reports',
      query: { createdAfter: formatISO(startOfMonth(new Date())) },
    },
  ]), []);

  const onChange = v => {
    const newQuery = v?.query || { reportTypeSlug: null, createdAfter: null };
    updateReportParams({
      ...defaultQuery,
      ...newQuery,
    });
  };

  return (
    <ReportTypeSearch onChange={onChange} additionalOptions={additionalOptions} version="1" />
  );
};

export default ReportTableTypeSearch;
