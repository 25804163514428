import { Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { createReportType, editReportType } from 'api/reportTypes';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './style';

const ReportTypesCreate = props => {
  const classes = useStyles();
  const [name, setName] = React.useState(props.reportType?.attributes?.name || '');
  const [slug, setSlug] = React.useState(props.reportType?.attributes?.slug || '');
  const [category, setCategory] = React.useState(props.reportType?.attributes?.category || 'Applications');
  const [visibility, setVisibility] = React.useState(props.reportType?.attributes?.visibility || 'visible');
  const [version, setVersion] = React.useState(props.reportType?.attributes?.version || 1);
  const [infoPopup, setInfoPopup] = React.useState(props.reportType?.attributes?.infoPopup || '');
  const [error, setError] = React.useState('');

  const saveReportType = () => {
    createReportType({
      name,
      slug,
      category,
      visibility,
      version,
      infoPopup,
    }).then(() => {
      props.afterSave();
    }).catch(response => setError(JSON.stringify(response.message)));
  };

  const doEditReportType = (deprecated = false) => {
    editReportType({
      id: props.reportType.id,
      name,
      slug,
      category,
      visibility,
      version,
      infoPopup,
      deprecated,
    }).then(() => {
      props.afterSave();
    }).catch(response => setError(JSON.stringify(response.message)));
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} xs={12} className={classes.container}>
          <Grid item xs={3}>
            <Select onChange={e => setCategory(e.target.value)} value={category} label="Category">
              <MenuItem value="Applications">Applications</MenuItem>
              <MenuItem value="Loans">Loans</MenuItem>
              <MenuItem value="Program">Program</MenuItem>
              <MenuItem value="Finance">Finance</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={5}>
            <TextField
              className={classes.textField}
              disabled={!!props.reportType}
              onChange={e => setName(e.target.value)}
              value={name}
              label="Name"
            />
          </Grid>
          <Grid item xs={2}>
            <Select onChange={e => setVersion(e.target.value)} value={version} label="Version">
              <MenuItem value="1">v1</MenuItem>
              <MenuItem value="2">v2</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.reportType ? () => doEditReportType() : () => saveReportType()}
            >
              {props.reportType ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12} className={classes.container}>
          <Grid item xs={3}>
            <Select onChange={e => setVisibility(e.target.value)} value={visibility} label="Visibility">
              <MenuItem value="hidden">Hidden</MenuItem>
              <MenuItem value="visible">Visible</MenuItem>
              <MenuItem value="visible_to_non_servicing">Visible To Non-Servicing</MenuItem>
              <MenuItem value="visible_to_servicing">Visible To Servicing</MenuItem>
              <MenuItem value="visible_to_admin">Visible To Admin</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={5}>
            <TextField
              className={classes.textField}
              disabled={!!props.reportType}
              onChange={e => setSlug(e.target.value)}
              value={slug}
              label="Slug"
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            {props.reportType && (
              <Button variant="contained" color="secondary" onClick={() => doEditReportType(true)}>
                Deprecate
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12} className={classes.container}>
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              onChange={e => setInfoPopup(e.target.value)}
              value={infoPopup}
              label="Field Info Description"
              multiline
              maxRows={4}
            />
          </Grid>
          <Grid item xs={4} />
        </Grid>
        {error !== '' && (
          <Grid item xs={12}>
            {error}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportTypesCreate;

ReportTypesCreate.propTypes = {
  afterSave: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  reportType: PropTypes.any,
};

ReportTypesCreate.defaultProps = {
  afterSave: () => {},
  reportType: null,
};
