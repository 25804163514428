import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;
const useStyles = makeStyles(theme => ({
  sideNavContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    background: 'linear-gradient(180deg, rgba(254, 254, 254, 0.65) 0%, #FFFFFF 100%)',
    borderRight: `1px solid #E7E7E7`,
  },
  listMenu: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  listItem: {
    marginBottom: theme.spacing(2),
    height: '48px',
  },
  listItemIcon: {
    minWidth: '40px',
  },
  listItemText: {
    '& .MuiTypography-root': {
      fontSize: '16px',
      fontWeight: '500',
      whiteSpace: 'normal',
      lineHeight: '1.2',
    },
  },
  selected: {
    backgroundColor: '#EDF5FC',
    borderRadius: '4px',
    '& .MuiListItemIcon-root': {
      color: '#222929',
    },
    '& .MuiListItemText-root': {
      '& span': {
        color: '#222929',
        fontWeight: '500',
      },
    },
  },
  upstartLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    paddingBottom: theme.spacing(3),
  },
  upstartLogoImage: {
    maxWidth: '150px',
  },
  drawerRoot: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  datePicker: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
  },
}));

export default useStyles;
