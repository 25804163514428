import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#1951A7',
      subdued: '#F3F6FB',
    },
    highlight: {
      main: '#3678DC',
    },
    sky: {
      main: '#3678DC80',
    },
    shade: {
      main: '#0C3471',
    },
    teal: {
      main: '#00B1AC',
    },
    lightTeal: {
      main: '#80D8D5',
    },
    green: {
      main: '#00B1AC',
    },
    turquoise: {
      main: '#0091BF',
    },
    tangerine: {
      main: '#FFA027',
    },
    secondary: {
      main: '#222929',
    },
    line: {
      main: '#E0E0E0',
    },
    neutral: {
      text: '#6E6E6E',
      borderLight: '#E7E7E7',
      background: '#F9F9F9',
    },
    red: {
      main: '#B00020',
      subdued: '#FFE8E6',
    },
    darkGreen: {
      main: '#008000',
      subdued: '#CDFDCD',
    },
    yellow: {
      main: '#e8c413',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    secondary: {
      fontFamily: 'Open Sans',
    },
  },
  chartStyle: {
    chart: {
      style: {
        fontSize: 14,
        fontFamily: 'Open Sans',
      },
    },
    tooltip: {
      valueSuffix: '',
      shared: true,
      headerFormat: '<strong>{point.key}</strong><br>',
      backgroundColor: 'rgba(34, 41, 41, 0.7)',
      borderColor: 'rgba(34, 41, 41, 0.1)',
      borderRadius: 24,
      borderWidth: 1,
      lineWidth: 0,
      followPointer: true,
      shadow: false,
      padding: 16,
      style: {
        color: 'white',
        fontSize: 16,
        lineHeight: 24,
        weight: 300,
        padding: '16px',
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      y: -5,
      itemStyle: {
        fontSize: 14,
        fontFamily: 'Montserrat',
      },
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '4px',
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none',
      },
    },
    MuiTableCell: {
      root: {
        padding: '10px',
        fontWeight: 500,
      },
    },
    MuiDialog: {
      paper: {
        background: '#FFFFFF',
        borderRadius: '4px',
      },
    },
    MuiDialogTitle: {
      root: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '48px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiDialogContentText: {
      root: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
      },
    },
    MuiTypography: {
      root: {
        fontWeight: 500,
      },
      body1: {
        fontWeight: 500,
      },
    },
    MuiToggleButton: {
      root: {
        textTransform: 'none',
        color: 'inherit',
        '&$selected': {
          borderColor: '#1952A7 !important',
          backgroundColor: '#F3F6FB',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '600',
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '20px',
        fontWeight: '600',
      },
    },
    MuiTabs: {
      root: {
        borderWidth: 0,
      },
      scroller: {
        borderWidth: '0px 0px 1px 0px',
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
      },
      indicator: {
        backgroundColor: '#1952A7',
        height: '4px',
      },
    },
    MuiTabPanel: {
      root: {
        padding: '0px',
      },
    },
  },
});
