import React from 'react';

const CircleChevron = (color = '#000000', direction = 'right') => {
  const rotation = () => {
    switch (direction) {
      case 'left':
        return 180;
      case 'up':
        return 90;
      case 'down':
        return 270;
      default:
        return 0;
    }
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="1.5" />
      <path d="M13.5 8L9.5 12L13.5 16" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" transform={`rotate(${rotation(direction)}, 12, 12)`} />
    </svg>
  );
};

export default CircleChevron;
