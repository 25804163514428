import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { Button, IconButton, TextField, InputAdornment } from '@material-ui/core';
import { checkLoanPacket, downloadLoanPacket } from 'api/applicantSearch';
import { useProduct } from 'contexts/ProductContext';
import streamZipDownload from '../../../utils/streamZipDownload';
import ApplicantSearchCard from '../ApplicantSearchCard';
import ReviewDoc from '../icons/ReviewDoc';

const LandingPage = ({ setUpstartId, nextPage }) => {
  const { currentProductKey } = useProduct();
  const [loanId, setLoanId] = useState('');
  const [loanPacketStatus, setLoanPacketStatus] = useState('initial');

  useEffect(() => {
    setLoanPacketStatus('initial');
  }, [loanId]);

  async function handleCheckLoanPacket() {
    let status;
    try {
      status = await checkLoanPacket(currentProductKey, loanId.toLowerCase());
      status = status.data.packetFound ? 'packetFound' : 'notFound';
    } catch (error) {
      status = 'error';
    }
    setLoanPacketStatus(status);
  }

  async function handleDownloadLoanPacket() {
    try {
      const response = await downloadLoanPacket(currentProductKey, loanId.toLowerCase());
      streamZipDownload(response.data, `${loanId.toLowerCase()}.zip`);
    } catch (error) {
      setLoanPacketStatus('error');
    }
  }

  return (
    <ApplicantSearchCard>
      <ReviewDoc />
      <h2>
        Enter an Upstart Id to find applicant records
      </h2>
      <TextField
        data-testid="search-input"
        label={'Upstart Id'}
        onChange={setUpstartId}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton data-testid="search-button" onClick={nextPage}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div style={{ display: 'none' }}>
        <h2>
          Enter a Loan Id to retrieve origination documents
        </h2>
        <TextField
          data-testid="loan-id-input"
          label="Loan Id"
          onChange={e => setLoanId(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton data-testid="loan-id-button" onClick={() => handleCheckLoanPacket()}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoanPacketResults
          loanPacketStatus={loanPacketStatus}
          handleDownloadLoanPacket={() => handleDownloadLoanPacket()}
        />
      </div>
    </ApplicantSearchCard>
  );
};

const LoanPacketResults = ({ loanPacketStatus, handleDownloadLoanPacket }) => (
  <>
    {loanPacketStatus === 'packetFound' && (
      <Button
        onClick={() => handleDownloadLoanPacket()}
        color="primary"
        variant="outlined"
      >
        Download Loan Packet
      </Button>
    )}
    {loanPacketStatus === 'error' && (
      <div>There was an error in processing your request</div>
    )}
    {loanPacketStatus === 'notFound' && (
      <div>
        {`No loan packet exists for this Loan Id`}
      </div>
    )}
  </>
);

LoanPacketResults.propTypes = {
  loanPacketStatus: PropTypes.string.isRequired,
  handleDownloadLoanPacket: PropTypes.func.isRequired,
};

LandingPage.propTypes = {
  setUpstartId: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
};

export default LandingPage;
