import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

const ConfirmModal = ({ isOpen, onConfirm, onExit, title, description, agree = 'Yes', disagree = 'No' }) => (
  <React.Fragment>
    <Dialog
      open={isOpen}
      onClose={onExit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onExit}>{disagree}</Button>
        <Button onClick={onConfirm} autoFocus>{agree}</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
);

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  agree: PropTypes.string,
  disagree: PropTypes.string,
};

ConfirmModal.defaultProps = {
  agree: 'Yes',
  disagree: 'No',
};

export default ConfirmModal;
