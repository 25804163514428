import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useProduct } from 'contexts/ProductContext';

const ProductDropdown = () => {
  const { products, currentProduct, setCurrentProduct, hasMultipleBankAccess } = useProduct();
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProductSelection = product => {
    setCurrentProduct(product);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        aria-controls="product-dropdown"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        size="large"
        disabled={!currentProduct}
      >
        {currentProduct ? currentProduct.attributes.name : '—'}
      </Button>
      <Menu
        id="product-dropdown"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          {products.map(product => (
            <MenuItem key={product.id} onClick={() => handleProductSelection(product)}>
              <Typography noWrap>
                {hasMultipleBankAccess ? `${product.attributes.issuingBankName}: ${product.attributes.name}` : product.attributes.name}
              </Typography>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </React.Fragment>
  );
};

export default ProductDropdown;
