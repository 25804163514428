import { makeStyles } from '@material-ui/core/styles';

const height = 200;
const width = 200;

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
  innerGauge: ({ color, clipPath }) => ({
    position: 'absolute',
    zIndex: '1',
    height,
    width,
    border: `${color} solid 16px`,
    borderRadius: '100%',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
    clipPath,
  }),
  outerGauge: {
    position: 'absolute',
    height,
    width,
    border: '#E7E7E7 solid 16px',
    borderRadius: '100%',
    clipPath: 'polygon(100% 50%, 100% 0px, 0px 0px, 0px 50%)',
  },
  labelContainer: {
    position: 'absolute',
    zIndex: '2',
    height: '160px',
    width,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  value: {
    fontWeight: '600',
    fontSize: '32px',
    color: '#222929',
  },
  label: {
    color: '#757575',
    fontSize: '14px',
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
