import { Box, Tab } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import AnalyticsDashboard from 'components/AnalyticsDashboard';
import AnalyticsLastRefresh from 'components/AnalyticsLastRefresh';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import MonthlyWeightedAverageApr from 'components/MonthlyWeightedAverageApr';
import MonthlyWeightedAverageLossesAndNetReturns from 'components/MonthlyWeightedAverageLossesAndNetReturns';
import OriginationAverages from 'components/OriginationAverages';
import OriginationsByMonth from 'components/OriginationsByMonth';
import OutstandingTotalLoanBalance from 'components/OutstandingTotalLoanBalance';
import RepaymentStatus from 'components/RepaymentStatus';
import RequireProduct from 'components/RequireProduct';
import UptimeStatus from 'components/UptimeStatus';
import UseOfFunds from 'components/UseOfFunds';
import WeightedAverages from 'components/WeightedAverages';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { useProduct } from 'contexts/ProductContext';
import React, { useMemo } from 'react';
import useStyle from './style';

const PerformanceMetricWrapper = () => {
  const classes = useStyle();

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.sectionTitle}>
          {'Portfolio & Servicing'}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <RepaymentStatus />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Outstanding portfolio" />
          <CardContent>
            <WeightedAverages />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={9}>
        <OutstandingTotalLoanBalance />
      </Grid>
    </React.Fragment>
  );
};

const Metrics = () => {
  const classes = useStyle();
  const { currentProduct } = useProduct();
  const { analyticsEnabled } = useAnalytics();
  const [selectedTab, setSelectedTab] = React.useState(window.location.hash === '' ? 'originations' : window.location.hash.replace('#', ''));
  const servicesOwnLoans = useMemo(
    () => currentProduct?.attributes?.servicesOwnLoans,
    [currentProduct],
  );

  const onSelectedTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <RequireProduct>
      {analyticsEnabled && (
        <TabContext value={selectedTab}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={onSelectedTabChange}>
                <Tab label="Originations" value="originations" />
                <Tab
                  style={{ visibility: servicesOwnLoans ? 'hidden' : 'visible' }}
                  label="Portfolio"
                  value="portfolio"
                />
              </TabList>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <TabPanel value="originations">
              <Grid item xs={12}>
                <AnalyticsDashboard
                  reportPath={'embed/dashboards/lending-partnership-reporting::originations_detail'}
                  headerText="Summary"
                  showDivider={false}
                  subHeader={false}
                />
              </Grid>
              <Grid xs={12} item container className={classes.caption}>
                <AnalyticsLastRefresh />
                <UptimeStatus />
              </Grid>
            </TabPanel>
            <TabPanel value="portfolio">
              <Grid container spacing={3}>
                { !servicesOwnLoans && <PerformanceMetricWrapper /> }
              </Grid>
            </TabPanel>
          </Grid>
        </TabContext>
      )}
      {!analyticsEnabled && (
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.sectionTitle}>Originations</Typography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <OriginationAverages />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card>
              <CardHeader title="Loans per month and $ per month" addFullscreenOption />
              <CardContent>
                <OriginationsByMonth />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Card>
              <CardHeader title="Use of funds" />
              <CardContent>
                <UseOfFunds />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card>
              <CardHeader title="Rolling view of core metrics by loan origination month" addFullscreenOption />
              <CardContent>
                <MonthlyWeightedAverageApr />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card>
              <CardHeader title="Expected weighted average annualized loss rates" addFullscreenOption />
              <CardContent>
                <MonthlyWeightedAverageLossesAndNetReturns />
              </CardContent>
            </Card>
          </Grid>
          { !servicesOwnLoans && <PerformanceMetricWrapper /> }
        </Grid>
      )}
    </RequireProduct>
  );
};

export default Metrics;
