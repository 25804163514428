import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  welcomeBanner: {
    display: 'flex',
    width: '100%',
    alignItems: 'start',
    backgroundColor: '#EDFAFC',
    padding: '12px 12px',
    borderRadius: '5px',
    minHeight: '70px',
    justifyContent: 'space-between',
    boxShadow: '0 0px 10px rgba(0, 0, 0, 0.2)',
  },
  welcomeBannerContent: {
    display: 'flex',
    alignItems: 'start',
    height: '100%',
  },
  welcomeBannerIcon: {
    width: '32px',
    marginRight: '10px',
  },
  welcomeHeader: {
    color: '#006D69',
    fontSize: '20px',
    fontWeight: '600',
    margin: '0',
  },
  welcomeText: {
    color: '#006D69',
    fontSize: '14px',
    margin: '0',
  },
  welcomeButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#006D69',
  },
}));

export default useStyles;
