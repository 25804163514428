import React, { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { useAsync } from 'react-async';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Loader from 'components/Loader';
import LastUpdatedNotifier from 'components/LastUpdatedNotifier';
import { useProduct } from 'contexts/ProductContext';
import { getRepaymentStatus } from 'api/performanceMetric';
import CardHeader from 'components/CardHeader';
import CardContent from 'components/CardContent';
import Card from 'components/Card';
import useStyle from './style';

const RepaymentStatus = () => {
  const classes = useStyle();
  const { currentProductKey } = useProduct();
  const {
    data,
    isLoading,
    isFulfilled,
  } = useAsync(getRepaymentStatus, { productKey: currentProductKey, watch: currentProductKey });

  const reportData = useMemo(() => (
    isFulfilled ? data?.data?.data : {}
  ), [isFulfilled]);

  const currentBalance = useMemo(() => (
    reportData?.current?.balance ? `$${reportData.current.balance.toLocaleString()}` : '—'
  ), [reportData]);
  const currentPercent = useMemo(() => (
    reportData?.current?.percent ? `${reportData.current.percent}%` : '—'
  ), [reportData]);
  const lessThanSixteenBalance = useMemo(() => (
    reportData?.lessThanSixteen?.balance ? `$${reportData.lessThanSixteen.balance.toLocaleString()}` : '—'
  ), [reportData]);
  const lessThanSixteenPercent = useMemo(() => (
    reportData?.lessThanSixteen?.percent ? `${reportData.lessThanSixteen.percent}%` : '—'
  ), [reportData]);
  const lessThanThirtyOneBalance = useMemo(() => (
    reportData?.lessThanThirtyOne?.balance ? `$${reportData.lessThanThirtyOne.balance.toLocaleString()}` : '—'
  ), [reportData]);
  const lessThanThirtyOnePercent = useMemo(() => (
    reportData?.lessThanThirtyOne?.percent ? `${reportData.lessThanThirtyOne.percent}%` : '—'
  ), [reportData]);
  const lessThanSixtyOneBalance = useMemo(() => (
    reportData?.lessThanSixtyOne?.balance ? `$${reportData.lessThanSixtyOne.balance.toLocaleString()}` : '—'
  ), [reportData]);
  const lessThanSixtyOnePercent = useMemo(() => (
    reportData?.lessThanSixtyOne?.percent ? `${reportData.lessThanSixtyOne.percent}%` : '—'
  ), [reportData]);
  const lessThanNinetyOneBalance = useMemo(() => (
    reportData?.lessThanNinetyOne?.balance ? `$${reportData.lessThanNinetyOne.balance.toLocaleString()}` : '—'
  ), [reportData]);
  const lessThanNinetyOnePercent = useMemo(() => (
    reportData?.lessThanNinetyOne?.percent ? `${reportData.lessThanNinetyOne.percent}%` : '—'
  ), [reportData]);
  const ninetyOneOrMoreBalance = useMemo(() => (
    reportData?.ninetyOneOrMore?.balance ? `$${reportData.ninetyOneOrMore.balance.toLocaleString()}` : '—'
  ), [reportData]);
  const ninetyOneOrMorePercent = useMemo(() => (
    reportData?.ninetyOneOrMore?.percent ? `${reportData.ninetyOneOrMore.percent}%` : '—'
  ), [reportData]);
  const chargedOffBalance = useMemo(() => (
    reportData?.chargedOff?.balance ? `$${reportData.chargedOff.balance.toLocaleString()}` : '—'
  ), [reportData]);
  const chargedOffPercent = useMemo(() => (
    reportData?.chargedOff?.percent ? `${reportData.chargedOff.percent}%` : '—'
  ), [reportData]);
  const date = useMemo(() => (
    reportData?.date ? parseISO(reportData?.date) : undefined
  ), [reportData]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader data-testid="loader" />
      ) : (
        <Card>
          <CardHeader title="Repayment Status" addFullscreenOption />
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell className={classes.borderedHeaderCell}>
                      Current principal balance
                    </TableCell>
                    <TableCell className={classes.borderedHeaderCell}>
                      Current principal %
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.borderedHeaderCell}>
                      In repayment
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {currentBalance}
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {currentPercent}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.borderedHeaderCell}>
                      1-15 dpd
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {lessThanSixteenBalance}
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {lessThanSixteenPercent}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.borderedHeaderCell}>
                      16-30 dpd
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {lessThanThirtyOneBalance}
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {lessThanThirtyOnePercent}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.borderedHeaderCell}>
                      31-60 dpd
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {lessThanSixtyOneBalance}
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {lessThanSixtyOnePercent}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.borderedHeaderCell}>
                      61-90 dpd
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {lessThanNinetyOneBalance}
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {lessThanNinetyOnePercent}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.borderedHeaderCell}>
                      91+ dpd
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {ninetyOneOrMoreBalance}
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {ninetyOneOrMorePercent}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.borderedHeaderCell}>
                      Charged off
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {chargedOffBalance}
                    </TableCell>
                    <TableCell className={classes.borderedTableCell}>
                      {chargedOffPercent}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <LastUpdatedNotifier date={date} />
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default RepaymentStatus;
