import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Avatar from '@mui/material/Avatar';
import { ExpandMore } from '@material-ui/icons';
import ForecastInputModifierTab from 'components/ForecastInputModifierTab/ForecastInputModifierTab';
import {
  getManagementAssumptionAdjustments,
  searchManagementAssumptionAdjustment,
} from 'api/managementAssumptionAdjustment';
import Loader from 'components/Loader';
import { useForecast } from '../../contexts/ForecastContext';

const ForecastModifiers = () => {
  const {
    expanded,
    handlePanel,
    forecastUuid,
    duplicateAdjustment,
    setError,
    error,
    isValidated,
  } = useForecast();
  const [searchForecastMaas, setSearchForecastMaas] = useState([]);
  const [isLoadingAdjustments, setIsLoadingAdjustments] = useState(false);
  const [, setIsLoadingSearch] = useState(true);
  const [adjustments, setAdjustments] = useState(null);
  const [currentTab, setCurrentTab] = useState('');
  const [currentAdjustment, setCurrentAdjustment] = useState({});
  const [currentAdjustmentId, setCurrentAdjustmentId] = useState(null);
  const [csvData, setCsvData] = useState('');

  const handleAddToAdjustments = newAdjustment => {
    setAdjustments(prevAdjs => {
      const newAdjs = prevAdjs.filter(
        a => a.attributes.adjustmentType !== newAdjustment.attributes.adjustmentType,
      );
      return [...newAdjs, newAdjustment];
    });
  };

  const totalErrors = () => {
    const totals = [];

    Object.keys(isValidated).forEach(k => {
      if (isValidated[k] !== true) {
        totals.push({
          adjustmentType: isValidated[k]?.adjustmentType,
          count: isValidated[k].errors?.adjustments.length,
        });
      }
    });

    return totals;
  };

  useEffect(() => {
    if (currentTab !== '') {
      setIsLoadingSearch(true);
      searchManagementAssumptionAdjustment(forecastUuid, currentTab)
        .then(res => {
          setSearchForecastMaas(res.data.data);
          setIsLoadingSearch(false);
        })
        .catch(e => {
          setError(
            e.response?.data?.message || 'An error loading the submitted Managment Assumption Adjustments for this forecast has occurred',
          );
          setIsLoadingSearch(false);
        });
    }
  }, [forecastUuid, currentTab]);

  useEffect(() => {
    if (!forecastUuid) return;

    setIsLoadingAdjustments(true);
    getManagementAssumptionAdjustments(forecastUuid)
      .then(res => {
        setAdjustments(res.data.data);
        setCurrentTab('chargeOffTiming');
        setIsLoadingAdjustments(false);
        setError(false);
      })
      .catch(e => {
        setError(
          ...error,
          e.response?.data?.message || 'An error retrieving input modifiers has occurred',
        );
        setIsLoadingAdjustments(false);
      });
  }, [forecastUuid]);

  useEffect(() => {
    if (adjustments) {
      const startingAdjustment = adjustments.find(
        adj => adj.attributes.adjustmentType === currentTab,
      ) ?
        adjustments.find(adj => adj.attributes.adjustmentType === currentTab) :
        null;
      setCurrentAdjustment(startingAdjustment);
      setCurrentAdjustmentId(startingAdjustment?.id || null);
      setCsvData(startingAdjustment?.attributes?.adjustments);
    }
  }, [currentTab]);

  const allTabs = [
    {
      label: 'Charge Off Timing',
      value: 'chargeOffTiming',
      permittedHeaders: [
        'product_type_id',
        'days_past_due_start',
        'daily_charge_off_probability',
      ],
    },
    {
      label: 'Default Prepayment Calibration',
      value: 'defaultPrepaymentCalibration',
      permittedHeaders: [
        'product_type_id',
        'origination_month',
        'risk_tier',
        'default_calibration_multiplier',
        'prepayment_calibration_multiplier',
        'recovery_adjustment_type',
        'recovery_adjustment_value',
        'half_life_easing_in_months',
      ],
    },
    {
      label: 'Default Prepayment Sensitivity',
      value: 'defaultPrepaymentSensitivity',
      permittedHeaders: [
        'product_type_id',
        'default_sensitivity_multiplier',
        'prepayment_sensitivity_multiplier',
        'recovery_sensitivity_multiplier',
      ],
    },
    {
      label: 'Discount Rate',
      value: 'discountRate',
      permittedHeaders: [
        'product_type_id',
        'contract_months_start',
        'loss_type',
        'loss_value_start',
        'intercept_adder',
        'multiple_of_loss_rate_adder',
        'annual_servicing_fee_adder',
        'loan_term_adder',
        'multiple_of_loan_term_adder',
      ],
    },
    {
      label: 'Recovery Timing',
      value: 'recoveryTiming',
      permittedHeaders: [
        'product_type_id',
        'months_after_charge_off',
        'cumulative_recovery_timing_rate',
      ],
    },
    {
      label: 'Seasonality Adjustment',
      value: 'seasonalityAdjustment',
      permittedHeaders: [
        'product_type_id',
        'underwriting_model',
        'month_of_year',
        'seasonality_default_multiplier',
      ],
    },
    {
      label: 'Synthetic Loans',
      value: 'syntheticLoans',
      permittedHeaders: [
        'product_type_id',
        'loan_contract_id',
        'loan_id',
        'first_payment_date',
        'interest_rate',
        'contract_months',
        'loan_amount',
        'conditional_default_probability_csv',
        'conditional_prepayment_probability_csv',
        'recovery_rate',
      ],
    },
    {
      label: 'Timing Curve Adjustment',
      value: 'timingCurveAdjustment',
      permittedHeaders: [
        'product_type_id',
        'underwriting_model',
        'payment_number',
        'timing_curve_default_multiplier',
      ],
    },
    {
      label: 'Upstart Macro Factor',
      value: 'upstartMacroFactor',
      permittedHeaders: [
        'product_type_id',
        'underwriting_model',
        'upstart_macro_factor',
      ],
    },
    {
      label: 'Upstart Macro Index',
      value: 'upstartMacroIndex',
      permittedHeaders: [
        'product_type_id',
        'payment_month',
        'seasonally_adjusted_upstart_macro_index',
      ],
    },
  ];

  return (
    <>
      <Accordion
        expanded={expanded === 'all' || expanded === 'modifier-panel'}
        onChange={handlePanel('modifier-panel')}
        style={{ borderRadius: 4, backgroundColor: 'white' }}
        id="modifier-panel"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          style={{ fontWeight: 'bold' }}
        >
          <Avatar
            sx={{
              bgcolor: '#00807B',
              width: 18,
              height: 18,
              fontSize: 10,
              marginRight: '5px',
              fontFamily: 'Montserrat',
            }}
          >
            3
          </Avatar>
          {' '}
          Input modifiers
        </AccordionSummary>
        {isLoadingAdjustments && <Loader />}
        {isValidated && (
          <Alert severity={totalErrors()?.some(obj => obj.count > 0) ? 'error' : 'success'}>
            Data validator:
            {' '}
            {totalErrors().reduce((total, obj) => obj.count + total, 0)}
            {' '}
            issues
            detected
            <ul>
              {totalErrors().map(te => (
                <li>
                  {te.adjustmentType}
                  :
                  {' '}
                  {te.count}
                  {' '}
                  issues
                  {' '}
                </li>
              ))}
            </ul>
          </Alert>
        )}
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
          {adjustments && (
          <ForecastInputModifierTab
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            allTabs={allTabs}
            currentAdjustment={currentAdjustment}
            setCurrentAdjustment={setCurrentAdjustment}
            duplicateAdjustment={duplicateAdjustment}
            searchForecastMaas={searchForecastMaas}
            currentAdjustmentId={currentAdjustmentId}
            csvData={csvData}
            setCsvData={setCsvData}
            handleAddToAdjustments={handleAddToAdjustments}
          />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ForecastModifiers;
