import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useUser } from 'contexts/UserContext';
import { useView } from 'contexts/ViewContext';
import { useAuth } from 'contexts/AuthContext';
import { useAppConfig } from '../App';

const UserDropdown = () => {
  const { authActions } = useAuth();
  const [anchorEl, setAnchorEl] = useState();
  const { appConfig } = useAppConfig();
  const {
    user, isOnboarder, isForecaster, isRiskCapitalUser, hasPortfolioAnalyticsAccess,
  } = useUser();
  const {
    productView,
    investorView,
    forecastView,
    portfolioAnalyticsView,
    setProductView,
    setInvestorView,
    setForecastView,
    setPortfolioAnalyticsView,
    hasLenderAccess,
    hasInvestorAccess,
  } = useView();

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProductClose = () => {
    setProductView();
    setAnchorEl(null);
  };

  const handleInvestorClose = () => {
    setInvestorView();
    setAnchorEl(null);
  };

  const handleForecastClose = () => {
    setForecastView();
    setAnchorEl(null);
  };

  const handlePortfolioAnalyticsClose = () => {
    setPortfolioAnalyticsView();
    setAnchorEl(null);
  };

  const handleRiskCapitalClose = () => {
    const capitalMarketsHost = appConfig.capitalMarketsHost || '';
    // window location href nav required to enforce nav away from react app routing
    window.location.href = `${capitalMarketsHost}/risk-capital`;
  };

  // The top hidden menu is for Mobile - DO NOT REMOVE
  return (
    <React.Fragment>
      <Hidden initialWidth="md" smDown>
        <Button
          data-testid="user-menu"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          disabled={!user}
        >
          {user?.name ? user.name : '—'}
        </Button>
      </Hidden>
      <Hidden initialWidth="sm" mdUp>
        <Button
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
        >
          Menu
        </Button>
      </Hidden>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          {user && hasLenderAccess && !portfolioAnalyticsView && (
            <Hidden initialWidth="sm" mdUp>
              <MenuItem onClick={handleClose} component={Link} to="/">
                <Typography noWrap>
                  Dashboard
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/reports">
                <Typography noWrap>
                  Reports
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/metrics">
                <Typography noWrap>
                  Metrics
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/configuration">
                <Typography noWrap>
                  Configuration
                </Typography>
              </MenuItem>
            </Hidden>
          )}

          {user && hasPortfolioAnalyticsAccess && portfolioAnalyticsView && (
            <Hidden initialWidth="sm" mdUp>
              <MenuItem onClick={handleClose} component={Link} to="/">
                <Typography noWrap>
                  Dashboard
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/credit-performance">
                <Typography noWrap>
                  Credit Performance
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/saved-queries">
                <Typography noWrap>
                  Saved Queries
                </Typography>
              </MenuItem>
            </Hidden>
          )}

          {hasInvestorAccess && !investorView && (
            <MenuItem onClick={handleInvestorClose} component={Link} to="/">
              <Typography noWrap>
                Investor View
              </Typography>
            </MenuItem>
          )}
          {hasLenderAccess && !productView && (
            <MenuItem onClick={handleProductClose} component={Link} to="/">
              <Typography noWrap>
                Product View
              </Typography>
            </MenuItem>
          )}
          {isForecaster && !forecastView && (
            <MenuItem onClick={handleForecastClose} component={Link} to="/">
              <Typography noWrap>
                Forecasting
              </Typography>
            </MenuItem>
          )}
          {isRiskCapitalUser && (
            <MenuItem onClick={handleRiskCapitalClose}>
              <Typography noWrap>
                Risk Capital
              </Typography>
            </MenuItem>
          )}
          {hasPortfolioAnalyticsAccess && !portfolioAnalyticsView && (
            <MenuItem onClick={handlePortfolioAnalyticsClose} component={Link} to="/">
              <Typography noWrap>
                Upstart Portfolio Analytics
              </Typography>
            </MenuItem>
          )}
          <MenuItem onClick={() => authActions.signOut('/')}>
            <Typography noWrap>
              Logout
            </Typography>
          </MenuItem>
          {isOnboarder && (
            <MenuItem component={Link} to="/bank-onboarding-page">
              <Typography noWrap>
                Onboard New Bank
              </Typography>
            </MenuItem>
          )}
        </div>
      </Menu>
    </React.Fragment>
  );
};

export default UserDropdown;
