import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useView } from 'contexts/ViewContext';
import PortfolioAnalytics from 'pages/PortfolioAnalytics';
import Dashboard from '../../pages/Dashboard';
import CreditPerformance from '../../pages/CreditPerformance';
import SavedQueries from '../../pages/SavedQueries';
import OAuthSecureRoute from '../OAuthSecureRoute';

const RoutesPortfolioAnalytics = () => {
  const { portfolioAnalyticsView } = useView();

  if (portfolioAnalyticsView) {
    return (
      <Switch>
        <OAuthSecureRoute exact path="/">
          <PortfolioAnalytics />
        </OAuthSecureRoute>
        <OAuthSecureRoute exact path="/credit-performance">
          <CreditPerformance />
        </OAuthSecureRoute>
        <OAuthSecureRoute exact path="/saved-queries">
          <SavedQueries />
        </OAuthSecureRoute>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <OAuthSecureRoute exact path="/">
          <Dashboard />
        </OAuthSecureRoute>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }
};

export default RoutesPortfolioAnalytics;
