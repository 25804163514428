/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { useAsync } from 'react-async';
import { parseISO, format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'components/Loader';
import { useProduct } from 'contexts/ProductContext';
import { getMonthlyPortfolioLoanMetrics } from 'api/performanceMetric';
import abbreviateNumber from 'utils/abbreviateNumber';
import theme from 'theme';
import CardHeader from 'components/CardHeader';
import CardContent from 'components/CardContent';
import Card from 'components/Card';

const OutstandingTotalLoanBalance = () => {
  const { currentProductKey } = useProduct();
  const { data, isLoading, isFulfilled } = useAsync(getMonthlyPortfolioLoanMetrics, {
    productKey: currentProductKey,
    limit: 12,
    watch: currentProductKey,
  });
  const { green } = theme.palette;

  if (isLoading) {
    return <Loader data-testid="loader" />;
  }

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  const series = isFulfilled ? (
    data?.data?.data.sort((a, b) => parseISO(a.attributes.date) - parseISO(b.attributes.date))
  ) : [];
  const parsedData = series.map(
    ({ attributes: { totalOutstandingLoanBalance } }) => totalOutstandingLoanBalance,
  );
  const categories = series.map(({ attributes: { date } }) => format(parseISO(date), 'LLL'));

  const options = {
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    chart: {
      type: 'area',
      style: {
        fontFamily: 'Montserrat',
      },
    },
    series: [
      {
        name: 'Loan Balance',
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, Highcharts.color(green.main).setOpacity(0.4).get('rgba')],
            [1, Highcharts.color(green.main).setOpacity(0.1).get('rgba')],
          ],
        },
        marker: {
          enabled: false,
        },
        lineWidth: 1,
        lineColor: green.main,
        data: parsedData,
      },
    ],
    xAxis: [{
      categories,
    }],
    yAxis: {
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        formatter() {
          const label = this.axis.defaultLabelFormatter.call(this);
          return `$${abbreviateNumber(label)}`;
        },
        style: {
          color: '#757575',
        },
      },
      title: {
        text: '',
        style: {
          color: '#757575',
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Card>
      <CardHeader title="Outstanding total loan balance" addFullscreenOption />
      <CardContent>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </CardContent>
    </Card>
  );
};

export default OutstandingTotalLoanBalance;
