import React, { useContext } from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import CardContent from 'components/CardContent';
import { CardContext } from 'contexts/CardContext';
import useStyle from './style';

const CardModal = () => {
  const classes = useStyle();
  const { title, content, modalOpen, setModalOpen } = useContext(CardContext);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby={`${title} Modal`}
      aria-describedby={`${title} Modal`}
      className={classes.modal}
      closeAfterTransition
    >
      <div>
        <Fade in={modalOpen}>
          <Card className={classes.card}>
            <CardHeader
              title={title}
              action={(
                <IconButton size="small" onClick={() => setModalOpen(false)} data-testid="close-button">
                  <CloseIcon />
                </IconButton>
              )}
            />
            <CardContent>
              {content}
            </CardContent>
          </Card>
        </Fade>
      </div>
    </Modal>
  );
};

export default CardModal;
