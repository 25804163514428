import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getExplorableReports } from 'api/explorableReport';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import ExplorableReportCreate from 'components/ExplorableReportCreate';
import Loader from 'components/Loader';
import React, { useEffect } from 'react';
import sharedStyles from 'theme/sharedStyles';

const ExplorableReportEditor = () => {
  const classes = sharedStyles();
  const [showCreate, setShowCreate] = React.useState(false);
  const [editing, setEditing] = React.useState(null);
  const [data, setData] = React.useState(null);

  const getExplorables = () => {
    getExplorableReports().then(response => {
      setData(response?.data?.data);
    });
  };

  const afterSave = () => {
    setEditing(null);
    getExplorables();
    setShowCreate(false);
  };

  useEffect(() => {
    getExplorables();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} elevation={0}>
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <Button onClick={() => setShowCreate(!showCreate)} variant="contained">
                Add New
              </Button>
            </Grid>
            {showCreate && (
              <Grid item xs={12}>
                <ExplorableReportCreate afterSave={() => afterSave()} />
              </Grid>
            )}
            <hr className={classes.horizontalRule} />
            {data ? (
              <TableContainer>
                <Table aria-label="report_types table" className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.headRow}>
                      <TableCell>Category</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Url</TableCell>
                      <TableCell>Visibility</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.length > 0 && data.map(explorableReport => (
                      <>
                        <TableRow>
                          <TableCell>{explorableReport.attributes.category}</TableCell>
                          <TableCell>{explorableReport.attributes.name}</TableCell>
                          <TableCell>{explorableReport.attributes.url}</TableCell>
                          <TableCell>{explorableReport.attributes.visibility}</TableCell>
                          <TableCell>
                            <Button variant="contained" onClick={() => setEditing(editing ? null : explorableReport)}>
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                        {editing && editing === explorableReport && (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <ExplorableReportCreate
                                afterSave={() => afterSave()}
                                explorableReport={explorableReport}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : <Loader data-testid="loader" />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ExplorableReportEditor;
