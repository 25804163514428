import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiCardHeader-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiCardHeader-title': {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      paddingLeft: '12px',
    },
    '& .MuiCardHeader-subheader': {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      '& a': {
        textDecoration: 'none',
        color: '#757575',
      },
    },
  },
  action: {
    marginTop: '0px',
    marginRight: '0px',
  },
  iconButtonRoot: {
    padding: '0px',
  },
}));

export default useStyles;
