import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import FeatureFlags from 'components/FeatureFlags';
import { useProduct } from 'contexts/ProductContext';
import { useInstitution } from 'contexts/InstitutionContext';
import { useUser } from 'contexts/UserContext';

export const ViewContext = createContext();

export const useView = () => ({ ...useContext(ViewContext) });

export const ViewContextProvider = ({ children }) => {
  const flags = FeatureFlags();
  const products = useProduct();
  const institutions = useInstitution();
  const { isForecaster, hasPortfolioAnalyticsAccess } = useUser();
  const productViewName = 'product';
  const investorViewName = 'investor';
  const forecastViewName = 'forecast';
  const portfolioAnalyticsViewName = 'portfolioAnalytics';
  const currentViewKeyCookieName = 'bank-partner-console-current-view-key';
  const [cookies, setCookie] = useCookies([currentViewKeyCookieName]);

  const investorsEnabled = useMemo(() => (
    flags && flags['investorsEnabled?'] === true
  ), [flags]);
  const checkInvestorAccess = () => {
    if (investorsEnabled && institutions?.institutions?.length > 0) return true;
    return false;
  };
  const checkLenderAccess = () => {
    if (products?.products?.length > 0) return true;
    return false;
  };

  const checkForecastAccess = () => {
    if (isForecaster) return true;
    return false;
  };
  const hasLenderAccess = checkLenderAccess(products);
  // Wrapper to enable the Investors View
  const hasInvestorAccess = checkInvestorAccess(investorsEnabled, institutions);

  const hasForecastAccess = checkForecastAccess();
  const setProductView = () => setCookie(currentViewKeyCookieName, productViewName);
  const setInvestorView = () => {
    setCookie(currentViewKeyCookieName, investorViewName);
  };
  const setForecastView = () => {
    if (isForecaster) {
      setCookie(currentViewKeyCookieName, forecastViewName);
    }
  };

  const setPortfolioAnalyticsView = () => {
    if (hasPortfolioAnalyticsAccess) {
      setCookie(currentViewKeyCookieName, portfolioAnalyticsViewName);
    }
  };

  const currentView = useMemo(() => {
    const cookieValue = cookies[currentViewKeyCookieName];
    if (!hasInvestorAccess && !hasLenderAccess && !isForecaster &&
      !hasPortfolioAnalyticsAccess) return null; // Not loaded
    if (!investorsEnabled && !isForecaster && !hasPortfolioAnalyticsAccess) {
      return productViewName; // Default to product view until investors feature is enabled
    }

    if (cookieValue) {
      return cookieValue;
    } else if (hasLenderAccess) {
      setProductView();
      return productViewName;
    } else if (isForecaster) {
      setForecastView();
      return forecastViewName;
    } else if (hasInvestorAccess) {
      setInvestorView();
      return investorViewName;
    } else if (hasPortfolioAnalyticsAccess) {
      setPortfolioAnalyticsView();
      return portfolioAnalyticsViewName;
    } else {
      return null;
    }
  }, [hasInvestorAccess, hasLenderAccess, investorsEnabled, isForecaster, cookies]);
  const investorView = currentView === investorViewName;
  const forecastView = currentView === forecastViewName;
  const productView = currentView === productViewName;
  const portfolioAnalyticsView = currentView === portfolioAnalyticsViewName;

  return (
    <ViewContext.Provider
      value={{
        productView,
        investorView,
        forecastView,
        portfolioAnalyticsView,
        setInvestorView,
        setProductView,
        setForecastView,
        setPortfolioAnalyticsView,
        hasInvestorAccess,
        hasLenderAccess,
        hasForecastAccess,
      }}
    >
      {children}
    </ViewContext.Provider>
  );
};

ViewContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ViewContextProvider.defaultProps = {
  children: [],
};
