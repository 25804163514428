import { makeStyles } from '@material-ui/core/styles';

const useLegendStyles = makeStyles({
  chartLegend: {
    '& .highcharts-point': {
      fill: props => props.color,
      y: 11,
      width: 15.85,
      height: 2,
      ry: 0,
      rx: 0,
    },
  },
});

export default useLegendStyles;
