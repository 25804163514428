import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useView } from 'contexts/ViewContext';
import ForecastConfigurations from 'pages/ForecastConfigurations';
import ForecastConfigurationsForm from 'pages/ForecastConfigurationsForm';
import Dashboard from '../../pages/Dashboard';
import { ForecastContextProvider } from '../../contexts/ForecastContext';
import OAuthSecureRoute from '../OAuthSecureRoute';

const RoutesForecast = () => {
  const { forecastView } = useView();

  if (forecastView) {
    return (
      <Switch>
        <OAuthSecureRoute exact path="/">
          <ForecastConfigurations />
        </OAuthSecureRoute>
        <OAuthSecureRoute exact path="/forecast-configuration/:uuid?">
          <ForecastContextProvider>
            <ForecastConfigurationsForm />
          </ForecastContextProvider>
        </OAuthSecureRoute>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <OAuthSecureRoute exact path="/">
          <Dashboard />
        </OAuthSecureRoute>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }
};

export default RoutesForecast;
