import React from 'react';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import useStyle from './style';

const FileDisplay = ({ file, onClear }) => {
  const classes = useStyle();
  return (
    <div className={classes.root} data-testid="file-display">
      <span>{`${file.name} (${prettyBytes(file.size)})`}</span>
      <IconButton size="small" onClick={onClear} data-testid="file-display-remove-button">
        <ClearIcon />
      </IconButton>
    </div>
  );
};

FileDisplay.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  onClear: PropTypes.func.isRequired,
};

export default FileDisplay;
