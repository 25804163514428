import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useUser } from 'contexts/UserContext';
import { useView } from 'contexts/ViewContext';
import HelpIcon from 'assets/question-inside-circle.svg';
import PortfolioLegalDisclaimerModal from 'components/PortfolioLegalDisclaimerModal';

const HelpDropdown = () => {
  const [anchorEl, setAnchorEl] = useState();
  const [legalDisclaimerOpen, setLegalDisclaimerOpen] = useState(false);
  const {
    user, hasPortfolioAnalyticsAccess,
  } = useUser();
  const {
    portfolioAnalyticsView,
    setPortfolioAnalyticsView,
  } = useView();

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePortfolioAnalyticsClose = () => {
    setPortfolioAnalyticsView();
    setAnchorEl(null);
  };

  const showLegalDislaimerModal = () => {
    setLegalDisclaimerOpen(true);
  };
  const handleCloseLegalDisclaimerModal = () => {
    setLegalDisclaimerOpen(false);
  };

  const handleCloseAndOpenLegalDisclaimer = () => {
    setAnchorEl(null);
    showLegalDislaimerModal();
  };

  // The top hidden menu is for Mobile - DO NOT REMOVE
  return (
    <>
      {/* eslint-disable-next-line max-len */}
      <PortfolioLegalDisclaimerModal isOpen={legalDisclaimerOpen} onClose={handleCloseLegalDisclaimerModal} />
      <React.Fragment>
        <Hidden initialWidth="md" smDown>
          <Button
            data-testid="user-menu"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleClick}
            disabled={!user}
          >
            <img src={HelpIcon} alt="Help" />
          </Button>
        </Hidden>
        <Hidden initialWidth="sm" mdUp>
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon />}
          >
            Help
          </Button>
        </Hidden>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div>
            {hasPortfolioAnalyticsAccess && !portfolioAnalyticsView && (
              <MenuItem onClick={handlePortfolioAnalyticsClose} component={Link} to="/">
                <Typography noWrap>
                  Upstart Portfolio Analytics
                </Typography>
              </MenuItem>
            )}
            <MenuItem onClick={handleCloseAndOpenLegalDisclaimer}>
              <Typography noWrap>
                Disclaimer
              </Typography>
            </MenuItem>
          </div>
        </Menu>
      </React.Fragment>
    </>
  );
};

export default HelpDropdown;
