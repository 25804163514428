import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { useProduct } from 'contexts/ProductContext';
import Loader from 'components/Loader';

const RequireProduct = ({ children }) => {
  const { productLoading, currentProduct, productCallCounter } = useProduct();
  return (
    <React.Fragment>
      {productLoading && (
        <Grid item xs={12}>
          <Loader />
        </Grid>
      )}
      {!productLoading && !currentProduct && productCallCounter > 0 && (
        <Grid item xs={12}>
          <Alert severity="warning">{`You don't have access to any products.`}</Alert>
        </Grid>
      )}
      {!productLoading && currentProduct && children}
    </React.Fragment>
  );
};

RequireProduct.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RequireProduct;
