import React from 'react';
import PropTypes from 'prop-types';
import { isValid, format } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import useStyle from './style';

const LastUpdatedNotifier = ({ date }) => {
  const classes = useStyle();
  return (
    <React.Fragment>
      {isValid(date) && (
        <div className={classes.root}>
          <Typography component="p" className={classes.text} style={{ margin: '-1em 1em 1em auto', fontFamily: 'Open Sans' }}>
            {`*Results as of ${format(date, 'M/d/yyyy')}`}
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
};

LastUpdatedNotifier.propTypes = {
  date: PropTypes.instanceOf(Date),
};

LastUpdatedNotifier.defaultProps = {
  date: undefined,
};

export default LastUpdatedNotifier;
