import { getProducts } from 'api/product';
import { useUser } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-async';
import { useCookies } from 'react-cookie';

export const ProductContext = createContext();

export const useProduct = () => ({ ...useContext(ProductContext) });

export const ProductContextProvider = ({ children }) => {
  const { user } = useUser();
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const currentProductKey = useMemo(
    () => currentProduct && currentProduct.attributes.productKey, [currentProduct],
  );
  const currentProductType = useMemo(
    () => currentProduct && currentProduct.attributes.productType, [currentProduct],
  );
  const currentProductKeyCookieName = 'bank-partner-console-current-product-key';
  const [cookies, setCookie] = useCookies([currentProductKeyCookieName]);
  const hasMultipleBankAccess = useMemo(() => (
    [...new Set(products.map(product => product.attributes.issuingBankSlug))].length > 1
  ), [products]);
  const {
    run,
    data,
    isFulfilled,
    isLoading: productLoading,
    counter: productCallCounter,
  } = useAsync({ deferFn: getProducts, defer: true });

  useEffect(() => {
    if (user) {
      run(
        user.console_groups?.map(group => group.replace('BPC_Bank Partner Console: ', ''))
          .map(group => group.replace('upstart_upc_', '')).join(','),
      );
    } else {
      setCurrentProduct(null);
    }
  }, [user]);

  useEffect(() => {
    if (isFulfilled) {
      const parsedProducts = data.data.data
        .sort((a, b) => (a.attributes.issuingBankName.localeCompare(b.attributes.issuingBankName, undefined, { sensitivity: 'base' })));
      setProducts(parsedProducts);
      if (cookies[currentProductKeyCookieName]) {
        setCurrentProduct(
          parsedProducts.find(
            pp => pp.attributes.productKey === cookies[currentProductKeyCookieName],
          ) || parsedProducts[0],
        );
      } else if (parsedProducts.length > 0) {
        setCurrentProduct(parsedProducts[0]);
        setCookie(currentProductKeyCookieName, parsedProducts[0].attributes.productKey);
      }
    }
  }, [isFulfilled, data]);

  useEffect(() => {
    if (currentProduct) {
      setCookie(currentProductKeyCookieName, currentProduct.attributes.productKey);
    }
  }, [currentProduct]);

  return (
    <ProductContext.Provider
      value={{
        products,
        currentProductKey,
        currentProduct,
        currentProductType,
        setCurrentProduct,
        hasMultipleBankAccess,
        productLoading,
        productCallCounter,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

ProductContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ProductContextProvider.defaultProps = {
  children: [],
};
