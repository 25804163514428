import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import SideNav from 'components/SideNav';
import TopNav from 'components/TopNav';
import { useView } from 'contexts/ViewContext';
import { useUser } from 'contexts/UserContext';
import Routes from 'components/Routes';
import RoutesInvestors from 'components/RoutesInvestors';
import RoutesForecast from '../RoutesForecast';
import RoutesPortfolioAnalytics from '../RoutesPortfolioAnalytics';
import useStyles from './style';

const Layout = () => {
  const classes = useStyles();
  const {
    productView, hasLenderAccess, investorView, hasInvestorAccess,
    forecastView, hasForecastAccess, portfolioAnalyticsView,
  } = useView();
  const { hasPortfolioAnalyticsAccess } = useUser();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopNav />
      <SideNav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.mainContent}>
          <Grid container className={classes.pageRoot}>
            {hasLenderAccess && productView && (
              <Routes />
            )}
            {hasInvestorAccess && investorView && (
              <RoutesInvestors />
            )}
            {hasPortfolioAnalyticsAccess && portfolioAnalyticsView && (
              <RoutesPortfolioAnalytics />
            )}
            {hasForecastAccess && forecastView && (
              <RoutesForecast />
            )}
          </Grid>
        </div>
      </main>
    </div>
  );
};

export default Layout;
