import apiClient from 'api/apiClient';

export const getReport = ({ productKey, id }) => apiClient.get(`/v1/products/${productKey}/reports/${id}`);

export const getReports = ({
  productKey,
  limit,
  offset,
  query,
  sortField,
  sortDirection,
  createdAfter,
  reportTypeSlug,
}) => (
  apiClient.get(`/v1/products/${productKey}/reports`, {
    params: {
      limit,
      offset,
      query,
      sortField,
      sortDirection,
      createdAfter,
      reportTypeSlug,
    },
  })
);

export const parseReports = data => data.data.data;

export const getReportTypes = ({ productKey, query, version }) => (
  apiClient.get(`/v1/products/${productKey}/report_types`, {
    params: {
      version,
      query,
    },
  })
);

export const parseReportTypes = data => data.data.data;

export const updateReport = ({ productKey, id, data }) => {
  apiClient.put(`/v1/products/${productKey}/reports/${id}`, data);
};

export const createReport = data => (
  apiClient.post('/v1/reports', data)
);

export const getValidContentTypes = () => (
  apiClient.get('/v1/reports/valid_content_types')
);

export const deleteReport = ({ productKey, id }) => (
  apiClient.delete(`/v1/products/${productKey}/reports?report_id=${id}`)
);

export const getReportList = ({ productKey, category }) => (
  apiClient.get(`/v1/products/${productKey}/report_list?category=${category}`)
);
