/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import { useAsync } from 'react-async';
import humps from 'humps';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from 'components/Tooltip';
import { useReport } from 'contexts/ReportContext';
import { getReports, parseReports, deleteReport } from 'api/report';
import { useProduct } from 'contexts/ProductContext';
import { useUser } from 'contexts/UserContext';
import SquareIconButton from '../SquareIconButton';
import useStyle from './style';

const ReportsTable = () => {
  const classes = useStyle();
  const {
    reportLimit,
    reportParams,
    compactReportParams,
    updateReportParams,
    setSelectedReports,
    setCurrentReportDownloadState,
  } = useReport();

  const { currentProductKey } = useProduct();
  const [initialLoading, setInitialLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const { isAdmin } = useUser();

  const {
    data,
    isFulfilled,
    isLoading,
  } = useAsync(getReports, {
    ...compactReportParams,
    watchFn: (o, n) => !isEqual(
      pick(o, Object.keys(reportParams)),
      pick(n, Object.keys(reportParams)),
    ),
  });

  const total = useMemo(
    () => (isFulfilled ? data.data.meta.total : 0),
    [isFulfilled, reports],
  );

  const pageCount = useMemo(
    () => (isFulfilled ? Math.ceil(data.data.meta.total / reportLimit) : 0),
    [isFulfilled, reports, reportLimit],
  );

  const columns = useMemo(() => [
    {
      Header: 'Report',
      id: 'name',
      accessor: 'attributes.displayName',
      width: 225,
    },
    {
      Header: 'File Name',
      id: 'filename',
      accessor: 'attributes.filename',
      width: 'auto',
    },
    {
      Header: 'Date Uploaded',
      id: 'reportDate',
      accessor: row => row.attributes.reportDateDisplay,
      width: 110,
    },
    {
      id: 'pagination',
      disableSortBy: true,
      Header: ({ nextPage, previousPage, state }) => (
        <TablePagination
          component="div"
          page={state.pageIndex}
          count={state.total}
          rowsPerPage={reportLimit}
          rowsPerPageOptions={[]}
          onPageChange={() => null}
          nextIconButtonProps={{
            onClick: nextPage,
            'data-testid': 'pagination-next',
          }}
          backIconButtonProps={{
            onClick: previousPage,
          }}
          classes={{
            root: classes.paginationRoot,
            caption: classes.paginationCaption,
          }}
        />
      ),
      accessor: row => row,
      Cell: e => (
        <span className={'report-table-tooltip'}>
          <SquareIconButton
            icon={<GetAppIcon />}
            title="Download"
            data-testid="download-button"
            type="button"
            onClick={event => {
              event.preventDefault();
              setCurrentReportDownloadState({
                reportId: e.value.id,
                actionEndpoint: 'download_single',
                paramName: 'report_id',
              });
            }}
          />
          { isAdmin && e.value.id.includes('manual_upload') && (
            <span>
              <SquareIconButton
                icon={<DeleteIcon />}
                title="Delete"
                data-testid="delete-button"
                onClick={event => {
                  event.preventDefault();
                  if (window.confirm('Delete this report?')) {
                    deleteReport({ productKey: currentProductKey, id: e.value.id })
                      .then(() => {
                        updateReportParams({
                          ...reportParams,
                          ...{ deleted_id: e.value.id },
                        });
                      }).then(() => alert('Report is successfully deleted!'));
                  }
                }}
              />
            </span>
          )}
          <span>
            <Tooltip message={e.value.attributes.infoPopup} />
          </span>
        </span>
      ),
      width: 260,
      minWidth: 260,
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data: reports,
      initialState: {
        pageSize: reportLimit,
        pageIndex: 0,
      },
      pageCount,
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      useControlledState: state => useMemo(() => ({
        ...state,
        total,
      }), [state]),
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(visibleColumns => [
        {
          id: 'selection',
          disableSortBy: true,
          width: 65,
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Checkbox
              {...getToggleAllPageRowsSelectedProps()}
              className={classes.headerCheckbox}
              data-testid="select-all-checkbox"
            />
          ),
          Cell: ({ row }) => (
            <Checkbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...visibleColumns,
      ]);
    },
  );

  useEffect(() => {
    const offset = pageIndex * pageSize;
    const sortField = sortBy[0] && humps.decamelize(sortBy[0].id);
    const sortDirection = sortBy[0] && (sortBy[0]?.desc ? 'desc' : 'asc');
    updateReportParams({
      offset,
      sortField,
      sortDirection,
    });
  }, [pageIndex, pageSize, sortBy]);

  useEffect(() => {
    setSelectedReports(
      Object.keys(selectedRowIds).map(key => reports[key]?.id),
    );
  }, [setSelectedReports, data, selectedRowIds]);

  useEffect(() => {
    if (!isLoading && initialLoading) {
      setInitialLoading(false);
    }
  });

  useEffect(() => {
    if (isFulfilled) {
      if (pageIndex >= pageCount) {
        gotoPage(0);
      }
      setReports(parseReports(data));
    }
  }, [isFulfilled, data, total]);

  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="reports table" className={classes.table} {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow className={classes.headRow} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.canSort ? (
                      <TableSortLabel
                        active={column.isSorted}
                        IconComponent={ImportExportOutlinedIcon}
                        classes={{ root: classes.tableSortLabelRoot }}
                        data-testid="table-sort-label"
                      >
                        {column.render('Header')}
                      </TableSortLabel>
                    ) : column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {!isLoading && (
            <TableBody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <TableRow className={classes.bodyRow} {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <TableCell {...cell.getCellProps()} className={classes.bodyCell}>
                        <Typography title={cell.value} noWrap>
                          {cell.render('Cell')}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {(isLoading || initialLoading) && (
        <Paper elevation={0} className={classes.message}>
          <Typography variany="h3">Loading...</Typography>
        </Paper>
      )}
      {reports.length === 0 && !isLoading && !initialLoading && (
        <Paper elevation={0} className={classes.message} data-testid="no-data-message">
          <Typography variany="h3">No Data</Typography>
        </Paper>
      )}
    </React.Fragment>
  );
};

export default ReportsTable;
