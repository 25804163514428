import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import RequireProduct from 'components/RequireProduct';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import CardContent from 'components/CardContent';
import LinearGauge from 'components/LinearGauge';
import CircularGauge from 'components/CircularGauge';
import CurrentPolicies from 'components/CurrentPolicies';
import BorrowerFees from 'components/BorrowerFees';
import QuickLinks from 'components/QuickLinks';
import Loader from 'components/Loader';
import ConfigurationChanges from 'components/ConfigurationChanges';
import { useProduct } from 'contexts/ProductContext';
import abbreviateNumber from 'utils/abbreviateNumber';
import { getConfiguration } from 'api/configuration';
import theme from 'theme';

const Configuration = () => {
  const { teal, primary, tangerine, turquoise } = theme.palette;
  const [configs, setConfigs] = useState(null);
  const [raisingLimits, setRaisingLimits] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { currentProductKey, currentProduct } = useProduct();

  useEffect(() => {
    if (currentProductKey) {
      getConfiguration({ productKey: currentProductKey }).then(value => {
        setConfigs(value?.data?.data?.attributes);
        setRaisingLimits(value?.data?.meta?.raisingLimits);
      }).finally(() => setIsLoading(false));
    }
  }, [currentProductKey]);

  return (
    <RequireProduct>
      <Grid container item spacing={3}>
        <Grid container item xs={12} md={7} direction="column">
          <Grid item>
            <Box mb={3}>
              <Card>
                <CardHeader title="Loan Amount" />
                <CardContent>
                  {isLoading ? <Loader data-testid="loader" /> : (
                    <LinearGauge
                      color={turquoise.main}
                      minRange={raisingLimits?.min || 0}
                      maxRange={raisingLimits?.max || 0}
                      minValue={configs?.minRaisingAmount}
                      maxValue={configs?.maxRaisingAmount}
                      rangeFormat={range => `$${abbreviateNumber(range)}`}
                      valueFormat={range => `$${abbreviateNumber(range)}`}
                    />
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={3}>
              <Card>
                <CardHeader title="Min Credit Score" />
                <CardContent>
                  {isLoading ? <Loader data-testid="loader" /> : (
                    <LinearGauge
                      color={tangerine.main}
                      minRange={configs?.configuration?.qualifyingCreditScore?.min || 0}
                      maxRange={configs?.configuration?.qualifyingCreditScore?.max || 0}
                      minValue={configs?.qualifyingCreditScore}
                      maxValue={configs?.configuration?.qualifyingCreditScore?.max}
                    />
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={3}>
              <Card>
                <CardHeader title="Max DTI" />
                {configs?.dtiCheckEnabled === false ?
                  <CardContent>This check is not enabled</CardContent> : (
                    <CardContent>
                      {isLoading ? <Loader data-testid="loader" /> : (
                        <LinearGauge
                          color={teal.main}
                          minRange={configs?.configuration?.dtiPercentLimit?.min || 0}
                          maxRange={configs?.configuration?.dtiPercentLimit?.max || 0}
                          minValue={0}
                          maxValue={configs?.dtiPercentLimit}
                          rangeFormat={range => `${range}%`}
                          valueFormat={range => `${range}%`}
                        />
                      )}
                    </CardContent>
                  )}
              </Card>
            </Box>
          </Grid>
          <Grid item>
            <Card>
              <CardHeader title="Current Policies" />
              <CardContent>
                {isLoading ? <Loader data-testid="loader" /> : (
                  <CurrentPolicies policies={configs?.bankPolicies} />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={5} direction="column">
          <Grid item>
            <Box mb={3}>
              <Card>
                <CardHeader title="Configuration Changes" />
                <CardContent>
                  {isLoading ? <Loader data-testid="loader" /> : (
                    <ConfigurationChanges />
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={3}>
              <Card>
                <CardHeader title="Max APR" />
                <CardContent>
                  {isLoading ? <Loader data-testid="loader" /> : (
                    <CircularGauge
                      minRange={configs?.configuration?.maxApr?.min || 0}
                      maxRange={configs?.configuration?.maxApr?.max || 0}
                      value={configs?.maxApr || 0}
                      color={primary.main}
                    />
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item>
            <Box mb={3}>
              <Card>
                <CardHeader title="Borrower Fees" />
                <CardContent>
                  {isLoading ? <Loader data-testid="loader" /> : (
                    <BorrowerFees
                      lateFee={configs?.defaultLateFeeAmount ? `$${configs?.defaultLateFeeAmount}` : 'N/A'}
                      checkFee={configs?.defaultCheckFeeAmount ? `$${configs?.defaultCheckFeeAmount}` : 'N/A'}
                      achReturnFee={configs?.defaultAchReturnFeeAmount ? `$${configs?.defaultAchReturnFeeAmount}` : 'N/A'}
                    />
                  )}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item>
            <Card>
              <CardHeader title="Quick Links" />
              <CardContent>
                {isLoading ? <Loader data-testid="loader" /> : (
                  <QuickLinks links={
                    [
                      { name: 'Contact Us Page', url: `${currentProduct?.attributes?.urlBase}/contact` },
                      { name: 'Help Center', url: configs?.helpCenterRootUrl },
                    ]
                  }
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </RequireProduct>
  );
};

export default Configuration;
