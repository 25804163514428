import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DraftForecasts from 'components/DraftForecasts/DraftForecasts';
import { getForecastConfigurations } from 'api/forecastConfiguration';
import { Alert } from '@material-ui/lab';
import SubmittedForecasts from 'components/SubmittedForecasts/SubmittedForecasts';
import { Grid, Button } from '@material-ui/core';
import { getCurrentForecastConsensus } from 'api/forecastConsensusHistory';

const ForecastConfigurationsIndex = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [draftForecasts, setDraftForecasts] = useState([]);
  const [submittedForecasts, setSubmittedForecasts] = useState([]);
  const [consensus, setConsensus] = useState('');
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    getForecastConfigurations()
      .then(res => {
        setDraftForecasts(res.data.data.draftForecasts);
        setSubmittedForecasts(res.data.data.submittedForecasts);
        setIsLoading(false);
      })
      .catch(e => {
        setError(e.response?.data?.message || 'An error has occurred');
        setIsLoading(false);
      });
    getCurrentForecastConsensus()
      .then(res => {
        setConsensus(res.data?.data?.attributes?.forecastName);
      })
      .catch(e => {
        setError(e.response?.data?.message || 'An error has occurred');
      });
  }, []);

  const createForecast = () => {
    history.push('/forecast-configuration');
  };

  return (
    <Grid container>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <h1>Forecasts</h1>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            style={{
              borderRadius: 0,
              background: '#F3F3F3',
              color: '#00807B',
              width: '200px',
              marginRight: '10px',
            }}
            onClick={createForecast}
          >
            Create forecast
          </Button>
        </Grid>
      </Grid>
      {error && (
        <Alert severity="error">{error}</Alert>
      )}
      <Grid item xs={12} lg={12}>
        <DraftForecasts
          draftForecasts={draftForecasts || []}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <SubmittedForecasts
          submittedForecasts={submittedForecasts || []}
          consensus={consensus || ''}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default ForecastConfigurationsIndex;
