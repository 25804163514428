import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import useResize from 'hooks/useResize';
import useOfFundsDisplayName from 'utils/useOfFundsDisplayName';

import useStyle from './style';

const FundUsage = ({ name, percentage }) => {
  const classes = useStyle();
  const containerRef = useRef();
  const percentageRef = useRef();
  const [percentBarWidth, setPercentBarWidth] = useState(0);
  const { width: windowWidth } = useResize(containerRef);

  useEffect(() => {
    const containerWidth = containerRef?.current?.clientWidth || 0;
    const labelWidth = percentageRef?.current?.clientWidth || 0;
    setPercentBarWidth((percentage * (containerWidth - labelWidth)) / 100);
  }, [containerRef, percentageRef, windowWidth]);

  return (
    <div ref={containerRef} className={classes.root}>
      <Typography>{useOfFundsDisplayName(name)}</Typography>
      <div className={classes.percentRoot}>
        <div className={classes.percentBar} style={{ width: `${percentBarWidth}px` }} data-testid="percent-bar" />
        <Typography ref={percentageRef} className={classes.percentLabel}>{`${percentage}%`}</Typography>
      </div>
    </div>
  );
};

FundUsage.propTypes = {
  name: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default FundUsage;
