import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: '600',
    fontSize: '32px',
    color: '#222929',
    marginBottom: theme.spacing(3),
  },
  outerGauge: {
    height: '16px',
    background: '#E7E7E7',
    marginBottom: theme.spacing(1),
  },
  innerGauge: ({ innerGaugeColor, innerGaugeLeft, innerGaugeWidth }) => ({
    height: '16px',
    width: `${innerGaugeWidth}px`,
    backgroundColor: innerGaugeColor,
    position: 'relative',
    left: `${innerGaugeLeft}px`,
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
  }),
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '14px',
    color: '#757575',
  },
}));

export default useStyles;
