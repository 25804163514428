import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useReport } from '../../contexts/ReportContext';
import useStyle from './style';

const ReportSnapshot = ({ id, name, uploadDate }) => {
  const classes = useStyle();
  const { setCurrentReportDownloadState } = useReport();

  const uploadedAtDate = uploadDate.endsWith('00:00:00.000Z') ?
    new Date(uploadDate).toLocaleDateString('en-US') :
    new Date(uploadDate).toLocaleString('en-US');

  return (
    <Grid item xs={12}>
      <div>
        <Button
          className={clsx(classes.text, classes.link, classes.reportTitle)}
          disableRipple
          disableElevation
          variant="text"
          component="button"
          data-testid="download-button-text"
          onClick={event => {
            event.preventDefault();
            setCurrentReportDownloadState({
              reportId: id,
              actionEndpoint: 'download_single',
              paramName: 'report_id',
            });
          }}
        >
          {name}
        </Button>
        <Typography className={classes.text} color="textSecondary" component="p">
          {`uploaded at ${uploadedAtDate}`}
        </Typography>
      </div>
    </Grid>
  );
};

ReportSnapshot.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  uploadDate: PropTypes.string.isRequired,
};

export default ReportSnapshot;
