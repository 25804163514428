import { hasAuthParams, useAuth as useOidcAuth } from 'react-oidc-context';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import apiClient, { streamingClient } from '../../api/apiClient';
import Loader from '../../components/Loader';

export const AuthContext = createContext({});

const AuthContextAdapter = ({ children }) => {
  const oidcAuth = useOidcAuth();
  const [signInAttempted, setSignInAttempted] = useState(false);
  const [hasAuthed, setAuthed] = useState(false);
  const [user, setUser] = useState(undefined);

  // automatically sign-in
  useEffect(() => {
    if (hasAuthParams()) return;

    if (oidcAuth.isAuthenticated || oidcAuth.activeNavigator || oidcAuth.isLoading) return;

    if (signInAttempted) return;

    setSignInAttempted(true);
    oidcAuth.signinRedirect().catch(e => {
      console.error(`error on sign-in ${e}`);
    });
  }, [oidcAuth, signInAttempted]);

  // signOut invalid user states
  useEffect(() => {
    if (!oidcAuth.isAuthenticated) return;

    if (oidcAuth.user) return;

    oidcAuth.signoutRedirect({
      post_logout_redirect_uri: document.location.origin,
    }).catch(e => {
      console.error(`error on sign-out ${e}`);
    });
  }, [oidcAuth]);

  // authenticate api clients
  useEffect(() => {
    const accessToken = oidcAuth.user?.access_token;
    if (!accessToken) return;

    streamingClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    setAuthed(true);
  }, [oidcAuth]);

  useEffect(() => {
    if (!oidcAuth || oidcAuth.error || !oidcAuth.isAuthenticated) return;

    const accessToken = oidcAuth.user?.access_token || '';
    if (!accessToken) return;

    const tokenUser = accessToken && jwtDecode(accessToken);
    const userName = tokenUser.name || [tokenUser.first_name, tokenUser.last_name].join(' ');
    setUser(prior => {
      if (prior) return prior;

      // prevent re-render on auth renew by updating user only on initial auth
      return {
        ...tokenUser,
        name: userName,
      };
    });
  }, [oidcAuth]);

  // adapt OIDC auth state to app auth state
  const auth = useMemo(() => {
    if (!oidcAuth) return { authState: {} };

    if (oidcAuth.error) {
      console.error(`OIDC auth encountered error`, oidcAuth.error);
      return { error: true };
    }

    const accessToken = oidcAuth.user?.access_token || '';
    return {
      authState: {
        isAuthenticated: !!oidcAuth.isAuthenticated,
        accessToken,
        isPending: !!oidcAuth.isLoading,
        user,
      },
      authActions: {
        signOut: () => oidcAuth.signoutRedirect({
          post_logout_redirect_uri: document.location.origin,
        }),
      },
    };
  }, [oidcAuth, user]);
  const renderContent = () => {
    if (auth?.error) return <Alert severity="warning">Unable to Sign In</Alert>;

    if (!hasAuthed) return <Loader />;

    return children;
  };
  return (
    <AuthContext.Provider value={auth}>
      {renderContent()}
    </AuthContext.Provider>
  );
};

AuthContextAdapter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AuthContextAdapter.defaultProps = {
  children: [],
};

export default AuthContextAdapter;
