import Admin from 'pages/Admin';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useView } from 'contexts/ViewContext';
import Dashboard from 'pages/Dashboard';
import Reports from 'pages/Reports';
import Metrics from 'pages/Metrics';
import Configuration from 'pages/Configuration';
import ApplicantSearch from 'pages/ApplicantSearch';
import OAuthSecureRoute from '../OAuthSecureRoute';

const Routes = () => {
  const { productView } = useView();

  if (productView) {
    return (
      <Switch>
        <OAuthSecureRoute exact path="/">
          <Dashboard />
        </OAuthSecureRoute>
        <OAuthSecureRoute exact path="/reports">
          <Reports />
        </OAuthSecureRoute>
        <OAuthSecureRoute exact path="/metrics">
          <Metrics />
        </OAuthSecureRoute>
        <OAuthSecureRoute exact path="/configuration">
          <Configuration />
        </OAuthSecureRoute>
        <OAuthSecureRoute exact path="/applicant-search">
          <ApplicantSearch />
        </OAuthSecureRoute>
        <OAuthSecureRoute exact path="/admin">
          <Admin />
        </OAuthSecureRoute>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <OAuthSecureRoute exact path="/">
          <Dashboard />
        </OAuthSecureRoute>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }
};

export default Routes;
