import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    tableLayout: 'auto',
  },
  headRow: {
    borderRadius: '2px',
    backgroundColor: theme.palette.primary.main,
    '& th:first-child': {
      width: theme.spacing(8),
      paddingLeft: 20,
    },
    '& th:last-child': {
      minWidth: 230,
    },
    '& th': {
      fontWeight: 600,
      color: '#FFFFFF !important',
    },
  },
  bodyRow: {
    borderRadius: '2px',
    height: 65,
    backgroundColor: '#FFFFFF',
    '& td:first-child': {
      paddingLeft: 20,
    },
    '& td p': {
      ...theme.typography.secondary,
      verticalAlign: 'middle',
    },
    '& .report-table-tooltip': {
      textAlign: 'right',
      display: 'block',
    },
    '& .report-table-tooltip *': {
      display: 'inline-block',
      padding: '0px',
      margin: '0px 5px',
      verticalAlign: 'middle',
    },
  },
  bodyCell: {
    maxWidth: 350,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100px',
    borderRadius: '0px',
  },
  headerCheckbox: {
    color: '#FFFFFF',
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#FFFFFF',
    },
  },
  paginationRoot: {
    color: '#FFFFFF',
  },
  paginationCaption: {
    fontWeight: 600,
  },
  tableSortLabelRoot: {
    '&:hover': {
      color: '#FFFFFF',
    },
    '&.MuiTableSortLabel-active': {
      color: '#FFFFFF',
      '&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: '#FFFFFF',
      },
    },
  },
}));

export default useStyles;
