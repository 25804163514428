import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    background: '#ffffff',
    borderRadius: 0,
  },
}));

export default useStyles;
