import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import useStyle from './style';

const StatGraphCard = ({ title, value, loading }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <p className={classes.title}>{title}</p>
      {loading ? (
        <Loader data-testid="loader" />
      ) : (
        <p className={classes.value}>{value}</p>
      )}
    </div>
  );
};

StatGraphCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default StatGraphCard;
