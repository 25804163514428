import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import AnalyticsLastRefresh from 'components/AnalyticsLastRefresh';
import AnalyticsReport from 'components/AnalyticsReport';
import SquareIconButton from 'components/SquareIconButton';
import { useReport } from 'contexts/ReportContext';
import ReportsSideList from 'components/ReportsSideList';
import ReportsTable from 'components/ReportsTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import sharedStyles from 'theme/sharedStyles';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import UptimeStatus from '../UptimeStatus';

const ReportsSplit = props => {
  const classes = sharedStyles();
  const { category } = props;
  const [selection, setSelection] = useState('');
  const { updateReportParams, selectedReports, setCurrentReportDownloadState } = useReport();

  const onSelectionChange = newValue => {
    setSelection(newValue);
    if (newValue?.attributes?.type === 'static') {
      updateReportParams({ reportTypeSlug: newValue.attributes.slug });
    }
  };

  return (
    <Grid item container spacing={3} className={classes.splitWrapper}>
      <ReportsSideList
        category={category}
        onSelectionChange={onSelectionChange}
      />
      <Grid item className={classes.mainContent}>
        {selection?.attributes?.type === 'explorable' && (
          <>
            <div className={classes.header}>
              <div className={classes.headerText}>{selection.attributes.name}</div>
            </div>
            <AnalyticsReport reportPath={selection.attributes.url} />
            <Grid xs={12} item container className={classes.caption}>
              <AnalyticsLastRefresh />
              <UptimeStatus />
            </Grid>
          </>
        )}
        {selection?.attributes?.type === 'static' && (
          <Grid item container>
            <Grid item md={6} xs={12} className={classes.header}>
              <div className={classes.headerText}>{selection.attributes.name}</div>
            </Grid>
            <Grid item md={6} xs={12} className={classes.header} justifyContent={'flex-end'}>
              <SquareIconButton
                disabled={selectedReports.length < 1}
                icon={(
                  <Typography>
                    Download Selected&nbsp;
                    <GetAppIcon className={classes.alignMiddle} />
                  </Typography>
                )}
                data-testid="download-button"
                onClick={event => {
                  event.preventDefault();
                  setCurrentReportDownloadState({
                    reportId: selectedReports.join(','),
                    actionEndpoint: 'download_multiple',
                    paramName: 'report_ids',
                  });
                }}
              />
            </Grid>
            <ReportsTable />
          </Grid>
        )}
        {selection === '' && (
          <Card className={classes.reportListWrapper}>
            <CardContent className={classes.headerText}>
              <Typography>Please select a report</Typography>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default ReportsSplit;

ReportsSplit.propTypes = {
  category: PropTypes.string.isRequired,
};
