import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ForecastInputModifierErrorPanel = adjustmentErrors => {
  const totalErrors = adjustmentErrors?.adjustmentErrors.errors.adjustments.length;
  const category = adjustmentErrors?.adjustmentErrors.adjustmentType;

  const errorsToShow = () => {
    if (totalErrors > 10) {
      return adjustmentErrors?.adjustmentErrors.errors.adjustments.slice(0, 10);
    } else return adjustmentErrors?.adjustmentErrors.errors.adjustments;
  };

  return (
    <Grid container size={10}>
      <Accordion
        style={{ backgroundColor: '#E7E7E7' }}
        id="error-panel"
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            fontWeight: 'bold',
          }}
        >
          {totalErrors}
          {' '}
          issue(s) detected in
          {category}
          {' '}
          category
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
          <ul>
            {errorsToShow()?.map(err => (
              <li>
                <span style={{ color: 'red' }}>{err.message}</span>
              </li>
            ))}
          </ul>
          {totalErrors > 10 && (
          <h5>
            Showing first 10 of
            {' '}
            {totalErrors}
            {' '}
            issues found. Resolve the above
            issues and click Validate to address the remaining issues
          </h5>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ForecastInputModifierErrorPanel;
