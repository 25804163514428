import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import useResize from 'hooks/useResize';
import useStyle from './style';

const LinearGauge = ({
  minRange,
  maxRange,
  rangeFormat,
  minValue,
  maxValue,
  valueFormat,
  color,
}) => {
  const [innerGaugeWidth, setInnerGaugeWidth] = useState({
    innerWidth: 0,
    left: 0,
  });
  const classes = useStyle({
    innerGaugeColor: color,
    innerGaugeLeft: innerGaugeWidth.left,
    innerGaugeWidth: innerGaugeWidth.innerWidth,
  });
  const outerGaugeRef = useRef();
  const { width } = useResize();

  const updateGauges = () => {
    const left = Math.round(
      (minValue - minRange) / ((maxRange - minRange) / outerGaugeRef.current.clientWidth),
    );
    const innerWidth = Math.round(
      ((maxValue - minValue) / (maxRange - minRange)) * outerGaugeRef.current.clientWidth,
    );
    setInnerGaugeWidth({ innerWidth, left });
  };

  useEffect(() => {
    updateGauges();
  }, [minValue, maxValue, width]);

  return (
    <div>
      <Typography variant="h2" className={classes.header}>
        {minValue === null || maxValue === null ? '—' : `${valueFormat(minValue)}-${valueFormat(maxValue)}`}
      </Typography>
      <div className={classes.outerGauge} ref={outerGaugeRef}>
        <div className={classes.innerGauge} />
      </div>
      <div className={classes.labelContainer}>
        <span className={classes.label}>
          {`${rangeFormat(minRange)} min`}
        </span>
        <span className={classes.label}>
          {`${rangeFormat(maxRange)} max`}
        </span>
      </div>
    </div>
  );
};

LinearGauge.propTypes = {
  minRange: PropTypes.number.isRequired,
  maxRange: PropTypes.number.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  color: PropTypes.string.isRequired,
  rangeFormat: PropTypes.func,
  valueFormat: PropTypes.func,
};

LinearGauge.defaultProps = {
  minValue: null,
  maxValue: null,
  rangeFormat: range => range,
  valueFormat: value => value,
};

export default LinearGauge;
