import React, { useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MenuItem, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import Chip from '@mui/material/Chip';
import PushPinIcon from '@mui/icons-material/PushPin';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ForecastsTable = ({
  columns,
  forecasts,
  consensus,
  initialTableState,
  isLoading,
  rowMenuActions,
  performAction,
}) => {
  const isConsensus = forecast => forecast.forecastName === consensus;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: initialTableState.pagination.pageSize,
  });
  const formatForecastName = forecastObj => {
    if (isConsensus(forecastObj)) {
      return {
        ...forecastObj,
        forecastName: (
          <>
            {forecastObj.forecastName}
            <br />
            <Chip
              variant="filled"
              icon={<PushPinIcon sx={{ '&&': { color: '#00807B' } }} />}
              label={'Consensus Assumption Set'}
              style={{
                borderRadius: 0,
                fontSize: '12px',
                lineHeight: '18px',
                backgroundColor: '#FFFFFF',
                color: '#00807B',
                fontWeight: 700,
                fontFamily: 'Open Sans',
              }}
            />
          </>
        ),
      };
    }
    return forecastObj;
  };

  const modifiedForecasts = forecasts.map(forecast => formatForecastName(forecast));

  const table = useMaterialReactTable({
    columns,
    data: modifiedForecasts,
    icons: { MoreHorizIcon: MoreVertIcon },
    enableStickyHeader: true,
    positionGlobalFilter: 'left',
    initialState: initialTableState,
    enableFacetedValues: true,
    state: { pagination, isLoading, showProgressBars: isLoading },
    enableRowActions: true,
    positionActionsColumn: 'last',
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
    renderRowActionMenuItems: ({ row, t }) => [
      rowMenuActions.map(action => (
        <MenuItem
          key={action}
          onClick={() => performAction(row, action)} // temporary action
          table={t}
        >
          <Typography
            variant="body1"
            style={{ textTransform: 'capitalize' }}
            noWrap
          >
            {action}
          </Typography>
        </MenuItem>
      )),
    ],
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

ForecastsTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessorKey: PropTypes.string,
      enableGlobalFilter: PropTypes.bool,
    }),
  ).isRequired,
  forecasts: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      forecastName: PropTypes.string,
      createdBy: PropTypes.string,
      lastModified: PropTypes.string,
    }),
  ).isRequired,
  initialTableState: PropTypes.shape({
    showLoadingOverlay: PropTypes.bool,
    sorting: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        desc: PropTypes.bool,
      }),
    ),
    showGlobalFilter: PropTypes.bool,
    pagination: PropTypes.shape({
      pageSize: PropTypes.number,
    }),
  }).isRequired,
  consensus: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  rowMenuActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  performAction: PropTypes.func.isRequired,
};

ForecastsTable.defaultProps = {
  consensus: '',
};

export default ForecastsTable;
