import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconContainer: ({ iconColor }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: iconColor,
    backgroundColor: `${iconColor}20`,
    borderRadius: '100%',
    height: theme.spacing(4),
    width: theme.spacing(4),
  }),
  values: {
    fontFamily: theme.typography.secondary.fontFamily,
    wordBreak: 'break-word',
  },
  timestamp: {
    fontFamily: theme.typography.secondary.fontFamily,
    color: '#757575',
  },
}));

export default useStyles;
