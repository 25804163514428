import apiClient from 'api/apiClient';

// eslint-disable-next-line import/prefer-default-export

// Nested routes
export const getManagementAssumptionAdjustments = uuid => apiClient.get(`/v1/forecast_configurations/${uuid}/management_assumption_adjustments`);

export const getManagementAssumptionAdjustment = (uuid, id) => apiClient.get(`/v1/forecast_configurations/${uuid}/management_assumption_adjustments/${id}`);

export const createManagementAssumptionAdjustment = (uuid, data) => apiClient.post(`/v1/forecast_configurations/${uuid}/management_assumption_adjustments`, data);

export const updateManagementAssumptionAdjustment = (uuid, id, data) => apiClient.patch(`/v1/forecast_configurations/${uuid}/management_assumption_adjustments/${id}`, data);

export const duplicateManagementAssumptionAdjustment = (uuid, id, data) => apiClient.put(`/v1/forecast_configurations/${uuid}/management_assumption_adjustments/${id}/duplicate`, data);

export const searchManagementAssumptionAdjustment = (uuid, type) => apiClient.get(`/v1/forecast_configurations/${uuid}/management_assumption_adjustments/search?type=${type}`);

export const validateManagementAssumptionAdjustments = (uuid, type) => apiClient.get(`v1/forecast_configurations/${uuid}/management_assumption_adjustments/validate?type=${type}`);

export default getManagementAssumptionAdjustments;
