import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardWrapper: ({ invert }) => ({
    backgroundColor: invert && theme.palette.shade.main,
    borderRadius: theme.spacing(1),
  }),
  root: ({ invert }) => ({
    color: invert && '#FFFFFF',
    background: invert && 'linear-gradient(142.51deg, rgba(117, 117, 117, 0) -17.2%, rgba(34, 41, 41, 0.4) 100%)',
    '& .MuiCardHeader-root': {
      borderBottom: `1px solid #E7E7E7${invert ? 50 : ''}`,
    },
  }),
}));

export default useStyles;
