import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import ExplorableReportEditor from 'components/ExplorableReportEditor';
import ReportTypesEditor from 'components/ReportTypesEditor';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import RequireProduct from 'components/RequireProduct';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import ReportForm from 'components/ReportForm';
import Loader from 'components/Loader';
import { useUser } from 'contexts/UserContext';
import sharedStyles from 'theme/sharedStyles';
import UMFEditor from '../../components/UMFEditor';

const Admin = () => {
  const classes = sharedStyles();
  const history = useHistory();
  const { userIsLoading, isAdmin, isConfigAdmin } = useUser();
  const [selectedTab, setSelectedTab] = React.useState('upload_report');

  const onSelectedTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (!userIsLoading && !isAdmin) {
      history.push('/');
    }
  }, [userIsLoading, isAdmin]);

  return (
    <RequireProduct>
      <Grid container item sm={12} elevation={0} spacing={3}>
        <TabContext value={selectedTab}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={onSelectedTabChange}>
                <Tab label="Upload Report" value="upload_report" />
                <Tab
                  label="Report Types"
                  value="report_types"
                  style={{ visibility: isConfigAdmin ? 'visible' : 'hidden' }}
                />
                <Tab
                  label="Explorable Reports"
                  value="explorable_reports"
                  style={{ visibility: isConfigAdmin ? 'visible' : 'hidden' }}
                />
                <Tab
                  label="UMF Editor"
                  value="umf_editor"
                  style={{ visibility: isConfigAdmin ? 'visible' : 'hidden' }}
                />
              </TabList>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TabPanel className={classes.tabPanel} value="upload_report">
              <Card>
                <CardContent>
                  {userIsLoading ? <Loader data-testid="loader" /> : <ReportForm />}
                </CardContent>
              </Card>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value="report_types">
              <ReportTypesEditor />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value="explorable_reports">
              <ExplorableReportEditor />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value="umf_editor">
              <UMFEditor />
            </TabPanel>
          </Grid>
        </TabContext>
      </Grid>
    </RequireProduct>
  );
};

export default Admin;
