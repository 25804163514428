import { getInstitutions } from 'api/institution';
import { useUser } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-async';
import { useCookies } from 'react-cookie';

export const InstitutionContext = createContext();

export const useInstitution = () => ({ ...useContext(InstitutionContext) });

export const InstitutionContextProvider = ({ children }) => {
  const { user } = useUser();
  const [institutions, setInstitutions] = useState([]);
  const [currentInstitution, setCurrentInstitution] = useState(null);
  const currentInstitutionName = useMemo(
    () => currentInstitution && currentInstitution.attributes.name, [currentInstitution],
  );
  const currentNameCookieName = 'bank-partner-console-current-institution-key';
  const [cookies, setCookie] = useCookies([currentNameCookieName]);
  const {
    run,
    data,
    isFulfilled,
    isLoading: institutionLoading,
    counter: institutionCallCounter,
  } = useAsync({ deferFn: getInstitutions, defer: true });

  useEffect(() => {
    if (user) {
      run(
        user.institutions?.map(group => group.replace('upstart_institution_', '')).join(','),
      );
    } else {
      setCurrentInstitution(null);
    }
  }, [user]);

  useEffect(() => {
    if (isFulfilled) {
      const listInstitutions = data.data.data;
      setInstitutions(listInstitutions);
      if (cookies[currentNameCookieName]) {
        setCurrentInstitution(
          listInstitutions.find(
            pp => pp.attributes.name === cookies[currentNameCookieName],
          ) || listInstitutions[0],
        );
      } else if (listInstitutions.length > 0) {
        setCurrentInstitution(listInstitutions[0]);
        setCookie(currentNameCookieName, listInstitutions[0].attributes.name);
      }
    }
  }, [isFulfilled, data]);

  useEffect(() => {
    if (currentInstitution) {
      setCookie(currentNameCookieName, currentInstitution.attributes.name);
    }
  }, [currentInstitution]);

  return (
    <InstitutionContext.Provider
      value={{
        institutions,
        currentInstitutionName,
        currentInstitution,
        setCurrentInstitution,
        institutionLoading,
        institutionCallCounter,
      }}
    >
      {children}
    </InstitutionContext.Provider>
  );
};

InstitutionContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

InstitutionContextProvider.defaultProps = {
  children: [],
};
