import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiCardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import { CardContext } from 'contexts/CardContext';
import CardModal from 'components/CardModal';
import useStyle from './style';

const CardHeader = ({ children, title, addFullscreenOption, ...rest }) => {
  const classes = useStyle();
  const { setModalOpen, setTitle } = useContext(CardContext);

  useEffect(() => {
    setTitle(title);
  }, []);

  return (
    <React.Fragment>
      <MuiCardHeader
        classes={{
          root: classes.root,
          action: classes.action,
        }}
        action={
          addFullscreenOption && (
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              onClick={() => setModalOpen(true)}
              data-testid="fullscreen-button"
            >
              <AspectRatioOutlinedIcon />
            </IconButton>
          )
        }
        title={title}
        {...rest}
      >
        {children}
      </MuiCardHeader>
      <CardModal />
    </React.Fragment>
  );
};

CardHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  addFullscreenOption: PropTypes.bool,
};

CardHeader.defaultProps = {
  children: [],
  addFullscreenOption: false,
};

export default CardHeader;
