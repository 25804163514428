import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import ReportDownloadSearch from 'components/ReportDownloadSearch';
import ReportsTabbed from 'components/ReportsTabbed';
import ReportsTable from 'components/ReportsTable';
import ReportTableTypeSearch from 'components/ReportTableTypeSearch';
import RequireProduct from 'components/RequireProduct';
import SquareIconButton from 'components/SquareIconButton';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ReportContextProvider } from 'contexts/ReportContext';
import { useUser } from 'contexts/UserContext';
import React from 'react';
import { useHistory } from 'react-router';
import DownloadFileForm from '../../components/DownloadFileForm';
import useStyle from './style';

const Reports = () => {
  const classes = useStyle();
  const history = useHistory();
  const { isAdmin } = useUser();
  const { analyticsEnabled } = useAnalytics();

  return (
    <RequireProduct>
      <Grid container item spacing={3}>
        {analyticsEnabled && (
          <ReportContextProvider>
            <ReportsTabbed />
            <DownloadFileForm />
          </ReportContextProvider>
        )}
        {!analyticsEnabled && (
          <ReportContextProvider>
            <Grid item md={4} xs={12}>
              <div className={classes.reportTypeAddContainer}>
                <ReportTableTypeSearch />
                {isAdmin && (
                  <SquareIconButton
                    icon={<AddIcon />}
                    onClick={() => history.push('/admin')}
                  />
                )}
              </div>
            </Grid>
            <Grid item md={2} />
            <Grid item md={6} xs={12}>
              <ReportDownloadSearch />
            </Grid>
            <Grid item xs={12}>
              <ReportsTable />
            </Grid>
            <DownloadFileForm />
          </ReportContextProvider>
        )}
      </Grid>
    </RequireProduct>
  );
};

export default Reports;
