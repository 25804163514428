import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import useStyle from './style';

const CircularGauge = ({ minRange, maxRange, value, label, color }) => {
  const clipPath = useMemo(() => {
    const percent =
      (maxRange - minRange) > 0 ? Math.round((value / (maxRange - minRange)) * 200) : 0;
    if (percent > 150) {
      return `polygon(100% ${percent - 150}%, 50% 50%, 0px 50%, 0px 0px, 100% 0px)`;
    } else if (percent > 50) {
      return `polygon(50% 50%, 0px 50%, 0px 0px, ${percent - 50}% 0px)`;
    } else {
      return `polygon(50% 50%, 0px 50%, 0px ${50 - percent}%)`;
    }
  }, [minRange, maxRange, value]);
  const classes = useStyle({ color, clipPath });

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.innerGauge} />
        <div className={classes.outerGauge} />
        <div className={classes.labelContainer}>
          <Typography className={classes.value}>
            {`${value}%`}
          </Typography>
        </div>
      </div>
      <Typography className={classes.label} component="p" align="center">
        {label}
      </Typography>
    </React.Fragment>
  );
};

CircularGauge.propTypes = {
  minRange: PropTypes.number.isRequired,
  maxRange: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  color: PropTypes.string.isRequired,
};

CircularGauge.defaultProps = {
  label: '',
};

export default CircularGauge;
