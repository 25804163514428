import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiCardContent from '@material-ui/core/CardContent';
import { CardContext } from 'contexts/CardContext';
import useStyle from './style';

const CardContent = ({ children, ...rest }) => {
  const classes = useStyle();
  const { setContent } = useContext(CardContext);

  useEffect(() => {
    setContent(children);
  }, []);

  return (
    <MuiCardContent classes={{ root: classes.root }} {...rest}>
      {children}
    </MuiCardContent>
  );
};

CardContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CardContent.defaultProps = {
  children: [],
};

export default CardContent;
