import React, { createContext, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import { getRiskCapitalDeals } from 'api/riskCapitalDeals';
import { useAuth } from 'contexts/AuthContext';

export const UserContext = createContext();

export const useUser = () => ({ ...useContext(UserContext) });

export const UserContextProvider = ({ children }) => {
  const { authState } = useAuth();
  const {
    run: fetchRiskCapitalDeals,
    isFulfilled: riskCapitalDealsSuccess,
  } = useAsync({
    deferFn: getRiskCapitalDeals,
  });

  useEffect(() => {
    fetchRiskCapitalDeals();
  }, [fetchRiskCapitalDeals]);

  const value = useMemo(() => {
    const user = authState?.user;
    const forecastPrivileges = user?.forecast_privileges?.map(group => group.replace('upstart_forecast_', ''));
    return {
      user,
      forecastPrivileges,
      isAdmin: !!user?.console_is_admin,
      isForecaster: forecastPrivileges?.length > 0,
      isConfigAdmin: !!user?.console_is_config_admin,
      isOnboarder: !!user?.console_is_onboarder,
      hasPortfolioAnalyticsAccess: user?.upstart_portfolio_analytics_upc,
      hasPiiAccess: !!user?.console_with_pii,
      isForecastAdmin: forecastPrivileges?.indexOf('admin') >= 0,
      isForecastStandard: forecastPrivileges?.indexOf('standard') >= 0,
      userIsLoading: !!authState.isPending,
      isRiskCapitalUser: !!riskCapitalDealsSuccess,
    };
  },
  [authState, riskCapitalDealsSuccess]);

  return (
    <UserContext.Provider
      value={value}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

UserContextProvider.defaultProps = {
  children: [],
};
