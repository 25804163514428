import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CardContext = createContext();

export const CardContextProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(null);

  return (
    <CardContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        title,
        setTitle,
        content,
        setContent,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};

CardContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CardContextProvider.defaultProps = {
  children: [],
};
