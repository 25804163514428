import apiClient from 'api/apiClient';

// eslint-disable-next-line import/prefer-default-export

export const getForecastConsensusHistory = () => apiClient.get('/v1/forecast_consensus_history');

export const getCurrentForecastConsensus = () => apiClient.get(`/v1/forecast_consensus_history/current`);

export const createForecastConsensusHistory = (uuid) => apiClient.post('/v1/forecast_consensus_history', { uuid });

export default getForecastConsensusHistory;
