function streamZipDownload(data, filename) {
  const blob = new Blob([data], { type: 'application/zip' });
  const downloadUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = downloadUrl;
  downloadLink.download = filename;
  downloadLink.click();
}

export default streamZipDownload;
