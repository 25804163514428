/**
 * Upserts the provided boolean flag key from the url search into local storage.
 * Returns the c
 * @param {Location} location the url location to check
 * @param {string} key the boolean flag key to check for
 * @return { boolean | undefined }
 * the flag value (undefined when key not present or invalid in url & storage)
 */
const upsertFromSearch = (location, key) => {
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has(key)) {
    const paramValue = searchParams.get(key);
    try {
      localStorage.setItem(key, (!!JSON.parse(paramValue)).toString());
    } catch (_) { /* param flag is not valid JSON */ }
  }

  const value = localStorage.getItem(key);
  if (value === null) return undefined;

  try {
    return !!(JSON.parse(value));
  } catch (_) { /* local storage not valid json */ }

  return undefined;
};

export default { upsertFromSearch };
