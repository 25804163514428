import { getLastRefreshTime } from 'api/analyticsApi';
import { useProduct } from 'contexts/ProductContext';
import { useUser } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

export const AnalyticsContext = createContext();

export const useAnalytics = () => ({ ...useContext(AnalyticsContext) });

export const AnalyticsContextProvider = ({ children }) => {
  const [lastRefreshTime, setLastRefreshTime] = useState();
  const { currentProductType } = useProduct();
  const { user } = useUser();

  const analyticsEnabled = useMemo(() => (
    (!!user?.upstart_portfolio_analytics || (currentProductType === 'personal_loan' && (!!user?.upstart_upc_looker_standard || !!user?.upstart_upc_looker_premium)))
  ), [currentProductType, user]);

  const analyticsPremiumEnabled = useMemo(() => (
    (!!user?.upstart_portfolio_analytics || (currentProductType === 'personal_loan' && !!user?.upstart_upc_looker_premium))
  ),
  [currentProductType, user]);

  const getRefresh = () => {
    if (analyticsEnabled) {
      getLastRefreshTime().then(result => {
        setLastRefreshTime(Date.parse(result?.data));
      });
    }
  };

  useEffect(() => {
    getRefresh();
  }, [analyticsEnabled]);

  return (
    <AnalyticsContext.Provider
      value={{
        lastRefreshTime,
        analyticsEnabled,
        analyticsPremiumEnabled,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AnalyticsContextProvider.defaultProps = {
  children: [],
};
