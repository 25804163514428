import upperFirst from 'lodash/upperFirst';

// keys are snake-cased because of they come from Ruby
const mapping = {
  credit_card_refinancing: 'Pay off credit cards',
  debt_consolidation: 'Consolidate debt',
  student_loan_refinancing: 'Refinance student loans',
  medical: 'Pay medical bills',
  bills_or_rent: 'Pay bills or rent',
  athletic: 'Pay for Athletic training',
  home_improvement: 'Pay for home improvement',
  relocation: 'Relocate',
  taxes: 'Pay taxes',
  vacation: 'Pay for vacation',
  wedding: 'Pay for wedding expenses',
  buy_a_vehicle: 'Buy a car',
  large_purchase: 'Make a large purchase',
  business: 'Start or expand a business',
  education: 'Pay for college or grad school',
  family: 'Help out a family member',
  coding_bootcamp: 'Take a course or bootcamp',
  auto_refinancing: 'Auto Refinance',
  other: 'Use the funds for something else',
};

const useOfFundsDisplayName = useOfFunds => mapping[useOfFunds] || upperFirst(useOfFunds.replaceAll(/_/g, ' '));

export default useOfFundsDisplayName;
