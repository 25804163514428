import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { format } from 'date-fns';
import React from 'react';

const AnalyticsLastRefresh = () => {
  const { lastRefreshTime } = useAnalytics();
  return (
    <Grid item style={{ display: 'flex' }}>
      { lastRefreshTime ? (
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <Typography variant="body1">
            { `Database updated as of: ${format(lastRefreshTime, 'MM/dd h:mm a')} Pacific Time` }
          </Typography>
        </div>
      ) : <div />}
    </Grid>
  );
};

export default AnalyticsLastRefresh;
