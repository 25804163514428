import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import useStyle from './style';

const FileDropzone = ({
  onDrop,
  multiple = true,
  accept,
  description,
  validator,
  onDropRejected,
}) => {
  const classes = useStyle();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
    validator,
    onDropRejected,
  });

  return (
    <form>
      <div
        {...getRootProps()}
        className={clsx(classes.root, isDragActive && classes.dragActiveRoot)}
      >
        <input {...getInputProps()} data-testid="dropzone-input" />
        <NoteAddOutlinedIcon className={classes.icon} />
        <p className={classes.message}>
          Drag and drop files here.
          <br />
          {description}
        </p>
      </div>
    </form>
  );
};

FileDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  description: PropTypes.string.isRequired,
  validator: PropTypes.func,
  onDropRejected: PropTypes.func,
};

FileDropzone.defaultProps = {
  multiple: true,
  accept: '',
  validator: null,
  onDropRejected: null,
};

export default FileDropzone;
