import React from 'react';
import PropTypes from 'prop-types';
import MuiCard from '@material-ui/core/Card';
import { CardContextProvider } from 'contexts/CardContext';
import useStyle from './style';

const Card = ({ children, invert, ...rest }) => {
  const classes = useStyle({ invert });

  return (
    <CardContextProvider>
      <div className={classes.cardWrapper}>
        <MuiCard elevation={0} classes={{ root: classes.root }} {...rest}>
          {children}
        </MuiCard>
      </div>
    </CardContextProvider>
  );
};

Card.propTypes = {
  invert: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Card.defaultProps = {
  invert: false,
  children: [],
};

export default Card;
