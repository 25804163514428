import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Loader from 'components/Loader';
import { getApplicants, parseApplicants } from 'api/applicantSearch';
import { useProduct } from 'contexts/ProductContext';
import ApplicantsTable from './ApplicantsTable';
import useStyles from '../style';
import ApplicantSearchCard from '../ApplicantSearchCard';
import SystemError from '../icons/SystemError';

const SearchForm = ({ initialUpstartId, nextPage, pageTitle, aanReasons }) => {
  const { currentProductKey } = useProduct();
  const classes = useStyles();

  const [upstartId, setUpstartId] = useState(initialUpstartId);
  const [errorMessage, setErrorMessage] = useState(null);

  const { isInitial, run, isPending, value, error } = useAsync({
    deferFn: getApplicants,
  });

  const isInputValid = input => input && !Number.isNaN(Number(input));

  const handleChange = input => {
    setUpstartId(input);

    setErrorMessage(null);
    if (!currentProductKey) {
      setErrorMessage('Please select a product before search');
    } else if (!isInputValid(input)) {
      setErrorMessage('Please enter a valid upstart id');
    }
  };

  useEffect(() => {
    if (!currentProductKey) {
      setErrorMessage('Please select a product before search');
    } else if (!isInputValid(upstartId)) {
      setErrorMessage('Please enter a valid upstart id');
    } else {
      run(currentProductKey, upstartId);
    }
  }, []);

  useEffect(() => {
    if (!isInitial && !isPending && !error && !parseApplicants(value).length) {
      setErrorMessage('Upstart Id not found.');
    }
  }, [isPending]);

  return (
    <React.Fragment>
      {error ? (
        <ApplicantSearchCard>
          <SystemError />
          <h2>Service is currently unavailable. Please check back later.</h2>
        </ApplicantSearchCard>
      ) : (
        <React.Fragment>
          <Card className={classes.searchSection}>
            <CardContent>
              <TextField
                className={classes.errorInput}
                error={!!errorMessage}
                label={'Upstart Id'}
                defaultValue={upstartId}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="icon-button"
                        disabled={!!errorMessage}
                        onClick={() => run(currentProductKey, upstartId)}
                      >
                        {errorMessage ? <ErrorIcon className={classes.errorIconColor} /> :
                        <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={e => handleChange(e.target.value)}
                helperText={errorMessage}
              />
            </CardContent>
          </Card>
          {isPending ? <Loader data-testid="loader" /> :
            (
              <ApplicantsTable
                data={parseApplicants(value)}
                nextPage={nextPage}
                pageTitle={pageTitle}
                listAanReasons={aanReasons}
              />
            )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

SearchForm.propTypes = {
  initialUpstartId: PropTypes.string.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageTitle: PropTypes.func.isRequired,
  aanReasons: PropTypes.func.isRequired,
};

export default SearchForm;
