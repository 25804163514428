import { makeStyles } from '@material-ui/core/styles';
import { sharedClasses } from 'theme/sharedStyles';

const styles = () => (
  {
    iframeHeight: {
      height: '80vh',
    },
  }
);

const useStyles = makeStyles(theme => ({
  ...sharedClasses(theme),
  ...styles(theme),
}));

export default useStyles;
