import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';

const ValueSnapshot = ({ icon, title, value, color }) => {
  const classes = useStyles({ iconColor: color });
  return (
    <Grid container spacing={3} item className={classes.root}>
      <Grid item>
        <div className={classes.iconContainer}>
          {icon}
        </div>
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Typography className={classes.value} gutterBottom>{value}</Typography>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

ValueSnapshot.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default ValueSnapshot;
