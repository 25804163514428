import { Grid } from '@material-ui/core';
import getAppConfig from 'api/appConfig';
import { useProduct } from 'contexts/ProductContext';
import { useExternalInvestor } from 'contexts/ExternalInvestorContext';
import { useEffect, useState } from 'react';
import getEmbedUrl from 'api/embed';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import { randomString } from 'utils/toolsService';
import { useView } from 'contexts/ViewContext';
import useStyles from './style';
import ReportDatesSelector from '../ReportDatesSelector';

const AnalyticsDashboard = (
  {
    reportPath,
    headerText,
    linkText,
    linkUrl,
    showDivider,
    showDateSelector,
    dateFilterField,
    subBoard,
    subHeader,
    subHeaderText,
  },
) => {
  const classes = useStyles();
  const { productView, investorView } = useView();
  const { currentInvestorName } = useExternalInvestor();
  const { currentProductKey } = useProduct();
  const { data: appConfigData } = useAsync(getAppConfig);
  const [url, setUrl] = useState('');
  const [contentHeight, setContentHeight] = useState('600px');
  const [embedId] = useState(randomString());
  const [isExploring, setIsExploring] = useState(false);
  const embedDomain = `?embed_domain=${window.location.origin}`;

  useEffect(() => {
    const handleMessage = event => {
      if (event.source === document.getElementById(embedId)?.contentWindow) {
        if (event.origin === `https://${appConfigData?.data.analyticsRootUrl}`) {
          const data = JSON.parse(event.data);
          // eslint-disable-next-line default-case
          switch (data.type) {
            case 'page:properties:changed':
              setContentHeight(`${data.height}px`);
              break;
            case 'env:client:dialog':
              if (data.dialogType === 'drilling') {
                setIsExploring(data.open);
              }
              break;
            case 'page:changed':
              setIsExploring(!data.page.url.startsWith(`/${reportPath}`));
              break;
          }
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [appConfigData]);

  useEffect(() => {
    if (productView && currentProductKey) {
      getEmbedUrl({
        productKey: currentProductKey,
        embedPath: `${reportPath}${embedDomain}`,
        embedDomain,
        investorName: null,
      }).then(result => setUrl(result.data.url));
    } else if (investorView && currentInvestorName) {
      getEmbedUrl({
        productKey: null,
        embedPath: `${reportPath}${embedDomain}`,
        embedDomain,
        investorName: currentInvestorName,
      }).then(result => setUrl(result.data.url));
    }
  }, [productView, currentProductKey, investorView, currentInvestorName, reportPath]);

  const headerClass = () => {
    if (subHeader) {
      return classes.subHeaderText;
    }
    return classes.headerText;
  };

  const shiftUp = () => {
    if (subHeader || subHeaderText !== '') {
      return classes.shiftUp;
    }
    return '';
  };

  const determineType = () => {
    if (reportPath.includes('dashboard')) {
      return 'dashboard';
    }
    if (reportPath.includes('look')) {
      return 'look';
    }
    return 'explore';
  };

  const runRequest = {
    type: `${determineType()}:run`,
  };

  // eslint-disable-next-line no-unused-vars
  const onDatesChange = filter => {
    // Build the request
    const type = determineType();
    const request = `{
      "type": "${type}:filters:update",
      "filters": {
        "${dateFilterField}": "${filter}"
      }
    }`;
    const embed = document.getElementById(embedId);
    embed?.contentWindow?.postMessage(request, `https://${appConfigData?.data.analyticsRootUrl}`);
    embed?.contentWindow?.postMessage(
      JSON.stringify(runRequest), `https://${appConfigData?.data.analyticsRootUrl}`,
    );
  };

  // return the iframe component with the embed url as the src
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} md={8} className={headerClass()}>
          {headerText}
          <a className={classes.headerLink} href={linkUrl}>
            {linkText}
          </a>
        </Grid>
      </Grid>
      {subBoard && (
        <Grid item xs={12}>
          {subBoard}
        </Grid>
      )}
      {subHeaderText !== '' && (
        <div>
          <Grid item xs={12} md={8} className={classes.subHeaderText2}>
            {subHeaderText}
          </Grid>
        </div>
      )}
      {showDateSelector && (
        <Grid item xs={12} style={{ visibility: isExploring ? 'hidden' : 'visible' }}>
          <ReportDatesSelector defaultDates="1Y" onDatesChange={onDatesChange} />
        </Grid>
      )}
      <Grid item xs={12}>
        <div className={shiftUp()}>
          <div className={classes.iframeDashboardWrapper}>
            <iframe
              id={embedId}
              className={classes.iframe}
              title="Look"
              src={url}
              height={contentHeight}
              width="100%"
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        {showDivider && (<hr className={[classes.horizontalRule, classes.horizontalRuleMargins].join(' ')} />)}
      </Grid>
    </Grid>
  );
};

export default AnalyticsDashboard;

AnalyticsDashboard.propTypes = {
  dateFilterField: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  reportPath: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
  showDateSelector: PropTypes.bool,
  subBoard: PropTypes.element,
  subHeader: PropTypes.bool,
  subHeaderText: PropTypes.string,
};

AnalyticsDashboard.defaultProps = {
  dateFilterField: 'Origination Date',
  linkText: '',
  linkUrl: '',
  showDivider: true,
  showDateSelector: true,
  subBoard: null,
  subHeader: true,
  subHeaderText: '',
};
