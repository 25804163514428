import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toolbarLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    background: '#FFFFFF',
    height: '80px',
    ...theme.mixins.toolbar,
  },
  image: {
    maxWidth: '175px',
    maxHeight: '54px',
  },
}));

export default useStyles;
