import React from 'react';
import { Box, Grid, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import useStyles from '../../components/ReportsTabbed/style';
import PortfolioReportsSaved from '../../components/PortfolioReportsSaved';
import PortfolioAnalyticsExplore from '../../components/PortfolioAnalyticsExplore';

const SavedQueries = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState('my-reports');

  const onSelectedTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <TabContext value={selectedTab}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList variant="scrollable" scrollButtons={'auto'} onChange={onSelectedTabChange}>
              <Tab
                label="My Reports"
                value="my-reports"
              />
              <Tab
                label="+ Create Report"
                className={[classes.linkLikeText, classes.floatRight, classes.hideMobile].join(' ')}
                value="create-report"
              />
            </TabList>
          </Box>
        </Grid>

        <Grid item container xs={12}>
          <TabPanel className={classes.tabPanel} value="my-reports">
            <PortfolioReportsSaved />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value="create-report">
            <PortfolioAnalyticsExplore />
          </TabPanel>
        </Grid>
      </TabContext>
    </>
  );
};
export default SavedQueries;
