import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseInfo } from '@mui/x-license';
import { BrowserRouter } from 'react-router-dom';
import Tracking from './components/Tracking';
import App from './components/App';

LicenseInfo.setLicenseKey('637c6da5cb249a352ced3f6befdf59ecTz04NjcyMSxFPTE3NDI0MTE5OTcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Tracking>
        <App />
      </Tracking>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
