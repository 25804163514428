import apiClient from './apiClient';

export const getUmi = () => (
  apiClient.get('v1/upstart_macro/get_umi')
);

export const getUmf = () => (
  apiClient.get('v1/upstart_macro/get_latest_umf')
);

export const updateUmfValue = (newValue, asOfDate) => (
  apiClient.post('v1/upstart_macro/update_umf_value', { newValue, asOfDate })
);
