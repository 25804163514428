import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { useAsyncDebounce } from 'react-table';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import Divider from '@material-ui/core/Divider';
import SquareIconButton from 'components/SquareIconButton';
import { useReport } from 'contexts/ReportContext';
import useStyle from './style';

const ReportDownloadSearch = () => {
  const classes = useStyle();
  const searchRef = useRef();
  const [showSearch, setShowSearch] = useState(false);
  const {
    reportParams,
    updateReportParams,
    selectedReports,
    setCurrentReportDownloadState,
  } = useReport();
  const [value, setInternalValue] = useState(reportParams.query);
  const onChange = useAsyncDebounce(query => {
    updateReportParams({ query });
  }, 500);

  return (
    <div className={classes.root}>
      <SquareIconButton
        icon={<SearchIcon />}
        data-testid="search-button"
        onClick={() => setShowSearch(prevState => {
          if (!prevState) { searchRef.current.focus(); }
          return !prevState;
        })}
      />
      <FormControl
        className={clsx(
          classes.inputContainer,
          showSearch ? classes.inputContainerOpen : classes.inputContainerClosed,
        )}
      >
        <InputBase
          id="reports-table-search"
          label="Search"
          className={clsx(classes.input, showSearch && classes.inputOpen)}
          value={value}
          onChange={e => {
            setInternalValue(e.target.value);
            onChange(e.target.value);
          }}
          inputProps={{
            tabIndex: showSearch ? null : -1,
            ref: searchRef,
            'data-testid': 'search-input',
          }}
          data-testid="search-input-base"
        />
      </FormControl>
      <Divider orientation="vertical" flexItem />
      <SquareIconButton
        disabled={selectedReports.length < 1}
        icon={<GetAppIcon />}
        data-testid="download-button"
        onClick={event => {
          event.preventDefault();
          setCurrentReportDownloadState({
            reportId: selectedReports.join(','),
            actionEndpoint: 'download_multiple',
            paramName: 'report_ids',
          });
        }}
      />
    </div>
  );
};

export default ReportDownloadSearch;
