import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Loader from 'components/Loader';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px',
  },
}));

const ButtonDropDown = ({ options, selectedOption, onChange, onMenuOpen, isMenuOpen, ...rest }) => {
  const [classes, ref] = [useStyles(), useRef()];
  const [anchorEl, setAnchorEl] = useState();
  const { isLoading, vertical, horizontal } = rest;

  const handleClick = () => {
    onMenuOpen();
    setAnchorEl(ref.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = option => () => {
    onChange(option);
    setAnchorEl(null);
  };

  return (
    <Grid
      className={classes.root}
      item
      xs={horizontal ? 4 : 12}
      lg={horizontal ? 4 : 12}
      xl={vertical ? 12 : 4}
    >
      <Button
        ref={ref}
        style={{ width: '100%' }}
        data-testid="button-drop-down-btn"
        size="small"
        aria-controls="button-drop-down-btn"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        variant={isMenuOpen ? 'contained' : 'text'}
        color={isMenuOpen ? 'primary' : 'default'}
      >
        {isLoading ? <Loader data-testid="loader" size={20} /> : selectedOption}
      </Button>
      <Menu
        keepMounted
        id="button-drop-down-menu"
        data-testid="button-drop-down-menu"
        style={{ padding: '4px 0px' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          options.map(x => (
            <MenuItem data-testid="button-drop-down-option" key={x} onClick={handleSelection(x)}>
              {x}
            </MenuItem>
          ))
        }
      </Menu>
    </Grid>
  );
};

// options are an array of strings or numbers
ButtonDropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  selectedOption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool,
};

ButtonDropDown.defaultProps = {
  options: [],
  selectedOption: null,
  isMenuOpen: false,
};

export default ButtonDropDown;
