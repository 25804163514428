import apiClient from 'api/apiClient';

export const createReportType = data => (
  apiClient.post('/v1/report_types/create', { report_type: data })
);

export const editReportType = data => (
  apiClient.put(`/v1/report_types/edit`, { report_type: data })
);

export const listReportTypes = () => apiClient.get(`/v1/report_types/list`);
