/* eslint-disable react/no-this-in-sfc */
import * as React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';
import Button from '@material-ui/core/Button';

const { highlight } = theme.palette;
const RangeButton = ({ value, year, setYear }) => (
  <Button
    style={{ textTransform: 'capitalize', width: '206px', display: 'block', borderBottom: `4px solid ${year === value ? highlight.main : '#ddd'}`, marginLeft: '16px', borderRadius: 0 }}
    type={'Button'}
    onClick={() => {
      setYear(value);
    }}
  >
    { value }
      &nbsp;Year
  </Button>
);

RangeButton.propTypes = {
  value: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  setYear: PropTypes.func.isRequired,
};

export default RangeButton;
