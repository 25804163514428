import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  iconContainer: ({ iconColor }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: iconColor,
    backgroundColor: `${iconColor}20`,
    borderRadius: '100%',
    height: '48px',
    width: '48px',
  }),
  title: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#757575',
    display: 'block',
  },
  value: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '24px',
    color: '#222929',
  },
}));

export default useStyles;
