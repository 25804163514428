import { Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { createExplorableReport, editExplorableReport } from 'api/explorableReport';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './style';

const ExplorableReportCreate = props => {
  const classes = useStyles();
  const [name, setName] = React.useState(props.explorableReport?.attributes?.name || '');
  const [url, setUrl] = React.useState(props.explorableReport?.attributes?.url || '');
  const [category, setCategory] = React.useState(props.explorableReport?.attributes?.category || 'Applications');
  const [visibility, setVisibility] = React.useState(props.explorableReport?.attributes?.visibility || 'visible');
  const [error, setError] = React.useState('');

  const saveReportType = () => {
    createExplorableReport({
      name,
      url,
      category,
      visibility,
    }).then(() => {
      props.afterSave();
    }).catch(response => setError(JSON.stringify(response.message)));
  };

  const doEditReportType = () => {
    editExplorableReport({
      id: props.explorableReport.id,
      name,
      url,
      category,
      visibility,
    }).then(() => {
      props.afterSave();
    }).catch(response => setError(JSON.stringify(response.message)));
  };

  return (
    <Card>
      <CardContent>
        <Grid container xs={12} className={classes.container}>
          <Grid item xs={3}>
            <Select onChange={e => setCategory(e.target.value)} value={category} label="Category">
              <MenuItem value="Applications">Applications</MenuItem>
              <MenuItem value="Loans">Loans</MenuItem>
              <MenuItem value="Program">Program</MenuItem>
              <MenuItem value="Finance">Finance</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={5}>
            <TextField
              className={classes.textField}
              onChange={e => setName(e.target.value)}
              value={name}
              label="Name"
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.explorableReport ? () => doEditReportType() : () => saveReportType()}
            >
              {props.explorableReport ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
        <Grid container xs={12} className={classes.container}>
          <Grid item xs={3}>
            <Select onChange={e => setVisibility(e.target.value)} value={visibility} label="Visibility">
              <MenuItem value="hidden">Hidden</MenuItem>
              <MenuItem value="visible">Visible</MenuItem>
              <MenuItem value="visible_to_non_servicing">Visible To Non-Servicing</MenuItem>
              <MenuItem value="visible_to_servicing">Visible To Servicing</MenuItem>
              <MenuItem value="visible_to_admin">Visible To Admin</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={5}>
            <TextField
              className={classes.textField}
              onChange={e => setUrl(e.target.value)}
              value={url}
              label="URL"
            />
          </Grid>
          <Grid item xs={4} />
        </Grid>
        {error !== '' && (
          <Grid item xs={12}>
            {error}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ExplorableReportCreate;

ExplorableReportCreate.propTypes = {
  afterSave: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  explorableReport: PropTypes.any,
};

ExplorableReportCreate.defaultProps = {
  afterSave: () => {},
  explorableReport: null,
};
