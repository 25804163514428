import React from 'react';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useOfFundsDisplayName from 'utils/useOfFundsDisplayName';

import useStyle from './style';

const AllowedUseOfFundChange = ({ change, icon, name, color }) => {
  const classes = useStyle({ iconColor: color });
  const { prevVal, newVal, createdAt } = change.attributes;

  const oldEntries = prevVal.split(',');
  const newEntries = newVal.split(',');
  const changeEntries = [
    { className: 'unchanged', entries: newEntries.filter(x => oldEntries.includes(x)) },
    { className: 'removed', entries: oldEntries.filter(x => !newEntries.includes(x)) },
    { className: 'added', entries: newEntries.filter(x => !oldEntries.includes(x)) },
  ];

  return (
    <Grid container item xs={12} key={change.id}>
      <Grid item xs={6}>
        <Box display="flex" alignItems="center">
          <Box mr={2} className={classes.iconContainer}>
            {icon}
          </Box>
          <Typography>{name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        {changeEntries.map(item => (
          <React.Fragment key={item.className}>
            {item.entries.map(entry => (
              <Typography className={classes[item.className]} key={`${item.className}-${entry}`}>
                {useOfFundsDisplayName(entry)}
              </Typography>
            ))}
          </React.Fragment>
        ))}
        <Typography variant="subtitle1" component="p" className={classes.timestamp}>
          {format(parseISO(createdAt), 'MMM do, yyyy')}
        </Typography>
      </Grid>
    </Grid>
  );
};

AllowedUseOfFundChange.propTypes = {
  change: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      prevVal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      newVal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      createdAt: PropTypes.string,
    }),
  }).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default AllowedUseOfFundChange;
