import React, { useMemo } from 'react';
import { useAsync } from 'react-async';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import Loader from 'components/Loader';
import ConfigurationChangesListItem from 'components/ConfigurationChangesListItem';
import AllowedUseOfFundsChange from 'components/AllowedUseOfFundsChange';
import { getConfigurationChanges } from 'api/configuration';
import abbreviateNumber from 'utils/abbreviateNumber';
import { useProduct } from 'contexts/ProductContext';
import theme from 'theme';

const ConfigurationChanges = () => {
  const { currentProductKey } = useProduct();
  const { data, isLoading, isFulfilled } = useAsync(getConfigurationChanges, {
    productKey: currentProductKey,
    watch: currentProductKey,
  });
  const changes = useMemo(() => (
    isFulfilled ? data.data.data : []
  ), [isFulfilled]);

  const { teal, green, turquoise, tangerine, shade } = theme.palette;
  const fieldMappings = {
    max_raising_amount: {
      icon: <MonetizationOnOutlinedIcon />,
      name: 'Max Raising Amount',
      color: teal.main,
      formatVal: val => `$${abbreviateNumber(val)}`,
    },
    min_raising_amount: {
      icon: <MonetizationOnOutlinedIcon />,
      name: 'Min Raising Amount',
      color: teal.main,
      formatVal: val => `$${abbreviateNumber(val)}`,
    },
    qualifying_credit_score: {
      icon: <CreditCardOutlinedIcon />,
      name: 'Qualifying Credit Score',
      color: tangerine.main,
      formatVal: val => val,
    },
    dti_percent_limit: {
      icon: <TrendingUpOutlinedIcon />,
      name: 'DTI Percent Limit',
      color: green.main,
      formatVal: val => `${val}%`,
    },
    max_apr: {
      icon: <TrendingUpOutlinedIcon />,
      name: 'Max APR',
      color: shade.main,
      formatVal: val => `${val}%`,
    },
    paper_copy_fee_amount: {
      icon: <MonetizationOnOutlinedIcon />,
      name: 'Paper Copy Fee Amount',
      color: green.main,
      formatVal: val => `$${val}`,
    },
    allowed_use_of_funds: {
      icon: <MonetizationOnOutlinedIcon />,
      name: 'Allowed Use of Funds',
      color: turquoise.main,
    },
  };

  return (
    <Grid item container spacing={5}>
      {isLoading && (
        <Grid item xs={12}>
          <Loader data-testid="loader" />
        </Grid>
      )}
      {changes.length === 0 && isFulfilled && (
        <Grid item xs={12}>
          <Typography component="p" align="center">No Changes</Typography>
        </Grid>
      )}
      {isFulfilled && (
        <React.Fragment>
          {changes.map(change => (
            <React.Fragment key={change.id}>
              {change.attributes.field === 'allowed_use_of_funds' && (
                <AllowedUseOfFundsChange
                  key={change.id}
                  change={change}
                  {...fieldMappings[change.attributes.field]}
                />
              )}
              {change.attributes.field !== 'allowed_use_of_funds' && (
                <ConfigurationChangesListItem
                  key={change.id}
                  change={change}
                  {...fieldMappings[change.attributes.field]}
                />
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </Grid>
  );
};

export default ConfigurationChanges;
