import React, { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { useAsync } from 'react-async';
import Grid from '@material-ui/core/Grid';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import ValueSnapshot from 'components/ValueSnapshot';
import Loader from 'components/Loader';
import { useProduct } from 'contexts/ProductContext';
import { getDailyPortfolioLoanMetric } from 'api/performanceMetric';
import theme from 'theme';
import LastUpdatedNotifier from '../LastUpdatedNotifier';

const WeightedAverages = () => {
  const { currentProductKey } = useProduct();
  const { data, isLoading, isFulfilled } = useAsync(getDailyPortfolioLoanMetric, {
    productKey: currentProductKey,
    watch: currentProductKey,
  });
  const values = useMemo(() => {
    const attributes = data?.data?.data?.attributes;
    return (isFulfilled && attributes) || {};
  }, [isFulfilled]);
  const waLoanAmount = useMemo(() => (
    values.wavgLoansValue ? `$${Math.ceil(values.wavgLoansValue).toLocaleString()}` : '—'
  ), [values]);
  const waApr = useMemo(() => (
    values.wavgApr ? `${values.wavgApr}%` : '—'
  ), [values]);
  const waCreditScore = useMemo(() => (
    values.wavgCreditScore ? `${values.wavgCreditScore}` : '—'
  ), [values]);
  const wavgLoanLife = useMemo(() => (
    values.wavgLoanLife ? `${values.wavgLoanLife} mo.` : '—'
  ), [values]);
  const date = useMemo(() => (
    values.date ? parseISO(values.date) : undefined
  ), [values]);
  const { green, highlight, turquoise, tangerine } = theme.palette;

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader data-testid="loader" />
      ) : (
        <Grid container>
          <ValueSnapshot
            icon={<TrendingUpOutlinedIcon />}
            title="Weighted Average APR"
            value={waApr}
            color={highlight.main}
          />
          <ValueSnapshot
            icon={<PeopleAltOutlinedIcon />}
            title="Weighted Average Credit Score"
            value={waCreditScore}
            color={tangerine.main}
          />
          <ValueSnapshot
            icon={<MonetizationOnOutlinedIcon />}
            title="Weighted Average Loan Amount"
            value={waLoanAmount}
            color={green.main}
          />
          <ValueSnapshot
            icon={<HourglassEmptyOutlinedIcon />}
            title="Weighted Average Loan Term"
            value={wavgLoanLife}
            color={turquoise.main}
          />
        </Grid>
      )}
      <LastUpdatedNotifier date={date} />
    </React.Fragment>
  );
};

export default WeightedAverages;
