import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import UpstartSmallLogo from 'assets/upstart-logo-small.svg';
import AnalyticsDashboard from 'components/AnalyticsDashboard';
import AnalyticsLastRefresh from 'components/AnalyticsLastRefresh';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import ConfigurationOverview from 'components/ConfigurationOverview';
import Insights from 'components/Insights';
import LoansThirtyDaysTrailing from 'components/LoansThirtyDaysTrailing';
import OriginationAverages from 'components/OriginationAverages';
import RecentReports from 'components/RecentReports';
import RepaymentStatus from 'components/RepaymentStatus';
import RequireProduct from 'components/RequireProduct';
import TrustPilot from 'components/TrustPilot';
import UMIDisplay from 'components/UMIDisplay';
import UptimeStatus from 'components/UptimeStatus';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { useProduct } from 'contexts/ProductContext';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import sharedStyles from 'theme/sharedStyles';

const Dashboard = () => {
  const classes = sharedStyles();
  const { currentProduct } = useProduct();
  const { analyticsEnabled } = useAnalytics();

  const servicesOwnLoans = useMemo(
    () => currentProduct?.attributes?.servicesOwnLoans,
    [currentProduct],
  );

  const nestedBoard = (
    <AnalyticsDashboard
      headerText={'Current Month Originations'}
      reportPath={'embed/dashboards/lending-partnership-reporting::current_month_originations'}
      showDivider={false}
      showDateSelector={false}
    />
  );

  return (
    <RequireProduct>
      <Grid container item spacing={3}>
        <Grid item xs={12} lg={9}>
          {analyticsEnabled && (
            <AnalyticsDashboard
              reportPath={'embed/dashboards/lending-partnership-reporting::originations_summary'}
              headerText="Originations Summary"
              linkText={'View Originations Details'}
              linkUrl={'/metrics#originations'}
              subBoard={nestedBoard}
              subHeader={false}
              subHeaderText="Origination Trends"
            />
          )}
          {!analyticsEnabled && (
            <>
              <Box mb={3}>
                <Card>
                  <CardContent>
                    <OriginationAverages cardsToInclude={['origination', 'wavg-apr', 'avg-score']} />
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                <Card>
                  <CardHeader title="Loans per day and $ per day for 30 days trailing" addFullscreenOption />
                  <CardContent>
                    <LoansThirtyDaysTrailing />
                  </CardContent>
                </Card>
              </Box>
              <Box mb={3}>
                {
                  !servicesOwnLoans && <RepaymentStatus />
                }
              </Box>
            </>
          )}
          <Card>
            <CardHeader title="Configuration" subheader={<Link to="/configuration">See All</Link>} />
            <CardContent>
              <ConfigurationOverview />
            </CardContent>
          </Card>
          <Grid xs={12} item container className={classes.caption}>
            {analyticsEnabled && (
              <AnalyticsLastRefresh />
            )}
            <UptimeStatus />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={3} data-testid="tile-grid">
          <Box mb={3}>
            <Card data-testid="card-umi">
              <CardHeader title="Upstart Macro Index" />
              <CardContent>
                <UMIDisplay />
              </CardContent>
            </Card>
          </Box>
          <Box mb={3}>
            <Card invert data-testid="card-insights">
              <CardHeader
                title={(
                  <Box display="flex">
                    Insights
                    <Box display="flex" ml={1}>
                      <img src={UpstartSmallLogo} alt="" />
                    </Box>
                  </Box>
                  )}
              />
              <CardContent>
                <Insights />
              </CardContent>
            </Card>
          </Box>
          <Box mb={3}>
            <RecentReports data-testid="recent-reports" />
          </Box>
          <Box mb={3}>
            <Card data-testid="card-trust-pilot">
              <CardHeader title="Reviews" />
              <CardContent>
                <TrustPilot />
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </RequireProduct>
  );
};

export default Dashboard;
