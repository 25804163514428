/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { useAsync } from 'react-async';
import { parse, format, parseISO } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'components/Loader';
import { useProduct } from 'contexts/ProductContext';
import abbreviateNumber from 'utils/abbreviateNumber';
import { getDailyOriginationLoanMetricThirtyDaysTrailing } from 'api/performanceMetric';
import theme from 'theme';
import LastUpdatedNotifier from 'components/LastUpdatedNotifier';

const LoansThirtyDaysTrailing = () => {
  const { currentProductKey } = useProduct();
  const {
    data,
    isLoading,
  } = useAsync(getDailyOriginationLoanMetricThirtyDaysTrailing, {
    productKey: currentProductKey, watch: currentProductKey,
  });
  const { lightTeal, highlight } = theme.palette;
  const { tooltip, legend, chart } = theme.chartStyle;

  if (isLoading) {
    return <Loader data-testid="loader" />;
  }

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  const dailyLoanMetrics = data?.data?.data || [];
  const loansValues = dailyLoanMetrics.map(dlm => parseFloat(dlm.attributes.loansValue));
  const numberOfLoans = dailyLoanMetrics.map(dlm => dlm.attributes.numberOfLoans);
  const dates = dailyLoanMetrics.map(dlm => format(
    parse(dlm.attributes.date, 'yyyy-MM-dd', new Date()),
    'MMM d',
  ));
  const lastUpdatedDate = parseISO(data?.data?.meta?.lastUpdatedDate);

  const series = [
    {
      name: '$ per day',
      color: lightTeal.main,
      marker: {
        enabled: false,
      },
      lineWidth: 1,
      lineColor: lightTeal.main,
      data: loansValues,
    },
    {
      name: 'Loans per day',
      color: highlight.main,
      marker: {
        enabled: false,
      },
      lineWidth: 2,
      lineColor: highlight.main,
      yAxis: 1,
      data: numberOfLoans,
    },
  ];

  const options = {
    title: {
      text: '',
    },
    chart: {
      ...chart,
      type: 'column',
    },
    tooltip,
    plotOptions: {
      column: {
        groupPadding: 0.25,
        borderWidth: 0,
        pointPadding: 0,
      },
      area: {
        marker: {
          enabled: false,
        },
      },
    },
    legend,
    xAxis: [{
      tickAmount: 30,
      labels: {
        step: 3,
        style: {
          fontSize: 14,
        },
      },
      categories: dates,
      crosshair: true,
    }],
    yAxis: [{
      gridLineWidth: 0,
      labels: {
        format: '{value}',
        formatter() {
          const label = this.axis.defaultLabelFormatter.call(this);
          return `$${abbreviateNumber(label)}`;
        },
        style: {
          color: '#757575',
          fontSize: 12,
        },
      },
      title: {
        text: '',
        style: {
          color: '#757575',
          fontSize: 12,
        },
      },
    }, {
      gridLineWidth: 0,
      title: {
        text: '',
        style: {
          color: '#757575',
          fontSize: 12,
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: '#757575',
          fontSize: 12,
        },
      },
      opposite: true,
    }],
    credits: {
      enabled: false,
    },
    series,
  };

  return (
    <React.Fragment>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <LastUpdatedNotifier date={lastUpdatedDate} />
    </React.Fragment>
  );
};

export default LoansThirtyDaysTrailing;
