import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createForecastConfiguration,
  updateForecastConfiguration,
  getForecastConfiguration, submitForecastConfiguration,
} from 'api/forecastConfiguration';
import {
  createManagementAssumptionAdjustment,
  duplicateManagementAssumptionAdjustment,
  updateManagementAssumptionAdjustment,
  validateManagementAssumptionAdjustments,
} from 'api/managementAssumptionAdjustment';
import { useHistory } from 'react-router-dom';

export const ForecastContext = createContext();

export const useForecast = () => ({ ...useContext(ForecastContext) });

export const ForecastContextProvider = ({ children }) => {
  const [state, setState] = useState({ uuid: '' });
  const [isEditable, setIsEditable] = useState(true);
  const [expanded, setExpanded] = useState('name-panel');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isValidated, setIsValidated] = useState(null);
  const [formType, setFormType] = useState(null);

  const history = useHistory();

  const getData = () => ({
    forecast_name: state.forecastName ?? '',
    loan_pool_attributes: {
      filters: {
        owners: state.loanPool?.attributes?.filters?.owners ?? [],
        product_types: state.loanPool?.attributes?.filters?.productTypes ?? [],
        as_of_date: state.loanPool?.attributes?.filters?.asOfDate ?? null,
      },
      loanIds: state.loanPool?.attributes?.loanIds ?? [],
    },
  });

  const setName = name => {
    setState(prevState => ({ ...prevState, forecastName: name }));
  };

  const setSelectedProductTypes = productTypes => {
    setState(prevState => ({
      ...prevState,
      loanPool: {
        ...prevState.loanPool,
        attributes: {
          ...prevState.loanPool?.attributes,
          filters: {
            ...prevState.loanPool?.attributes?.filters,
            productTypes,
          },
        },
      },
    }));
  };

  const setAsOfDate = asOfDate => {
    setState(prevState => ({
      ...prevState,
      loanPool: {
        ...prevState.loanPool,
        attributes: {
          ...prevState.loanPool?.attributes,
          filters: {
            ...prevState.loanPool?.attributes?.filters,
            asOfDate,
          },
        },
      },
    }));
  };

  const setSelectedOwners = owners => {
    setState(prevState => ({
      ...prevState,
      loanPool: {
        ...prevState.loanPool,
        attributes: {
          ...prevState.loanPool?.attributes,
          filters: {
            ...prevState.loanPool?.attributes?.filters,
            owners,
          },
        },
      },
    }));
  };

  const setInputModifiers = (tab, data) => {
    setState(prevState => ({
      ...prevState,
      managementAssumptionAdjustments: {
        ...prevState.managementAssumptionAdjustments,
        [tab]: data,
      },
    }));
  };

  const setLoanIds = loanIds => {
    setState(prevState => ({
      ...prevState,
      loanPool: {
        ...prevState.loanPool,
        attributes: {
          ...prevState.loanPool?.attributes,
          loanIds,
        },
      },
    }));
  };

  const loadForecast = uuid => {
    setError(null);
    setIsLoading(true);
    getForecastConfiguration(uuid)
      .then(response => {
        const data = response.data.data.attributes;
        setState(data);
        // set editable status for frontend
        setIsEditable(data.status === 'draft');
        setFormType(data.status === 'draft' ? 'edit' : 'view');
        // open all tabs if forecast is submitted
        if (data.status === 'submitted') {
          setExpanded('all');
        }
      }).catch(err => {
        setError(err.response.data.errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveOrUpdateConfig = async () => {
    if (!state.uuid.trim()) {
      return createForecastConfiguration(getData());
    } else {
      return updateForecastConfiguration(state.uuid, getData());
    }
  };

  const createAdjustment = async data => createManagementAssumptionAdjustment(state.uuid, data);

  const updateAdjustment = async (adjustmentId, data) => updateManagementAssumptionAdjustment(
    state.uuid, adjustmentId, data,
  );

  const duplicateAdjustment = async (adjustmentId, data) => duplicateManagementAssumptionAdjustment(
    state.uuid, adjustmentId, data,
  );

  const handleContinue = () => {
    setError(null);
    setIsLoading(true);

    saveOrUpdateConfig()
      .then(response => {
        setState(prevState => ({
          ...prevState,
          uuid: response.data.data.attributes.uuid,
        }));
        if (expanded === 'name-panel') setExpanded('filter-panel');
        else if (expanded === 'filter-panel') setExpanded('modifier-panel');
        else setExpanded('modifier-panel');
      })
      .catch(err => {
        setError(`Error saving Forecast Configuration: ${err.response.data.errors.slice(1).map(e => e).join('; ')}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveAndExit = () => {
    setError(null);
    setIsLoading(true);

    saveOrUpdateConfig()
      .then(() => {
        history.push('/');
      })
      .catch(err => {
        setError(`Error saving Forecast Configuration: ${err.response.data.errors.slice(1).map(e => e).join('; ')}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePanel = panel => (event, isExpanded) => {
    // if forecast name is not set, don't allow user to move to next panel
    if (panel !== 'name-panel' && !state.forecastName) {
      return;
    }
    setExpanded(isExpanded ? panel : false);
  };

  const handleValidateAdjustments = () => {
    setIsValidated(null);
    setIsValidating(true);
    setError(null);
    const validStates = {
      chargeOffTiming: null,
      defaultPrepaymentCalibration: null,
      defaultPrepaymentSensitivity: null,
      discountRate: null,
      recoveryTiming: null,
      seasonalityAdjustment: null,
      syntheticLoans: null,
      timingCurveAdjustment: null,
      upstartMacroFactor: null,
      upstartMacroIndex: null,
    };
    for (let i = 0; i < Object.keys(validStates).length; i++) {
      const category = Object.keys(validStates)[i];
      validateManagementAssumptionAdjustments(state.uuid, category)
        .then(() => {
          setIsValidated(prevState => ({
            ...prevState,
            [category]: true,
          }));
        })
        .catch(e => {
          setIsValidated(prevState => ({
            ...prevState,
            [category]: e.response.data.data,
          }));
        });
    }
    setIsValidating(false);
  };

  const handleSubmit = () => {
    setError(null);
    setIsLoading(true);
    submitForecastConfiguration(state.uuid)
      .then(() => {
        alert('Forecast Configuration submitted successfully');
        history.push('/');
      })
      .catch(err => {
        setError(`Error submitting Forecast Configuration: ${err.response.data.errors.slice(1).map(e => e).join('; ')}`);
      });
  };

  return (
    <ForecastContext.Provider
      value={{
        name: state.forecastName ?? '',
        forecastUuid: state.uuid ?? '',
        setName,
        selectedProductTypes:
state.loanPool?.attributes?.filters?.productTypes ?? [],
        setSelectedProductTypes,
        selectedOwners: state.loanPool?.attributes?.filters?.owners ?? [],
        setSelectedOwners,
        asOfDate: state.loanPool?.attributes?.filters?.asOfDate ?? null,
        setAsOfDate,
        setLoanIds,
        loanIdCount: state.loanPool?.attributes?.loanIds?.length ?? 0,
        loadForecast,
        handleContinue,
        handleSaveAndExit,
        handlePanel,
        handleSubmit,
        expanded,
        error,
        setError,
        isLoading,
        isEditable,
        state,
        setInputModifiers,
        createAdjustment,
        duplicateAdjustment,
        updateAdjustment,
        handleValidateAdjustments,
        isValidated,
        setIsValidated,
        isValidating,
        formType,
        setFormType,
      }}
    >
      {children}
    </ForecastContext.Provider>
  );
};

ForecastContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ForecastContextProvider.defaultProps = {
  children: [],
};
