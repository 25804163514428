import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import useStyle from './style';

const QuickLinks = ({ links }) => {
  const classes = useStyle();

  return (
    <div>
      {links.map(link => (
        <Typography component="p" key={link.name} gutterBottom>
          <a className={classes.link} href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a>
        </Typography>
      ))}
    </div>
  );
};

QuickLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default QuickLinks;
