import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useAsync } from 'react-async';
import getAppConfig from 'api/appConfig';

// Can drill down further to track user as needed
const Tracking = ({ children }) => {
  const [gaInitialized, setGaInitialized] = useState(false);
  const { pathname } = useLocation();
  const { data: appConfigData } = useAsync(getAppConfig);

  useEffect(() => {
    if (appConfigData) {
      ReactGA.initialize(appConfigData.data.googleAnalyticsTrackingId);
      setGaInitialized(true);
    }
  }, [appConfigData]);

  useEffect(() => {
    if (gaInitialized) {
      ReactGA.pageview(pathname);
    }
  }, [pathname, gaInitialized]);

  return <React.Fragment>{children}</React.Fragment>;
};

Tracking.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Tracking.defaultProps = {
  children: [],
};

export default Tracking;
