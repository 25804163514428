import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    fontFamily: theme.typography.secondary.fontFamily,
    fontSize: '16px',
    letterSpacing: '0.055em',
    textDecorationLine: 'underline',
    color: '#1952A7',
  },
}));

export default useStyles;
