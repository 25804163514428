import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isEqual from 'lodash/isEqual';
import { useProduct } from 'contexts/ProductContext';

export const ReportContext = createContext();

export const useReport = () => ({ ...useContext(ReportContext) });

export const ReportContextProvider = ({ children }) => {
  const reportLimit = 25;

  const { currentProductKey } = useProduct();

  const [reportParams, setReportParams] = useState({
    productKey: currentProductKey,
    limit: reportLimit,
    offset: 0,
    query: '',
    createdAfter: null,
    reportTypeSlug: null,
  });

  const updateReportParams = obj => {
    setReportParams(prevState => {
      const watchKeys = ['productKey', 'query', 'createdAfter', 'reportTypeSlug'];
      const newState = { ...prevState, ...obj };
      const watchKeyChanged = !isEqual(
        pick(prevState, watchKeys),
        pick(newState, watchKeys),
      );
      if (watchKeyChanged) {
        return { ...newState, limit: reportLimit, offset: 0 };
      }
      return newState;
    });
  };

  const compactReportParams = useMemo(
    () => pickBy(reportParams, identity),
    [reportParams],
  );

  const [selectedReports, setSelectedReports] = useState([]);
  const [currentReportDownloadState, setCurrentReportDownloadState] = useState({
    reportId: '',
    actionEndpoint: '',
    paramName: '',
  });

  useEffect(() => {
    updateReportParams({ productKey: currentProductKey });
  }, [currentProductKey]);

  return (
    <ReportContext.Provider
      value={{
        reportLimit,
        reportParams,
        compactReportParams,
        updateReportParams,
        selectedReports,
        setSelectedReports,
        currentProductKey,
        currentReportDownloadState,
        setCurrentReportDownloadState,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

ReportContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ReportContextProvider.defaultProps = {
  children: [],
};
