import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(1)}px 0`,
  },
  percentRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  percentBar: {
    height: '16px',
    background: theme.palette.primary.main,
    display: 'inline-block',
  },
  percentLabel: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

export default useStyles;
