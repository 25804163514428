import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;
const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.neutral.background,
    borderBottom: `1px solid ${theme.palette.neutral.borderLight}`,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  portfolioAnalyticsLogo: {
    width: '150px',
  },
  portfolioAnalyticsSpacer: {
    width: '20px',
    height: '25px',
    borderRight: '1px solid #000000',
    marginBottom: '4px',
  },
  portfolioAnalyticsTitle: {
    paddingLeft: '20px',
    fontSize: '24px',
    fontWeight: '500',
    color: '#000000',
  },
  spacer: {
    flexGrow: 1,
  },
}));

export default useStyles;
