import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ProductDropdown from 'components/ProductDropdown';
import ExternalInvestorDropdown from 'components/ExternalInvestorDropdown';
import InstitutionDropdown from 'components/InstitutionDropdown';
import UserDropdown from 'components/UserDropdown';
import { useView } from 'contexts/ViewContext';
import HelpDropdown from 'components/HelpDropdown';
import useStyles from './style';

const TopNav = () => {
  const classes = useStyles();
  const {
    productView, hasLenderAccess, investorView, hasInvestorAccess, portfolioAnalyticsView,
  } = useView();

  return (
    <AppBar
      position="absolute"
      elevation={0}
      className={classes.appBar}
      style={{
        width: portfolioAnalyticsView ? '100%' : '',
        height: '80px',
        padding: '0 32px',
        backgroundColor: portfolioAnalyticsView ? '#FFFFFF' : '#F9F9F9',
      }}
    >
      <Toolbar style={{ padding: '0', height: '80px' }}>
        {hasLenderAccess && productView && (
          <ProductDropdown />
        )}
        {portfolioAnalyticsView && (
          <>
            <img
              src={'https://www.upstart.com/static-tofu/_next/static/media/upstart.4702a090.svg'}
              alt={''}
              className={classes.portfolioAnalyticsLogo}
            />
            <div className={classes.portfolioAnalyticsSpacer} />
            <p className={classes.portfolioAnalyticsTitle}>Upstart Portfolio Analytics</p>
          </>
        )}
        {hasInvestorAccess && investorView && (
          <>
            <InstitutionDropdown />
            <ExternalInvestorDropdown />
          </>
        )}
        <div className={classes.spacer} />
        { portfolioAnalyticsView && (<HelpDropdown />)}
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
