import Alert from '@material-ui/lab/Alert';
import ForecastName from '../ForecastName';
import ForecastFilters from '../ForecastFilters';
import ForecastModifiers from '../ForecastModifiers';
import { useForecast } from '../../contexts/ForecastContext';

const ForecastForm = () => {
  const { error } = useForecast();

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      minWidth: '500px',
      width: '100%',
      borderRadius: '0',
    }}
    >
      {error && (
        <Alert severity="error">{error}</Alert>
      )}
      <ForecastName />
      <ForecastFilters />
      <ForecastModifiers />
    </div>
  );
};
export default ForecastForm;
