import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  borderedHeaderCell: {
    border: '1px solid #E7E7E7',
    fontWeight: 'bold',
    padding: '20px',
  },
  borderedTableCell: {
    border: '1px solid #E7E7E7',
    padding: '20px',
  },
}));

export default useStyles;
