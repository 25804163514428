import Grid from '@material-ui/core/Grid';
import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import getPortfolioAnalyticsEmbedUrl from 'api/portfolioAnalyticsEmbed';
import { randomString } from 'utils/toolsService';
import useStyles from './style';

const PortfolioAnalyticsExplore = () => {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const [embedId] = useState(randomString());
  const embedDomain = `?embed_domain=${window.location.origin}`;
  const reportPath = 'embed/explore/upstart_portfolio_analytics/upc_credit_performance';

  useEffect(() => {
    getPortfolioAnalyticsEmbedUrl({
      embedPath: `${reportPath}${embedDomain}`,
    }).then(result => setUrl(result.data.url));
  }, [reportPath, embedDomain]);

  return (
    <>
      {url.length === 0 ? (<Loader data-testid="loader" />) : (
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <div className={classes.iframeExploreWrapper}>
              <iframe
                id={embedId}
                className={[classes.iframe, classes.iframeHeight].join(' ')}
                title="Look"
                src={url}
                width="100%"
                height="100%"
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PortfolioAnalyticsExplore;
