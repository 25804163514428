import apiClient from 'api/apiClient';

export const getRepaymentStatus = ({ productKey }) => apiClient.get(`/v1/products/${productKey}/report_repayment_status`);

export const getFundUsages = ({ productKey, from, to }) => apiClient.get(`/v1/products/${productKey}/report_daily_fund_usages`, {
  params: { from, to },
});

export const getMonthlyPortfolioLoanMetrics = ({ productKey, limit }) => apiClient.get(`/v1/products/${productKey}/report_monthly_portfolio_loan_metrics`, {
  params: { limit },
});

export const getDailyPortfolioLoanMetric = ({ productKey }) => apiClient.get(`/v1/products/${productKey}/daily_portfolio_loan_metrics`);

export const getDailyOriginationLoanMetricThirtyDaysTrailing = ({ productKey }) => apiClient.get(`/v1/products/${productKey}/daily_origination_loan_metrics/thirty_days_trailing`);
export const getDailyOriginationLoanMetricByMonth = ({ productKey, from }) => apiClient.get(`/v1/products/${productKey}/daily_origination_loan_metrics/originations_by_month`, {
  params: { from },
});

export const getMonthlyWeightedAverageApr = ({ productKey, from }) => apiClient.get(`/v1/products/${productKey}/daily_origination_loan_metrics/monthly_averages`, {
  params: { from },
});

export const getDailyOriginationLoanMetricAverage = ({ productKey, from, to }) => apiClient.get(`/v1/products/${productKey}/daily_origination_loan_metrics/averages`, {
  params: { from, to },
});

export const getTimePeriods = ({ productKey }) => apiClient.get(`/v1/products/${productKey}/daily_origination_loan_metrics/time_periods`);
