import React from 'react';

const ReviewDoc = () => (
  <svg width="72" height="81" viewBox="0 0 72 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Review Doc - Upstart">
      <g id="lines_letter">
        <path id="letter" fillRule="evenodd" clipRule="evenodd" d="M59.5742 67.9654H4.27425V4.05764H43.4148V18.2445C43.4148 19.2876 44.258 20.1341 45.2997 20.1341H59.5742V67.9654ZM47.5087 6.08645L57.5633 16.2305H47.5087V6.08645ZM63.596 51.4693V18.1061C63.596 17.3824 63.1881 16.7603 62.5928 16.4379L46.7509 0.558594C46.6331 0.439258 46.4938 0.342774 46.3468 0.258985C46.3126 0.238672 46.2797 0.219629 46.2442 0.203125C46.0986 0.130762 45.9466 0.0698242 45.7832 0.0380859C45.773 0.0355469 45.7629 0.0368166 45.754 0.0342776C45.6413 0.0126955 45.5248 0 45.407 0H2.15241C1.10363 0 0.252441 0.851856 0.252441 1.9043V70.1187C0.252441 71.1699 1.10363 72.023 2.15241 72.023H61.696C62.7461 72.023 63.596 71.1699 63.596 70.1187V55.2779" fill="#1952A7" />
        <path id="Fill 97" fillRule="evenodd" clipRule="evenodd" d="M36.4155 18.815H13.693C12.7833 18.815 12.0469 18.1294 12.0469 17.2847C12.0469 16.44 12.7833 15.7544 13.693 15.7544H36.4155C37.3252 15.7544 38.0616 16.44 38.0616 17.2847C38.0616 18.1294 37.3252 18.815 36.4155 18.815Z" fill="#1952A7" />
        <path id="Fill 98" fillRule="evenodd" clipRule="evenodd" d="M48.6924 29.5269H13.6584C12.7688 29.5269 12.0469 28.8413 12.0469 27.9966C12.0469 27.1519 12.7688 26.4663 13.6584 26.4663H48.6924C49.583 26.4663 50.3038 27.1519 50.3038 27.9966C50.3038 28.8413 49.583 29.5269 48.6924 29.5269Z" fill="#1952A7" />
        <path id="Fill 5" fillRule="evenodd" clipRule="evenodd" d="M12.0469 40.2388C12.0469 41.0839 12.732 41.7691 13.5772 41.7691H33.4708C34.3159 41.7691 35.0011 41.0839 35.0011 40.2388C35.0011 39.3936 34.3159 38.7085 33.4708 38.7085H13.5772C12.732 38.7085 12.0469 39.3936 12.0469 40.2388Z" fill="#1952A7" />
      </g>
      <path id="white oval - don&#39;t recolor" d="M51.7875 73.4521C59.7961 73.4521 66.2884 66.9869 66.2884 59.0117C66.2884 51.0365 59.7961 44.5713 51.7875 44.5713C43.7789 44.5713 37.2866 51.0365 37.2866 59.0117C37.2866 66.9869 43.7789 73.4521 51.7875 73.4521Z" fill="white" />
      <g id="mag glass">
        <path id="Fill 11 Copy" fillRule="evenodd" clipRule="evenodd" d="M38.6032 58.9183C38.6032 51.7204 44.4629 45.8839 51.6922 45.8839C58.9202 45.8839 64.7799 51.7204 64.7799 58.9183C64.7799 66.1162 58.9202 71.9527 51.6922 71.9527C44.4629 71.9527 38.6032 66.1162 38.6032 58.9183ZM71.2592 77.213L64.7562 69.7985H64.7549C67.2358 66.85 68.7347 63.0561 68.7347 58.9183C68.7347 49.5596 61.0901 41.9456 51.6922 41.9456C42.2943 41.9456 34.6484 49.5596 34.6484 58.9183C34.6484 68.277 42.2943 75.891 51.6922 75.891C55.5099 75.891 59.027 74.6176 61.8705 72.4975V72.4988L68.2812 79.8044C68.6727 80.2494 69.2211 80.4778 69.7722 80.4778C70.2322 80.4778 70.6963 80.3177 71.072 79.9921C71.8932 79.2766 71.9776 78.0321 71.2592 77.213Z" fill="#3678DC" />
        <path id="Fill 9 Copy" fillRule="evenodd" clipRule="evenodd" d="M49.2777 67.0218L42.6284 60.4251L45.2544 57.7996L49.2224 61.7366L57.7357 52.9358L60.4144 55.5049L49.2777 67.0218Z" fill="#3678DC" />
      </g>
    </g>
  </svg>
);

export default ReviewDoc;
