import { makeStyles } from '@material-ui/core/styles';

export const sharedClasses = theme => (
  {
    alignMiddle: {
      verticalAlign: 'middle',
    },
    caption: {
      marginTop: 32,
    },
    header: {
      paddingTop: 15,
      paddingBottom: 10,
      display: 'flex',
      flexDirection: 'row',
    },
    headerText: {
      alignSelf: 'center',
      display: 'flex',
      fontSize: '32px',
      fontWeight: 'bold',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    subHeaderText: {
      alignSelf: 'center',
      display: 'flex',
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
      marginTop: 15,
      zIndex: 1,
    },
    shiftUp: {
      marginTop: -45,
    },
    subHeaderText2: {
      alignSelf: 'center',
      display: 'flex',
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
      marginTop: -27,
      marginBottom: 20,
    },
    hideMobile: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    horizontalRule: {
      backgroundColor: theme.palette.line.main,
      borderColor: theme.palette.line.main,
      color: theme.palette.line.main,
      width: '100%',
      height: '1px',
      border: 0,
    },
    iframe: {
      borderWidth: 0,
    },
    iframeDashboardWrapper: {
      width: '100%',
      marginLeft: -18,
      marginRight: -18,
    },
    iframeExploreWrapper: {
      width: '100%',
    },
    iframeReportWrapper: {
      width: '100%',
      backgroundColor: 'white',
      paddingLeft: 16,
      paddingRight: 16,
    },
    mainContent: {
      flexGrow: 1,
      flexBasis: 'min-content',
    },
    reportListHeader: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.neutral.text,
      textTransform: 'uppercase',
    },
    reportListTitle: {
      margin: theme.spacing(1.3),
      fontSize: 16,
      textTransform: 'none',
      textDecoration: 'underline',
      justifyContent: 'flex-start',
      textAlign: 'left',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&.selected': {
        fontWeight: 700,
        backgroundColor: theme.palette.primary.subdued,
      },
    },
    reportListWrapper: {
      padding: theme.spacing(2),
      backgroundColor: 'white',
      minHeight: '80vh',
      [theme.breakpoints.down('md')]: {
        minHeight: 38,
      },
      borderWidth: 1,
      borderColor: theme.palette.line.main,
      borderStyle: 'solid',
      position: 'relative',
    },
    reportList: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    sideBar: {
      width: '25%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    sideBarCollapsed: {
      width: '62px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '62px',
      },
    },
    sideListCollapseButton: {
      padding: '0 !important',
      float: 'right',
      position: 'absolute',
      top: 32,
      right: 32,
      '&.collapsed': {
        [theme.breakpoints.up('md')]: {
          right: 8,
        },
        [theme.breakpoints.down('md')]: {
          top: 8,
        },
      },
    },
    splitWrapper: {
      flexFlow: 'nowrap',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    tabPanel: {
      padding: 0,
      width: '100%',
    },
  }
);

const sharedStyles = makeStyles(theme => ({
  ...sharedClasses(theme),
}));

export default sharedStyles;
