import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
  },
  circleWrapper: {
    display: 'flex',
  },
  statusWrapper: {
    marginLeft: '5px',
  },
  uptimeStatusText: {
    borderRight: '0.1em solid black',
    padding: '0.5em',
  },
  verticalLineWrapper: {
    display: 'flex',
    marginLeft: '10px',
    marginRight: '10px',
  },
  verticalLine: {
    borderRight: '0.1em solid black',
  },
  statuslink: {
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.primary.main,
  },
  emailWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  emailLink: {
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.primary.main,
  },
  circle: {
    width: '11px',
    height: '11px',
    borderRadius: '50%',
    alignSelf: 'center',
  },
  circleDefault: {
    width: '11px',
    height: '11px',
    backgroundColor: theme.palette.neutral.text,
    borderRadius: '50%',
    alignSelf: 'center',
  },
}));

export default useStyles;
