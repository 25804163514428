import apiClient from 'api/apiClient';

export const getEmbedUrl = ({ productKey, embedPath, investorName }) => {
  let result = null;
  if (productKey != null) {
    result = apiClient.get(`/v1/products/${productKey}/embed/embed_url?embed_path=${embedPath}`);
    // TODO: migrate to a shared endpoint instead of product specific
    // apiClient.get(`/v1/embed/${productKey}/embed_product_url?embed_path=${embedPath}`);
  } else if (investorName != null) {
    result = apiClient.get(`/v1/embed/${investorName}/embed_external_investor_url?embed_path=${embedPath}`);
  }
  return result;
};

export default getEmbedUrl;
