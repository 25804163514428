import Admin from 'pages/Admin';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import InvestorDashboard from 'pages/InvestorDashboard';
import InvestorReports from 'pages/InvestorReports';
import InvestorMetrics from 'pages/InvestorMetrics';
import OAuthSecureRoute from '../OAuthSecureRoute';

const RoutesInvestors = () => (
  <Switch>
    <OAuthSecureRoute exact path="/">
      <InvestorDashboard />
    </OAuthSecureRoute>
    <OAuthSecureRoute exact path="/reports">
      <InvestorReports />
    </OAuthSecureRoute>
    <OAuthSecureRoute exact path="/metrics">
      <InvestorMetrics />
    </OAuthSecureRoute>
    <OAuthSecureRoute exact path="/admin">
      <Admin />
    </OAuthSecureRoute>
    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default RoutesInvestors;
