import React from 'react';
import PropTypes from 'prop-types';
import MuiTooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useStyle from './style';

const Tooltip = ({ message }) => {
  const classes = useStyle();

  return (
    <MuiTooltip arrow placement="left" title={message}>
      <InfoOutlinedIcon titleAccess="Info Tooltip" className={classes.icon} />
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Tooltip;
