import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination } from 'react-table';
import { useProduct } from 'contexts/ProductContext';
import { downloadLoanPacket } from 'api/applicantSearch';
import SquareIconButton from 'components/SquareIconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Card from 'components/Card';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ApplicantSearchCard from '../../ApplicantSearchCard';
import useStyles from '../../style';
import ReviewDoc from '../../icons/ReviewDoc';
import streamZipDownload from '../../../../utils/streamZipDownload';

const ApplicantsTable = ({ data, nextPage, pageTitle, listAanReasons }) => {
  const APPLICANTS_LIMIT = 25;
  const classes = useStyles();
  const { currentProductKey } = useProduct();
  const [showDialog, setShowDialog] = useState(false);

  const onAanSubmit = (aanReasons, applicationId) => {
    nextPage();
    pageTitle(`Applicant ${applicationId} Approved Status Details`);
    listAanReasons(aanReasons);
  };

  const loanStatus = (applicationId, applicationStatus, aanReasons) => {
    if (aanReasons && aanReasons.length) {
      return (
        <Button
          className={classes.loanStatusButton}
          onClick={() => onAanSubmit(aanReasons, applicationId)}
        >
          {applicationStatus}
        </Button>
      );
    }

    return (<Typography component={'span'} className={classes.loanStatus}>{applicationStatus}</Typography>);
  };

  async function handleDownloadLoanPacket(loanId) {
    try {
      const response = await downloadLoanPacket(currentProductKey, loanId);
      streamZipDownload(response.data, `${loanId}.zip`);
    } catch (error) {
      setShowDialog(true);
    }
  }

  const originationDocValues = (loanId, attributes) => {
    if (attributes.packetExists) {
      return (
        <SquareIconButton
          icon={<GetAppIcon />}
          title="Download Origination Docs"
          data-testid="origination-doc-download-btn"
          type="button"
          onClick={() => handleDownloadLoanPacket(loanId)}
        />
      );
    }

    return null;
  };

  const columnsHidden = applicationData => {
    if (applicationData && applicationData.length > 0 &&
        applicationData[0].attributes && applicationData[0].attributes.saveOriginationDocuments) {
      return [];
    }
    return ['originationDocs'];
  };

  const columns = useMemo(() => [
    { Header: 'Upstart Id', id: 'upstartId', accessor: 'attributes.upstartId' },
    {
      Header: 'Application ID',
      id: 'applicationId',
      accessor: row => row,
      Cell: e => (
        <span>
          <Link
            className={'application-id-link-pos'}
            underline="always"
            href={e.value.attributes.profilePage}
            target="_blank"
            rel="noopener noreferrer"
          >
            {e.value.attributes.applicationId}
          </Link>
          <IconButton
            color="primary"
            onClick={() => window.open(e.value.attributes.profilePage, '_blank')}
          >
            <OpenInNewIcon />
          </IconButton>
        </span>
      ),
    },
    { Header: 'First name', id: 'firstName', accessor: 'attributes.firstName' },
    { Header: 'Last name', id: 'lastName', accessor: 'attributes.lastName' },
    { Header: 'Loan ID', id: 'loanId', accessor: 'attributes.loanId' },
    { Header: 'Last 4 of SSN', id: 'last4OfSsn', accessor: 'attributes.ssn' },
    {
      Header: 'Loan Status',
      id: 'loanStatus',
      accessor: row => row,
      Cell: e => (
        <span>
          { loanStatus(e.value.attributes.applicationId,
            e.value.attributes.applicationStatus,
            e.value.attributes.aanReasons) }
        </span>
      ),
    },
    {
      Header: 'Origination Docs',
      id: 'originationDocs',
      show: false,
      accessor: row => row,
      Cell: e => (
        <span>
          { originationDocValues(e.value.attributes.loanId, e.value.attributes) }
        </span>
      ),
    },
  ], []);

  const {
    state,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: APPLICANTS_LIMIT,
        pageIndex: 0,
        hiddenColumns: columnsHidden(data),
      },
      usePagination,
    },
  );

  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="applicants table" className={classes.table} {...getTableProps()}>
          <TableHead>
            { headerGroups.map(headerGroup => (
              <TableRow className={classes.headRow} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell align="center" {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {rows.length !== 0 && (
            <TableBody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <TableRow data-testid="applicant-rows" className={classes.bodyRow} {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <TableCell align="center" {...cell.getCellProps()}>
                        <Typography title={cell.value} noWrap>
                          {cell.render('Cell')}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="warning-dialog"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          Error
        </DialogTitle>
        <DialogContent>
          <div>We are unable to retrieve your documents at this time. Please try again later.</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} variant="contained" size="large" data-testid="dialog-cancel-button">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {rows.length === 0 && (
        <ApplicantSearchCard>
          <ReviewDoc />
          <h2>Enter an upstart id to find applicant records</h2>
        </ApplicantSearchCard>
      )}
      {rows.length > 0 && (
        <Card className={classes.pagination}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            page={state.pageIndex}
            count={rows.length}
            rowsPerPage={state.pageSize}
            onPageChange={() => null}
          />
        </Card>
      )}
    </React.Fragment>
  );
};

ApplicantsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  nextPage: PropTypes.func.isRequired,
  pageTitle: PropTypes.func.isRequired,
  listAanReasons: PropTypes.func.isRequired,
};

export default ApplicantsTable;
