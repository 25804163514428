import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import UpstartSmallLogo from 'assets/upstart-logo-small.svg';
import AnalyticsDashboard from 'components/AnalyticsDashboard';
import AnalyticsLastRefresh from 'components/AnalyticsLastRefresh';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import CardHeader from 'components/CardHeader';
import Insights from 'components/Insights';
import RequireExternalInvestor from 'components/RequireExternalInvestor';
import UMIDisplay from 'components/UMIDisplay';
import UptimeStatus from 'components/UptimeStatus';
import { useAnalytics } from 'contexts/AnalyticsContext';
import React from 'react';
import sharedStyles from 'theme/sharedStyles';

const InvestorDashboard = () => {
  const classes = sharedStyles();
  const { analyticsEnabled } = useAnalytics();

  const nestedBoard = (
    <AnalyticsDashboard
      headerText={'Current Month Portfolio'}
      reportPath={'embed/dashboards/lending-partnership-reporting::current_month_allocations'}
      showDivider={false}
      showDateSelector={false}
    />
  );

  return (
    <RequireExternalInvestor>
      <Grid container item spacing={3}>
        <Grid item xs={12} lg={9}>
          {analyticsEnabled && (
            <AnalyticsDashboard
              reportPath={'embed/dashboards/lending-partnership-reporting::allocations_summary'}
              headerText="Portfolio Summary"
              linkText={'View Portfolio Details'}
              linkUrl={'/metrics'}
              subBoard={nestedBoard}
              subHeader={false}
              subHeaderText="Portfolio Trends"
            />
          )}
          <Grid xs={12} item container className={classes.caption}>
            <AnalyticsLastRefresh />
            <UptimeStatus />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box mb={3}>
            <Card>
              <CardHeader title="Upstart Macro Index" />
              <CardContent>
                <UMIDisplay />
              </CardContent>
            </Card>
          </Box>
          <Card invert>
            <CardHeader
              title={(
                <Box display="flex">
                  Insights
                  <Box display="flex" ml={1}>
                    <img src={UpstartSmallLogo} alt="" />
                  </Box>
                </Box>
              )}
            />
            <CardContent>
              <Insights />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </RequireExternalInvestor>
  );
};

export default InvestorDashboard;
