import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Box } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
const Spreadsheet = ({ columns, data, processRowUpdate, tableHeader }) => (
  <Box sx={{
    width: '100%',
  }}
  >
    {tableHeader && (<h3>{tableHeader}</h3>)}
    <Box sx={{
      height: 500,
      width: '100%',
      '& .gray-data-grid-header': {
        backgroundColor: '#E7E7E7',
      },
    }}
    >
      <DataGridPremium
        /* eslint-disable-next-line react/prop-types */
        columns={columns.map(col => ({ ...col, headerClassName: 'gray-data-grid-header' }))}
        rows={data || []}
        rowHeight={50}
        processRowUpdate={processRowUpdate}
        pagination
        disableColumnSorting
        pageSizeOptions={[250000]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 250000 },
          },
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid #6E6E6E',
            borderRight: '1px solid #6E6E6E',
          },
        }}
      />
    </Box>
  </Box>
);

export default Spreadsheet;
