import React, { createContext, useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import { format, parseISO } from 'date-fns';
import { useProduct } from 'contexts/ProductContext';
import { getTimePeriods } from 'api/performanceMetric';

const getYear = timePeriod => format(parseISO(timePeriod?.attributes?.date), 'yyyy');
const getMonth = timePeriod => format(parseISO(timePeriod?.attributes?.date), 'MMM');

export const TimePeriodContext = createContext();

export const useTimePeriodContext = () => ({ ...useContext(TimePeriodContext) });

export const TimePeriodContextProvider = ({ children }) => {
  const [timePeriods, setTimePeriods] = useState([]);
  const { currentProductKey } = useProduct();

  const timePeriodsOrDefault = productKey => (productKey ? getTimePeriods : []);

  const { data, isFulfilled } = useAsync(timePeriodsOrDefault(currentProductKey), {
    productKey: currentProductKey,
    watch: currentProductKey,
  });

  useEffect(() => {
    setTimePeriods(data?.data?.data || []);
  }, [isFulfilled]);

  const yearMonthMap = useMemo(() => {
    const yearKeys = {};
    timePeriods.forEach(timePeriod => {
      const year = getYear(timePeriod);
      const month = getMonth(timePeriod);
      if (!yearKeys[year]) {
        yearKeys[year] = ['ALL'];
      }

      yearKeys[year].push(month);
    });
    return yearKeys;
  }, [timePeriods]);

  const isLoading = Object.entries(yearMonthMap).length === 0;

  return (
    <TimePeriodContext.Provider
      value={{
        timePeriods,
        yearMonthMap,
        isLoading,
      }}
    >
      {children}
    </TimePeriodContext.Provider>
  );
};

TimePeriodContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TimePeriodContextProvider.defaultProps = {
  children: [],
};
