import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '600',
    lineHeight: '20px',
  },
  body: {
    fontWeight: 'bold',
    lineHeight: '32px',
    color: 'black',
  },
  umiNum: {
    color: 'black',
    fontSize: '48px',
    lineHeight: '55px',
  },
  umiDiv: {
    display: 'flex',
    flexDirection: 'row',
  },
  percentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginLeft: '15px',
    marginBottom: '10px',
  },
  arrowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '5px',
  },
  percentBoxUp: {
    color: theme.palette.red.main,
    fontSize: '15px',
    backgroundColor: theme.palette.red.subdued,
    borderRadius: '5px',
    justifyContent: 'center',
    maxWidth: 'fit-content',
    minWidth: '60px',
    height: '30px',
    display: 'flex',
  },
  percentBoxDown: {
    color: theme.palette.darkGreen.main,
    fontSize: '15px',
    backgroundColor: theme.palette.darkGreen.subdued,
    borderRadius: '5px',
    justifyContent: 'center',
    maxWidth: 'fit-content',
    minWidth: '60px',
    height: '30px',
    display: 'flex',
  },
  percentBoxContent: {
    paddingLeft: '10px',
    paddingRight: '10px',
    alignSelf: 'center',
    display: 'flex',
  },
  triangleUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid',
    borderBottomColor: theme.palette.red.main,
  },
  triangleDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid',
    borderTopColor: theme.palette.darkGreen.main,
  },
  link: {
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.primary.main,
  },
  tooltipContainer: {
    paddingLeft: '5px',
    paddingRight: '10px',
    alignSelf: 'center',
    display: 'flex',
  },
  outermostContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  errors: {
    fontSize: '30px',
  },
}));

export default useStyles;
