import Grid from '@material-ui/core/Grid';
import ReportsTabbed from 'components/ReportsTabbed';
import RequireExternalInvestor from 'components/RequireExternalInvestor';
import { ReportContextProvider } from 'contexts/ReportContext';
import React from 'react';
import DownloadFileForm from '../../components/DownloadFileForm';

const InvestorReports = () =>
  // The investor reports tabbed may need to be cloned, or it could be
  // driven by a prop for the tabs titles.
  // eslint-disable-next-line implicit-arrow-linebreak
  (
    <RequireExternalInvestor>
      <Grid container item spacing={3}>
        <ReportContextProvider>
          <ReportsTabbed />
          <DownloadFileForm />
        </ReportContextProvider>
      </Grid>
    </RequireExternalInvestor>
  );
export default InvestorReports;
