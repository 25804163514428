import React from 'react';
import PropTypes from 'prop-types';
import ListIcon from '@material-ui/icons/List';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyle from './style';

const CurrentPolicies = ({ policies }) => {
  const classes = useStyle();

  return (
    <React.Fragment>
      {policies.length === 0 ? <Typography component="p" align="center">No Policies</Typography> : (
        <Grid container spacing={2}>
          <Grid container item xs={12} alignItems="center">
            <Grid container item xs={12} sm={6} alignItems="center">
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Typography component="p">
                  Policy
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {policies.map(policy => (
            <Grid container item xs={12} alignItems="center" key={policy.id}>
              <Grid container item xs={12} sm={6} alignItems="center">
                <Grid item xs={3}>
                  <div className={classes.listIconContainer}>
                    <ListIcon />
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <p>
                    <a className={classes.link} href={policy.url} target="_blank" rel="noopener noreferrer">
                      {policy.displayName}
                    </a>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </React.Fragment>
  );
};

CurrentPolicies.propTypes = {
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      displayName: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ),
};

CurrentPolicies.defaultProps = {
  policies: [],
};

export default CurrentPolicies;
