import React from 'react';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useStyle from './style';

const ConfigurationChangesListItem = ({ change, icon, name, color, formatVal }) => {
  const classes = useStyle({ iconColor: color });
  const { prevVal, newVal, createdAt } = change.attributes;

  return (
    <Grid container item xs={12} key={change.id}>
      <Grid item xs={6}>
        <Box display="flex" alignItems="center">
          <Box mr={2} className={classes.iconContainer}>
            {icon}
          </Box>
          <Typography>{name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.values}>
          {`${formatVal(prevVal)} → ${formatVal(newVal)}`}
        </Typography>
        <Typography variant="subtitle1" component="p" className={classes.timestamp}>
          {format(parseISO(createdAt), 'MMM do, yyyy')}
        </Typography>
      </Grid>
    </Grid>
  );
};

ConfigurationChangesListItem.propTypes = {
  change: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      prevVal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      newVal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      createdAt: PropTypes.string,
    }),
  }).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  formatVal: PropTypes.func.isRequired,
};

export default ConfigurationChangesListItem;
