import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '24px',
    color: '#222929',
    marginTop: '11px',
  },
  mt2: {
    marginTop: '32px',
  },
}));

export default useStyles;
