import apiClient, { streamingClient } from 'api/apiClient';

export const getApplicants = ([productKey, upstartId]) => (
  apiClient.get('/v1/applicant_search/applications', {
    params: { productKey, upstartId },
  })
);

export const parseApplicants = data => data?.data?.data || [];

export const checkLoanPacket = (productKey, loanId) => (
  apiClient.post('/v1/loan_packet/check_loan_packet ',
    { productKey, loanId })
);

export const downloadLoanPacket = (productKey, loanId) => (
  streamingClient.post('/v1/loan_packet/download_loan_packet',
    { product_key: productKey, loan_id: loanId },
    { responseType: 'blob' })
);
