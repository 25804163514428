import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import isNil from 'lodash/isNil';
import Grid from '@material-ui/core/Grid';
import StatGraphCard from 'components/StatGraphCard';
import TimePeriodSelector, { selectorOptions } from 'components/TimePeriodSelector';
import { useProduct } from 'contexts/ProductContext';
import { getDailyOriginationLoanMetricAverage } from 'api/performanceMetric';
import abbreviateNumber from 'utils/abbreviateNumber';
import useStyle from './style';

const OriginationAverages = ({ cardsToInclude }) => {
  const classes = useStyle();
  const { currentProductKey } = useProduct();
  const [timePeriod, setTimePeriod] = useState(selectorOptions.YESTERDAY.value());
  const { data, isLoading, isFulfilled } = useAsync(getDailyOriginationLoanMetricAverage, {
    productKey: currentProductKey,
    from: timePeriod.fromDate,
    to: timePeriod.toDate,
    watchFn: (params, prevParams) => (
      params.from !== prevParams.from ||
      params.to !== prevParams.to ||
      params.productKey !== prevParams.productKey
    ),
  });
  const values = useMemo(() => (
    isFulfilled && data.status === 200 ? data.data.data.attributes : {}
  ), [isFulfilled]);
  const originated = useMemo(() => (
    isFulfilled && !isNil(values?.loansValue) ? `$${abbreviateNumber(values.loansValue)}` : '—'
  ), [values]);
  const wavgApr = useMemo(() => (
    isFulfilled && !isNil(values?.wavgApr) ? `${values.wavgApr}%` : '—'
  ), [values]);
  const avgScore = useMemo(() => (
    isFulfilled && !isNil(values?.avgCreditScore) ? `${values.avgCreditScore}` : '—'
  ), [values]);
  const avgLoanAmount = useMemo(() => (
    isFulfilled && !isNil(values?.avgLoanAmount) ? `$${abbreviateNumber(values.avgLoanAmount)}` : '—'
  ), [values]);

  const cards = [
    {
      id: 'origination',
      title: 'Origination',
      value: originated,
    },
    {
      id: 'wavg-apr',
      title: 'WAVG APR',
      value: wavgApr,
    },
    {
      id: 'avg-score',
      title: 'Avg Score',
      value: avgScore,
    },
    {
      id: 'avg-loan-amount',
      title: 'Avg Loan Amount',
      value: avgLoanAmount,
    },
  ].filter(({ id }) => cardsToInclude.includes(id));

  return (
    <Grid container spacing={3}>
      <Grid container spacing={3} item md={10} xs={12}>
        {cards.map(({ id, title, value }) => (
          <Grid item xs={12} md={12 / cards.length} key={id}>
            <StatGraphCard id={id} title={title} value={value} loading={isLoading} />
          </Grid>
        ))}
      </Grid>
      <Grid container item md={2} xs={12}>
        <Grid item xs={12} className={classes.timePeriodSelectorContainer}>
          <TimePeriodSelector
            initialTimePeriod={selectorOptions.YESTERDAY}
            onChange={({ value }) => setTimePeriod(value)}
            vertical
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

OriginationAverages.propTypes = {
  cardsToInclude: PropTypes.arrayOf(
    PropTypes.string,
  ),
};

OriginationAverages.defaultProps = {
  cardsToInclude: ['origination', 'wavg-apr', 'avg-score', 'avg-loan-amount'],
};

export default OriginationAverages;
