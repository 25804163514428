import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

const BorrowerFees = ({ lateFee, checkFee, achReturnFee }) => {
  const fees = [{
    name: 'Late fee',
    value: lateFee,
  }, {
    name: 'Check fee',
    value: checkFee,
  }, {
    name: 'ACH return fee',
    value: achReturnFee,
  }];

  return (
    <Grid container spacing={2}>
      {fees.map(({ name, value }) => (
        <Grid container item xs={12} key={name} alignItems="center">
          <Grid item xs={3}>
            <Switch color="primary" disabled checked inputProps={{ 'aria-label': 'disabled checkbox' }} />
          </Grid>
          <Grid item xs={6}>
            <Typography component="p">{name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography component="p">{value}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

BorrowerFees.propTypes = {
  lateFee: PropTypes.string.isRequired,
  checkFee: PropTypes.string.isRequired,
  achReturnFee: PropTypes.string.isRequired,
};

export default BorrowerFees;
