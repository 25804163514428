import React, { useMemo } from 'react';
import { useAsync } from 'react-async';
import Grid from '@material-ui/core/Grid';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import DataUsageOutlinedIcon from '@material-ui/icons/DataUsageOutlined';
import Loader from 'components/Loader';
import ValueSnapshot from 'components/ValueSnapshot';
import { useProduct } from 'contexts/ProductContext';
import abbreviateNumber from 'utils/abbreviateNumber';
import { getConfiguration } from 'api/configuration';
import theme from 'theme';

const ConfigurationOverview = () => {
  const { currentProductKey } = useProduct();
  const { data, isFulfilled, isLoading } = useAsync(getConfiguration, {
    productKey: currentProductKey, id: 1, watch: currentProductKey,
  });
  const values = useMemo(() => (
    isFulfilled && data.status === 200 ? data?.data?.data?.attributes : {}
  ), [isFulfilled]);
  const loanAmount = useMemo(() => (
    isFulfilled && values?.minRaisingAmount && values?.maxRaisingAmount ?
      `$${abbreviateNumber(values.minRaisingAmount)}-${abbreviateNumber(values.maxRaisingAmount)}` : '—'
  ), [values]);
  const minScore = useMemo(() => (
    isFulfilled && values?.qualifyingCreditScore ? `${values?.qualifyingCreditScore}` : '—'
  ), [values]);
  const maxApr = useMemo(() => (
    isFulfilled && values?.maxApr ? `${values?.maxApr}%` : '—'
  ), [values]);
  const maxDti = useMemo(() => (
    isFulfilled && values?.dtiPercentLimit ? `${values?.dtiPercentLimit}%` : '—'
  ), [values]);
  const { green, highlight, turquoise, tangerine } = theme.palette;

  if (isLoading) {
    return <Loader data-testid="loader" />;
  }

  return (
    <Grid container>
      <Grid container item xs={12} md={6} lg={3}>
        <ValueSnapshot
          icon={<MonetizationOnOutlinedIcon />}
          title="Loan Amount"
          value={loanAmount}
          color={green.main}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ValueSnapshot
          icon={<PeopleAltOutlinedIcon />}
          title="Min Score"
          value={minScore}
          color={tangerine.main}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ValueSnapshot
          icon={<TrendingUpOutlinedIcon />}
          title="Max APR"
          value={maxApr}
          color={highlight.main}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ValueSnapshot
          icon={<DataUsageOutlinedIcon />}
          title="Max DTI"
          value={maxDti}
          color={turquoise.main}
        />
      </Grid>
    </Grid>
  );
};

export default ConfigurationOverview;
