/* eslint-disable */
import PropTypes from 'prop-types';
import { Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@material-ui/core/Typography';

const PortfolioLegalDisclaimerModal = ({ isOpen, onClose }) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    aria-labelledby="portfolio-legal-disclaimer"
  >
    <div style={{
      position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', boxShadow: 24, p: 4, backgroundColor: '#FFFFFF', padding: '16px', borderRadius: '16px'
    }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <h1 data-testid="portfolio-legal-disclaimer" id="portfolio-legal-disclaimer" style={{ fontFamily: 'Montserrat', fontWeight: 'SemiBold', fontSize: 24}}> Disclaimer </h1>
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant="body2" color="black" style={{ marginTop: '10px' }}>
        The performance data and illustrative model contained herein are strictly confidential and may not be copied, distributed, reverse-engineered or disseminated without the express written consent of Upstart Network, Inc.
        <br />
        <br />
        The performance data and illustrative model contained herein are based on unaudited data and management assumptions, and have been provided for informational purposes only.  Prospective investors should not rely on the performance data and illustrative model as an indicator of future performance or as a basis for investment.  As with all performance data, past performance provides no assurance of future results.  Prospective investors should consult their own respective attorneys, business advisers and tax advisers as to the legal, business, tax and other implications of a potential investment.
        <br />
        <br />
        Unless stated otherwise, all statements contained herein are made as of the date indicated.
        <br />
        <br />
        Certain of the information contained herein represents or is based upon forward-looking statements or information, including management assumptions for past and future performance. Prospective investors are cautioned that forward-looking statements are not guarantees of future performance and involve risks and uncertainties. In light of these risks and uncertainties, future performance expected based on the illustrative model provided herein may not occur and actual results could differ materially from those anticipated or implied in this model. We do not undertake to update or revise any data herein, whether as a result of new information, future events, or otherwise, except as required by applicable law.
      </Typography>
        <br />
        <br />
    </div>
  </Modal>
);

PortfolioLegalDisclaimerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PortfolioLegalDisclaimerModal;
